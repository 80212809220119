import { getGivenName, getToken } from '../../utils/Auth'
import { snackBarErrorOrFailToFetch } from '../../utils/SnackBarGenericError'

export interface NotificationsUserOptions {
  allowPushNotifications: boolean
  borrowingsCreated: customChoicesNotificationsScope
  borrowingsLate: customChoicesNotificationsScope
  borrowingsReturned: customChoicesNotificationsScope
  bookingsCreated: customChoicesNotificationsScope
  bookingsLate: customChoicesNotificationsScope
}
export enum customChoicesNotificationsScope {
  NONE = 'none',
  FORMEONLY = 'for me only',
  FOREVERYONE = 'for everyone',
}

export const defaultNotificationsUserOptions: NotificationsUserOptions = {
  allowPushNotifications: true,
  borrowingsCreated: customChoicesNotificationsScope.NONE,
  borrowingsLate: customChoicesNotificationsScope.FORMEONLY,
  borrowingsReturned: customChoicesNotificationsScope.NONE,
  bookingsCreated: customChoicesNotificationsScope.NONE,
  bookingsLate: customChoicesNotificationsScope.FORMEONLY,
}
export const defaultDisablePushNotifs: NotificationsUserOptions = {
  allowPushNotifications: false,
  borrowingsCreated: customChoicesNotificationsScope.NONE,
  borrowingsLate: customChoicesNotificationsScope.NONE,
  borrowingsReturned: customChoicesNotificationsScope.NONE,
  bookingsCreated: customChoicesNotificationsScope.NONE,
  bookingsLate: customChoicesNotificationsScope.NONE,
}

export interface HistoryPushNotificationsSentInterface {
  id: number
  title: string
  message: string
  sentTo: string
  sentAt: string
}
export const postUpdateNotificationOptions = (
  body: NotificationsUserOptions,
  enqueueSnackbar: Function,
  decrementLoading: Function
) => {
  const p = process.env
  if (!p.REACT_APP_URL_BACK) {
    throw new Error('Clé .env manquante')
  }
  const bodyToSent = { ...body, given_name: getGivenName() }

  return fetch(p.REACT_APP_URL_BACK.concat('notifications/options/update'), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: getToken(),
    },
    body: JSON.stringify(bodyToSent),
  })
    .then((res) => res.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'postUpdateNotificationOptions',
        decrementLoading
      )
    })
}
export const getNotificationOptions = (
  enqueueSnackbar: Function,
  decrementLoading: Function
) => {
  const p = process.env
  if (!p.REACT_APP_URL_BACK) {
    throw new Error('Clé .env manquante')
  }
  return fetch(
    p.REACT_APP_URL_BACK +
      'notifications/options/get?given_name=' +
      getGivenName(),
    {
      method: 'GET',
      headers: {
        Authorization: getToken(),
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getNotificationOptions',
        decrementLoading
      )
    })
}
export const getHistoryPushNotificationsSent = (
  enqueueSnackbar: Function,
  decrementLoading: Function
) => {
  const p = process.env
  if (!p.REACT_APP_URL_BACK) {
    throw new Error('Clé .env manquante')
  }
  return fetch(p.REACT_APP_URL_BACK + 'notifications/push/get', {
    method: 'GET',
    headers: {
      Authorization: getToken(),
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getHistoryPushNotificationsSent',
        decrementLoading
      )
    })
}
export function getAllFrontNotifs(
  enqueueSnackbar: Function,
  decrementLoading: Function
) {
  const url = process.env.REACT_APP_URL_BACK + 'notifications/front/all/get'
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: getToken(),
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getAllFrontNotifs',
        decrementLoading
      )
    })
}
