import {
  Card,
  CardActionArea,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import PersonIcon from '@material-ui/icons/Person'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import classNames from 'classnames'
import 'firebase/analytics'
import firebase from 'firebase/app'
import React, { useMemo } from 'react'
import { Booking } from '../../pages/History/utils'
import { getGivenName } from '../../utils/Auth'
import { getAbbreviatedName } from '../../utils/various'
import MyCustomListItem from '../MyCustomListItem'
import BookingDialog from './BookingDialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 5,
      width: 300,
      height: 65,
      marginBottom: 10,
      marginLeft: 5,
      marginRight: 5,
      boxShadow: `0 8px 40px -12px ${theme.palette.boxShadow.primary}`,
      '&:hover': {
        boxShadow: `0 16px 70px -12.125px ${theme.palette.boxShadow.primary}`,
      },
      backgroundColor: theme.palette.bookings.default,
      justifyContent: 'center',
      textAlign: 'center',
    },
    cardHeader: {
      marginBottom: -5,
      marginTop: -20,
      color: theme.palette.customText.primary,
      display: 'block',
      overflow: 'hidden',
    },
    rootIfMine: { border: `1px solid ${theme.palette.customText.main}` },
    rootIfLate: { border: `2px solid  ${theme.palette.error.main}` },
    typoEndDateIfLate: { color: theme.palette.error.main },
  })
)

interface Props {
  booking: Booking
  rerenderParentCallback?: any
}
export default function BookingCard({
  booking,
  rerenderParentCallback,
}: Props) {
  const analytics = firebase.analytics()
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  const classes = useStyles()

  const [openDetails, setOpenDetails] = React.useState(false)
  const isMine = booking.borrower_start === getGivenName()
  const isLate = moment(booking.startdate).isBefore(new Date())
  const handleOpenBookingDetails = () => {
    analytics.logEvent('bookingCard-open_bookingDialog')
    setOpenDetails(!openDetails)
  }
  const abreviatedBooker = useMemo(() => {
    return getAbbreviatedName(booking.borrower_start)
  }, [booking])

  return (
    <div id={`booking-${booking.id}`}>
      <Card
        className={classNames(
          classes.root,
          isLate && classes.rootIfLate,
          isMine && classes.rootIfMine
        )}
        onClick={handleOpenBookingDetails}
      >
        <CardActionArea>
          <CardHeader
            title={
              <Typography noWrap variant="h5" component="h4">
                {booking.title}
              </Typography>
            }
            className={classNames(
              classes.cardHeader,
              isLate && classes.typoEndDateIfLate
            )}
          />
          <Grid container direction="column">
            <Grid container>
              <Grid item container xs={5}>
                <MyCustomListItem
                  dense
                  text={abreviatedBooker}
                  icon={<PersonIcon />}
                  accentuatedColor={isMine}
                  accentuatedColorError={isLate}
                  customClassAccentuated={isLate && classes.typoEndDateIfLate}
                />
              </Grid>
              <Grid item container xs={7}>
                <MyCustomListItem
                  dense
                  text={moment(booking.startdate).fromNow()}
                  icon={<WatchLaterIcon />}
                  accentuatedColor={isMine}
                  accentuatedColorError={isLate}
                  customClassAccentuated={isLate && classes.typoEndDateIfLate}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
      {openDetails && (
        <BookingDialog
          openDetails={openDetails}
          booking={booking}
          setOpenDetails={setOpenDetails}
          rerenderParentCallback={rerenderParentCallback}
        />
      )}
    </div>
  )
}
