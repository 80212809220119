import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Theme,
  Typography,
} from '@material-ui/core'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import NotificationsPausedIcon from '@material-ui/icons/NotificationsPaused'
import firebase from 'firebase/app'
import 'firebase/messaging'
import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { LoadingContext } from '../../../contexts/loading/LoadingContext'
import { NotificationsContext } from '../../../contexts/notifications/NotificationsContext'
import { NotificationsUserOptions } from '../../../pages/Notifications/utils'
import { darkTheme, lightTheme } from '../../../theme'
import { getDarkModeLocalStorage } from '../../../utils/Auth'
import { useStylesPopover } from './NotificationsPopoverListItem'

export const conditionsAndColorForNotifsIcon = (
  isFirebaseTokenFound: boolean,
  userPreferences: NotificationsUserOptions,
  theme: Theme
) => {
  if (!firebase.messaging.isSupported()) {
    return {
      text:
        'Ton navigateur actuel ne prend pas en charge les notifications pushs',
      color: theme.palette.warning.main,
    }
  } else if (userPreferences.allowPushNotifications === false) {
    return {
      text: 'Tu as désactivé les notifs pushs',
      color: theme.palette.warning.main,
    }
  } else if (Notification.permission === 'default') {
    return {
      text: 'Activer de façon permanente la réception des notifications',
      color: theme.palette.warning.main,
    }
  } else if (Notification.permission === 'granted' && !isFirebaseTokenFound) {
    return {
      text: 'Impossible actuellement de créer ta souscription aux push notifs',
      color: theme.palette.error.main,
    }
  } else if (Notification.permission === 'denied') {
    return {
      text:
        'Les notifications ont été bloquées par ton navigateur. Réactive les manuellement !',
      color: theme.palette.error.main,
    }
  } else if (!isFirebaseTokenFound) {
    return {
      text:
        "Mmmh, il semblerait qu'il y ait un petit problème pour t'inscrire aux pushs notifs",
      color: theme.palette.error.main,
    }
  } else {
    return { text: '', color: undefined }
  }
}
export default function DisclaimerNotifications() {
  const classes = useStylesPopover()
  const history = useHistory()
  const { loadingCount, incrementLoading, decrementLoading } = React.useContext(
    LoadingContext
  )
  const {
    isFirebaseTokenFound,
    triggerFirebaseToken,
    userPreferences,
  } = useContext(NotificationsContext)

  const isDarkMode = useMemo(() => getDarkModeLocalStorage(), [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const boo: boolean = event.target.checked
    if (boo === true) {
      incrementLoading()
      Notification.requestPermission(function (status) {
        if (status === 'granted') {
          triggerFirebaseToken()
          decrementLoading()
        } else if (status === 'denied') {
          alert('Tu viens de me refuser les notifs là tu sais...')
          decrementLoading()
        }
      })
    }
  }
  const textAndColorForNotifs = conditionsAndColorForNotifsIcon(
    isFirebaseTokenFound,
    userPreferences,
    isDarkMode ? darkTheme : lightTheme
  )

  const ShowDisclaimer = () => {
    return (
      <div
        onClick={() => history.push('/notifications')}
        style={{ cursor: 'pointer' }}
      >
        <Divider style={{ marginBottom: 10 }} />
        <ListItem dense>
          <ListItemIcon>
            {isFirebaseTokenFound &&
            userPreferences.allowPushNotifications === true ? (
              <NotificationsActiveIcon />
            ) : (
              <NotificationsPausedIcon
                style={{
                  color: textAndColorForNotifs.color,
                }}
              />
            )}
          </ListItemIcon>
          <ListItemText className={classes.listItemText}>
            <Typography
              style={{
                color: textAndColorForNotifs.color,
                fontWeight: 100,
              }}
            >
              {textAndColorForNotifs.text}
            </Typography>
          </ListItemText>
          {firebase.messaging.isSupported() &&
            Notification.permission === 'default' && (
              <ListItemSecondaryAction
                className={classes.buttonSecondaryAction}
              >
                <Switch
                  color="primary"
                  checked={isFirebaseTokenFound}
                  onChange={handleChange}
                  name="checkedA"
                  inputProps={{ 'aria-label': 'allow notifs checkbox' }}
                  disabled={loadingCount > 0}
                />
              </ListItemSecondaryAction>
            )}
        </ListItem>
      </div>
    )
  }
  return (
    <div id="allowNotifs" style={{ margin: 15 }}>
      {textAndColorForNotifs.text !== '' && <ShowDisclaimer />}
    </div>
  )
}
