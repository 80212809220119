import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import 'firebase/analytics'
import firebase from 'firebase/app'
import { useSnackbar } from 'notistack'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { LoadingContext } from '../../../contexts/loading/LoadingContext'
import { NotificationsContext } from '../../../contexts/notifications/NotificationsContext'
import { putBookingToDelete } from '../../../pages/Catalogue/utils'
import MyButton from '../../Buttons/MyButton'
import { PropsPopoverItem } from '../utils'
import { useStylesPopover } from './NotificationsPopoverListItem'

export const routeForBooking = '/emprunts/reservations'

export const PopoverBookingInThePast = ({
  borrower_start,
  startdate,
  title,
  originalId,
  forAdmin,
  closePopover,
}: PropsPopoverItem) => {
  const analytics = firebase.analytics()
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  const { incrementLoading, decrementLoading } = useContext(LoadingContext)
  const { triggerGetDataNotifications } = useContext(NotificationsContext)
  const classes = useStylesPopover()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const refreshData = () => {
    triggerGetDataNotifications()
  }
  const handleCancelBooking = () => {
    incrementLoading()
    putBookingToDelete(
      originalId,
      enqueueSnackbar,
      'Annulation rapide par les notifs',
      decrementLoading,
      refreshData,
      closePopover
    )
    analytics.logEvent('notifsPopover-cancel_bookings')
  }
  const handleRedirectBookingsPage = () => {
    closePopover()
    history.push(routeForBooking)
    analytics.logEvent('notifsPopover-go_to-bookings')
  }
  return (
    <div>
      <Divider />
      <ListItem button>
        <ListItemIcon>
          <WatchLaterIcon />
        </ListItemIcon>
        <ListItemText
          onClick={handleRedirectBookingsPage}
          className={classes.listItemText}
          primary={'Réservation non transformée'}
          secondary={
            forAdmin
              ? 'La réservation "'.concat(
                  title,
                  '" faite par ',
                  borrower_start?.split(' ')[0],
                  ' qui devait débuter ',
                  moment(startdate).fromNow(),
                  " n'a pas été transformée en emprunt"
                )
              : 'Ta réservation "'.concat(
                  title,
                  '" qui devait débuter ',
                  moment(startdate).fromNow(),
                  " n'a pas été transformée en emprunt"
                )
          }
        />
        <ListItemSecondaryAction>
          <MyButton title="Annuler" callback={handleCancelBooking} secondary />
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  )
}
