import {
  DialogContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import { LoadingContext } from '../../../contexts/loading/LoadingContext'
import {
  Booking,
  Borrowing,
  BorrowingStatus,
  getAllBorrowings,
} from '../../../pages/History/utils'
import { getToken } from '../../../utils/Auth'
import BookingCard from '../../Bookings/BookingCard'
import BorrowingCard from '../../Borrowings/BorrowingCard'

interface Props {
  openHistorySearchDialog: boolean
  setOpenHistorySearchDialog: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void
  bookings?: Booking[]
  rerenderParentCallback: Function
}
const useStyles = makeStyles((theme) => ({
  icon: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[600],
  },
  textField: { width: '95%' },
}))
export default function HistorySearchDialog({
  openHistorySearchDialog,
  setOpenHistorySearchDialog,
  bookings,
  rerenderParentCallback,
}: Props) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { decrementLoading } = useContext(LoadingContext)
  const [borrowings, setBorrowings] = React.useState<Borrowing[]>()
  const [inputSearch, setInputSearch] = React.useState<string>('')
  const handleClose = () => {
    setOpenHistorySearchDialog(false)
  }
  const filterBorrowing = (elt) => {
    return (
      elt.title.toLowerCase().indexOf(inputSearch) > -1 ||
      elt.borrower_start.toLowerCase().indexOf(inputSearch) > -1 ||
      elt.borrower_log_start.toLowerCase().indexOf(inputSearch) > -1
    )
  }
  React.useEffect(() => {
    getAllBorrowings(getToken(), enqueueSnackbar, decrementLoading).then(
      (res) => {
        setBorrowings(res)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div>
      <Dialog
        open={openHistorySearchDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <TextField
              autoComplete="off"
              variant="outlined"
              className={classes.textField}
              autoFocus={true}
              placeholder={'Emprunt, photographe'}
              onChange={(e) =>
                setInputSearch(e.target.value.toLocaleLowerCase())
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRoundedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton
              onClick={() => handleClose()}
              className={classes.icon}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>

        {inputSearch.length > 1 && (
          <Grid
            alignContent="center"
            justify="center"
            alignItems="center"
            container
          >
            <DialogContent>
              {bookings
                ?.filter((elt) => filterBorrowing(elt))
                .map((item) => {
                  return (
                    <Grid item key={item.id}>
                      <WatchLaterIcon />
                      <BookingCard
                        booking={item}
                        rerenderParentCallback={rerenderParentCallback}
                      />
                    </Grid>
                  )
                })}
              <Divider />
              {borrowings
                ?.filter((elt) => filterBorrowing(elt))
                .filter((elt) => elt.status === BorrowingStatus.SORTI)
                .map((item) => {
                  return (
                    <Grid item key={item.id}>
                      <PlayCircleFilledIcon />
                      <BorrowingCard
                        borrowing={item}
                        rerenderParentCallback={rerenderParentCallback}
                      />
                    </Grid>
                  )
                })}
              <Divider />
              {borrowings
                ?.filter((elt) => filterBorrowing(elt))
                .filter((elt) => elt.status === BorrowingStatus.TERMINE)
                .map((item) => {
                  return (
                    <Grid item key={item.id}>
                      <CheckCircleIcon />
                      <BorrowingCard
                        borrowing={item}
                        rerenderParentCallback={rerenderParentCallback}
                      />
                    </Grid>
                  )
                })}
            </DialogContent>
          </Grid>
        )}
      </Dialog>
    </div>
  )
}
