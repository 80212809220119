import {
  AppBar,
  Badge,
  Fab,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import TimelineIcon from '@material-ui/icons/Timeline'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import clsx from 'clsx'
import 'firebase/analytics'
import firebase from 'firebase/app'
import React from 'react'
import { Link } from 'react-router-dom'
import 'swiper/components/pagination/pagination.min.css'
import SwiperCore, { History, Navigation, Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import SchoolYearSelectPopover from '../../components/History/SchoolYearSelectPopover'
import HistorySearchDialog from '../../components/History/SearchDialog'
import { IS_MOBILE_MODE_SIZE } from '../../utils/Front'
import { appBarHeight, useFabStyles } from '../../utils/various'
import { showTitleAccordingToEnv } from '../../utils/Version'
import { DisplayHistory, DisplayNextBookings } from './DisplayHistories'
import { DataHistory, SchoolYearDateRange } from './utils'

SwiperCore.use([Navigation, Pagination, History])

export enum SwiperIndexHistory {
  BOOKING = 0,
  SORTI = 1,
  TERMINE = 2,
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: `calc(2*${appBarHeight})`,
    backgroundColor: theme.palette.background.paper,
    paddingBottom: appBarHeight,
  },
  mainTabs: { display: 'flex', flexWrap: 'nowrap', alignItems: 'stretch' },
  tabs: {
    flex: 1,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  nestedAppBar: {
    marginTop: appBarHeight,
    height: appBarHeight,
    backgroundColor: theme.palette.background.default,
  },
  typoYear: { fontWeight: 600, color: theme.palette.primary.main },
  indicator: {
    backgroundColor: theme.palette.indicators.isSelected,
    height: '10px',
    top: '45px',
  },
  tabsTypography: { marginLeft: theme.spacing(2) },
  tabsGrid: { justifyContent: 'center' },
}))

interface Props {
  data?: DataHistory
  rerenderParentCallback: any
  openOnSlide?: SwiperIndexHistory
  allPossibleSchoolYears: SchoolYearDateRange[]
  selectedSchoolYearDateRange: SchoolYearDateRange
  setSelectedSchoolYearDateRange: any
}

export default function HistoryTabs({
  data,
  rerenderParentCallback,
  openOnSlide,
  allPossibleSchoolYears,
  selectedSchoolYearDateRange,
  setSelectedSchoolYearDateRange,
}: Props) {
  const theme = useTheme()
  var moment = require('moment')
  const isMobileMode = useMediaQuery(
    theme.breakpoints.down(IS_MOBILE_MODE_SIZE)
  )
  const analytics = firebase.analytics()
  const classes = useStyles()
  const fabClasses = useFabStyles()
  const [swiperInstance, setSwiperInstance] = React.useState<SwiperCore>()
  const [valueSwiper, setValueSwiper] = React.useState<SwiperIndexHistory>(
    openOnSlide ? openOnSlide : SwiperIndexHistory.SORTI
  )

  const [
    openHistorySearchDialog,
    setOpenHistorySearchDialog,
  ] = React.useState<boolean>(false)
  const [anchorPopoverDate, setAnchorPopoverDate] = React.useState(null)
  const handleSchoolYearFABClick = (event) => {
    analytics.logEvent('history-change_year-FAB')
    setAnchorPopoverDate(event.currentTarget)
  }

  React.useEffect(() => {
    switch (valueSwiper) {
      case 0:
        document.title = showTitleAccordingToEnv('Réservations')
        break
      case 1:
        document.title = showTitleAccordingToEnv('Emprunts sortis')
        break
      case 2:
        document.title = showTitleAccordingToEnv('Emprunts rendus')
        break
    }
  }, [valueSwiper])

  React.useEffect(() => {
    swiperInstance && swiperInstance.slideTo(valueSwiper + 1)
  }, [swiperInstance, valueSwiper])

  const handleClickOpenSearch = () => {
    analytics.logEvent('history-open_search-FAB')
    setOpenHistorySearchDialog(true)
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.nestedAppBar} color="default">
        <Tabs
          value={valueSwiper}
          onChange={(ev, newVal: number) => {
            setValueSwiper(newVal)
          }}
          scrollButtons="on"
          variant="fullWidth"
          className={classes.mainTabs}
          TabIndicatorProps={{ className: classes.indicator }}
        >
          <Tab
            icon={
              <Grid container className={classes.tabsGrid}>
                <Badge badgeContent={data?.bookings.length} color={'primary'}>
                  <WatchLaterIcon />
                </Badge>
                <Typography className={classes.tabsTypography}>
                  {!isMobileMode && 'Réservations'}
                </Typography>
              </Grid>
            }
            className={classes.tabs}
            value={SwiperIndexHistory.BOOKING}
          />
          <Tab
            icon={
              <Grid container className={classes.tabsGrid}>
                <Badge
                  badgeContent={data?.borrowingsSorti?.length}
                  color={'primary'}
                >
                  <PlayCircleFilledIcon />
                </Badge>
                <Typography className={classes.tabsTypography}>
                  {!isMobileMode && 'En cours'}
                </Typography>
              </Grid>
            }
            value={SwiperIndexHistory.SORTI}
            className={classes.tabs}
          />
          <Tab
            icon={
              <Grid container className={classes.tabsGrid}>
                <CheckCircleIcon />
                <Typography className={classes.tabsTypography}>
                  {!isMobileMode && 'Terminés'}
                </Typography>
              </Grid>
            }
            className={classes.tabs}
            value={SwiperIndexHistory.TERMINE}
          />
        </Tabs>
      </AppBar>
      <Swiper
        onSwiper={setSwiperInstance}
        spaceBetween={5}
        loop={true}
        slidesPerView={1}
        history={{
          key: 'emprunts',
        }}
        onSlideChange={(swiper) => setValueSwiper(swiper.realIndex)}
      >
        <SwiperSlide data-history="reservations">
          <DisplayNextBookings
            array={data?.bookings}
            rerenderParentCallback={rerenderParentCallback}
          />
        </SwiperSlide>
        <SwiperSlide data-history="sortis">
          <DisplayHistory
            array={data?.borrowingsSorti}
            rerenderParentCallback={rerenderParentCallback}
          />
        </SwiperSlide>
        <SwiperSlide data-history="rendus">
          <DisplayHistory
            array={data?.borrowingsTermine}
            rerenderParentCallback={rerenderParentCallback}
          />
        </SwiperSlide>
      </Swiper>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="Recherche"
        className={clsx(
          fabClasses.common,
          fabClasses.firstTop,
          fabClasses.rounded
        )}
        onClick={handleClickOpenSearch}
      >
        <SearchRoundedIcon color={'primary'} />
      </Fab>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="GoToMetrics"
        className={clsx(
          fabClasses.common,
          fabClasses.secondTop,
          fabClasses.rounded
        )}
      >
        <Link
          rel="canonical"
          to="/historique"
          onClick={() => analytics.logEvent('emprunts-go_to-metrics-FAB')}
        >
          <TimelineIcon />
        </Link>
      </Fab>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="Change_year"
        className={clsx(
          fabClasses.common,
          fabClasses.firstBottom,
          fabClasses.rounded
        )}
        onClick={handleSchoolYearFABClick}
      >
        <Typography className={classes.typoYear} variant={'body2'}>
          {moment(selectedSchoolYearDateRange.start).format('YY')}{' '}
          {moment(selectedSchoolYearDateRange.end).format('YY')}
        </Typography>
      </Fab>
      <SchoolYearSelectPopover
        anchorPopoverDate={anchorPopoverDate}
        setAnchorPopoverDate={setAnchorPopoverDate}
        allPossibleSchoolYears={allPossibleSchoolYears}
        selectedSchoolYearDateRange={selectedSchoolYearDateRange}
        handleChangeSchoolYear={setSelectedSchoolYearDateRange}
      />
      <HistorySearchDialog
        bookings={data?.bookings}
        openHistorySearchDialog={openHistorySearchDialog}
        setOpenHistorySearchDialog={setOpenHistorySearchDialog}
        rerenderParentCallback={rerenderParentCallback}
      />
    </div>
  )
}
