import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import 'firebase/analytics'
import firebase from 'firebase/app'
import { useSnackbar } from 'notistack'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { LoadingContext } from '../../../contexts/loading/LoadingContext'
import { NotificationsContext } from '../../../contexts/notifications/NotificationsContext'
import { putEndBorrowing } from '../../../pages/History/utils'
import { getGivenName, getToken } from '../../../utils/Auth'
import { getAbbreviatedName } from '../../../utils/various'
import MyButton from '../../Buttons/MyButton'
import { PropsPopoverItem } from '../utils'
import { useStylesPopover } from './NotificationsPopoverListItem'

export const routeForBorrowing = '/emprunts/sortis'

export const PopoverBorrowingLate = ({
  borrower_start,
  enddate,
  title,
  originalId,
  forAdmin,
  closePopover,
}: PropsPopoverItem) => {
  const analytics = firebase.analytics()
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  const classes = useStylesPopover()
  const { incrementLoading, decrementLoading } = useContext(LoadingContext)
  const { triggerGetDataNotifications } = useContext(NotificationsContext)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const refreshData = () => {
    triggerGetDataNotifications()
  }

  const handleBorrowingEnd = () => {
    incrementLoading()
    putEndBorrowing(
      {
        title: title,
        id: originalId,
        enddate: moment().format('yyyy-MM-DDTHH:mm:ss'),
        borrower_log_end: getGivenName(),
        comment_end: 'Rendu rapide par les notifs',
      },
      getToken(),
      decrementLoading,
      enqueueSnackbar,
      refreshData,
      closePopover
    )
    analytics.logEvent('notifsPopover-end_borrowings')
  }
  const handleRedirectBorrowingsPage = () => {
    closePopover()
    history.push(routeForBorrowing)
    analytics.logEvent('notifsPopover-go_to-borrowings')
  }
  return (
    <div>
      <Divider />
      <ListItem button>
        <ListItemIcon>
          <PlayCircleFilledIcon />
        </ListItemIcon>
        <ListItemText
          onClick={handleRedirectBorrowingsPage}
          className={classes.listItemText}
          primary={'Emprunt non rendu'}
          secondary={
            forAdmin
              ? "L'emprunt ".concat(
                  '"',
                  title,
                  '" de ',
                  getAbbreviatedName(borrower_start),
                  ' qui devait finir ',
                  moment(enddate).fromNow(),
                  " n'a pas été rendu"
                )
              : 'Ton emprunt "'.concat(
                  title,
                  '" qui devait finir ',
                  moment(enddate).fromNow(),
                  " n'a pas été rendu"
                )
          }
        />
        <ListItemSecondaryAction>
          <MyButton title="Rendre" callback={handleBorrowingEnd} />
        </ListItemSecondaryAction>
      </ListItem>
    </div>
  )
}
