import {
  Checkbox,
  createStyles,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import PersonIcon from '@material-ui/icons/Person'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import SmsIcon from '@material-ui/icons/Sms'
import Autocomplete from '@material-ui/lab/Autocomplete'
import classNames from 'classnames'
import React from 'react'
import {
  MatosDetailInBorrowingOrBooking,
  MatosDetails,
  MatosStatusEnum,
} from '../../pages/Catalogue/utils'
import { Borrowing, BorrowingStatus } from '../../pages/History/utils'
import MyCustomListItem from '../MyCustomListItem'

export const transformMatosDetailInBorrowingOrBooking_To_MatosDetails = (
  matosDetailsInBorrowingOrBooking: MatosDetailInBorrowingOrBooking[]
): MatosDetails[] => {
  let temp: MatosDetails[] = []
  matosDetailsInBorrowingOrBooking.forEach((matos) => {
    temp.push({
      id: matos.id_matos,
      brand: matos.brand,
      model: matos.model,
      name: matos.name,
      mount: matos.mount,
      capacity: matos.capacity,
      type: matos.type,
      status: MatosStatusEnum.LOCAL,
      pics: matos.pics,
    })
  })
  return temp
}
export const showDetailedLogIfDifferent = (
  mainItem: string,
  secondItem: string
) => {
  if (mainItem === secondItem) {
    return mainItem
  } else {
    return mainItem.concat(' (', secondItem, ')')
  }
}

interface PropsDisplayBeginInfoBorrowing {
  borrowing: Borrowing
}
const useStylesBorrowingDialog = makeStyles((theme: Theme) =>
  createStyles({
    icon: { marginRight: 5, alignItems: 'center' },
    typoEndDateIfLate: { color: theme.palette.error.main, fontWeight: 600 },
  })
)
export const DisplayBeginInfoBorrowing = ({
  borrowing,
}: PropsDisplayBeginInfoBorrowing) => {
  var moment = require('moment')
  return (
    <span>
      <MyCustomListItem
        title={'Emprunté par'}
        text={borrowing.borrower_log_start}
        icon={<PersonIcon />}
        accentuatedColor
      />

      {borrowing.borrower_start !== borrowing.borrower_log_start && (
        <MyCustomListItem
          title={'Emprunté pour'}
          text={borrowing.borrower_start}
          icon={<PersonIcon />}
          accentuatedColor
        />
      )}
      <MyCustomListItem
        title={"Date d'emprunt"}
        text={showDetailedLogIfDifferent(
          moment(borrowing.startdate).format('dddd Do MMM LT'),
          moment(borrowing.startdate_log).format('dddd Do MMM LT')
        )}
        icon={<QueryBuilderIcon />}
        accentuatedColor
      />

      {borrowing.comment_start?.length > 0 && (
        <MyCustomListItem
          title={'Commentaire'}
          text={borrowing.comment_start}
          icon={<SmsIcon />}
          accentuatedColor
        />
      )}
    </span>
  )
}

export const DisplayEndInfoBorrowing = ({
  borrowing,
}: PropsDisplayBeginInfoBorrowing) => {
  const classes = useStylesBorrowingDialog()
  var moment = require('moment')
  const isLate =
    borrowing.status === BorrowingStatus.SORTI &&
    moment(borrowing.enddate).isBefore(new Date())
  return (
    <span>
      {borrowing.borrower_log_end && (
        <MyCustomListItem
          title={'Rendu par'}
          text={borrowing.borrower_log_end}
          icon={<PersonIcon />}
          accentuatedColor
        />
      )}
      <MyCustomListItem
        title={
          borrowing.status === BorrowingStatus.SORTI
            ? 'Retour prévu'
            : 'Rendu le'
        }
        text={moment(borrowing.enddate).format('dddd Do MMM LT')}
        icon={<QueryBuilderIcon />}
        accentuatedColor
        accentuatedColorError={classNames(isLate && classes.typoEndDateIfLate)}
      />

      {borrowing.comment_end &&
        borrowing.comment_end.length > 0 &&
        borrowing.comment_end.indexOf('PiP') === -1 &&
        borrowing.comment_end.indexOf('null') === -1 && (
          <MyCustomListItem
            title={'Commentaire de retour'}
            text={borrowing.comment_end}
            icon={<SmsIcon />}
            accentuatedColor
          />
        )}
    </span>
  )
}

interface PropsQuickAddMatosSearchBar {
  originalMatosBorrowed: MatosDetails[]
  catalog: MatosDetails[]
  selectedMatos: MatosDetails[]
  setSelectedMatos: (value: MatosDetails[]) => void
}
export const overrideMatosStatus = (
  matosArray: MatosDetails[],
  newStatus: MatosStatusEnum
) => {
  let temp: MatosDetails[] = []
  matosArray.forEach((matos) => {
    temp.push({ ...matos, status: newStatus })
  })
  return temp
}
export default function QuickAddMatosSearchBar({
  originalMatosBorrowed,
  catalog,
  selectedMatos,
  setSelectedMatos,
}: PropsQuickAddMatosSearchBar) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const handleChangeSelectedMatos = (event: object, value: any) => {
    setSelectedMatos(value)
  }
  const fullMatosToId = (inputArray: MatosDetails[]) => {
    var arr: number[] = []
    inputArray.forEach((matos) => {
      arr.push(matos.id)
    })
    return arr
  }
  const shouldBeDisabled = (matos: MatosDetails) => {
    if (fullMatosToId(originalMatosBorrowed).includes(matos.id)) {
      return false
    } else {
      return matos.status !== MatosStatusEnum.LOCAL
    }
  }
  const isSelected = (matos: MatosDetails) => {
    return fullMatosToId(selectedMatos).includes(matos.id)
  }
  return (
    <Autocomplete
      multiple
      id="checkboxes-QuickAddMatosSearchBar"
      options={catalog}
      disableCloseOnSelect={true}
      groupBy={(option) => option.type}
      defaultValue={originalMatosBorrowed}
      getOptionSelected={(option) => isSelected(option)}
      getOptionDisabled={(option) => shouldBeDisabled(option)}
      value={selectedMatos}
      getOptionLabel={(option) => option.name}
      onChange={handleChangeSelectedMatos}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            color={'primary'}
          />
          {option.name}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          variant="outlined"
          label="Matos"
        />
      )}
    />
  )
}
