import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import BatteryFullIcon from '@material-ui/icons/BatteryFull'
import CameraIcon from '@material-ui/icons/Camera'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import SdCardIcon from '@material-ui/icons/SdCard'
import 'firebase/analytics'
import firebase from 'firebase/app'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import { LoadingContext } from '../../../contexts/loading/LoadingContext'
import {
  getMatosSorti,
  MatosLive,
  MatosTypeEnum,
} from '../../../pages/Catalogue/utils'
import { Borrowing } from '../../../pages/History/utils'
import { getToken } from '../../../utils/Auth'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { justifyContent: 'center' },
    subRoot: {
      margin: theme.spacing(1),
      textAlign: 'center',
    },
    isActive: { color: theme.palette.indicators.isSelected },
    defaultIndicator: { color: theme.palette.indicators.default },
  })
)
interface Props {
  borrowingsLive: Borrowing[]
}
export default function SummaryMatosCouv({ borrowingsLive }: Props) {
  ///The most awful function I've ever made
  const analytics = firebase.analytics()
  const classes = useStyles()
  const history = useHistory()
  const { loadingCount, incrementLoading, decrementLoading } = useContext(
    LoadingContext
  )
  const { enqueueSnackbar } = useSnackbar()
  const [matosLive, setMatosLive] = React.useState<MatosLive[]>()
  async function getData() {
    incrementLoading()
    await getMatosSorti(getToken(), enqueueSnackbar, decrementLoading).then(
      (res) => {
        setMatosLive(res)
        decrementLoading()
      }
    )
  }
  React.useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [borrowingsLive])

  function getNumberOfMatos(type: MatosTypeEnum) {
    const n = matosLive?.filter((elt) => elt.type === type).length
    return { typo: <Typography className={getStyles(n)}>{n}</Typography>, n: n }
  }

  const individualGrid = (icon, type: MatosTypeEnum) => {
    return (
      <Grid container direction="column" className={classes.subRoot}>
        <Grid item>{icon}</Grid>
        <Grid item>
          {loadingCount > 0 ? (
            <CircularProgress size={20} />
          ) : (
            getNumberOfMatos(type).typo
          )}
        </Grid>
      </Grid>
    )
  }
  const getStyles = (n) => {
    if (n > 0) {
      return classes.isActive
    } else {
      return classes.defaultIndicator
    }
  }
  const handleClickGoToBorrowings = () => {
    analytics.logEvent('home_counters-go_to-borrowings')
    history.push('/emprunts/sortis')
  }
  return (
    <Grid
      container
      direction="row"
      className={classes.root}
      onClick={handleClickGoToBorrowings}
    >
      <Grid item>
        {individualGrid(
          <CameraAltIcon
            className={getStyles(getNumberOfMatos(MatosTypeEnum.BOITIER).n)}
          />,
          MatosTypeEnum.BOITIER
        )}
      </Grid>
      <Grid item>
        {individualGrid(
          <CameraIcon
            className={getStyles(getNumberOfMatos(MatosTypeEnum.OBJECTIF).n)}
          />,
          MatosTypeEnum.OBJECTIF
        )}
      </Grid>
      <Grid className={getStyles(getNumberOfMatos(MatosTypeEnum.CARTE).n)} item>
        {individualGrid(<SdCardIcon />, MatosTypeEnum.CARTE)}
      </Grid>
      <Grid
        className={getStyles(getNumberOfMatos(MatosTypeEnum.BATTERIE).n)}
        item
      >
        {individualGrid(<BatteryFullIcon />, MatosTypeEnum.BATTERIE)}
      </Grid>
      <Grid className={getStyles(getNumberOfMatos(MatosTypeEnum.STAND).n)} item>
        {individualGrid(<FlashOnIcon />, MatosTypeEnum.STAND)}
      </Grid>
      <Grid className={getStyles(getNumberOfMatos(MatosTypeEnum.OTHER).n)} item>
        {individualGrid(<LocalMallIcon />, MatosTypeEnum.OTHER)}
      </Grid>
    </Grid>
  )
}
