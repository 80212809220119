import {
  createStyles,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core'
import React, { useContext } from 'react'
import { NotificationsContext } from '../../../contexts/notifications/NotificationsContext'
import { getIsAdmin } from '../../../utils/Auth'
import { PopoverBookingInThePast } from './PopoverBookingInThePast'
import { PopoverBorrowingLate } from './PopoverBorrowingLate'

export const useStylesPopover = makeStyles((theme: Theme) =>
  createStyles({
    buttonSecondaryAction: { padding: 10, zIndex: 5, cursor: 'pointer' },
    listItemText: { paddingRight: 50 },
    error: {
      color: theme.palette.error.main,
    },
    warning: { color: theme.palette.warning.main },
    noNotifs: { paddingLeft: theme.spacing(3) },
  })
)
interface Props {
  closePopover: () => void
}
export const NotificationsPopoverListItem = ({ closePopover }: Props) => {
  const { notifications } = useContext(NotificationsContext)
  const isAdmin = getIsAdmin()
  const showNotifsForMe = () => {
    return notifications?.map((notif) => {
      if (notif.originalTable === 'borrowings') {
        return (
          <ListItem dense>
            <PopoverBorrowingLate
              borrower_start={notif.borrower_start}
              enddate={notif.enddate}
              title={notif.title}
              id={notif.id}
              originalId={notif.originalId}
              forAdmin={isAdmin}
              closePopover={() => {
                closePopover()
              }}
            />
          </ListItem>
        )
      } else {
        return (
          <ListItem dense>
            <PopoverBookingInThePast
              borrower_start={notif.borrower_start}
              startdate={notif.startdate}
              title={notif.title}
              id={notif.id}
              originalId={notif.originalId}
              forAdmin={isAdmin}
              closePopover={() => {
                closePopover()
              }}
            />
          </ListItem>
        )
      }
    })
  }

  const ShowNoNotifs = () => {
    return (
      <ListItem>
        <ListItemText
          secondary={'Aucune notif pour toi'}
          className={useStylesPopover().noNotifs}
        />
      </ListItem>
    )
  }
  return (
    <div id="NotificationsPopoverListItem">
      {notifications?.length > 0 ? showNotifsForMe() : <ShowNoNotifs />}
    </div>
  )
}
