import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SendIcon from '@material-ui/icons/Send'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import React from 'react'
import SummaryIndividualCardMatos from '../../../components/Matos/SummaryIndividualCardMatos'
import { MatosDetails } from '../../../pages/Catalogue/utils'
import MyButton from '../../Buttons/MyButton'

const useStyles = makeStyles((theme) => ({
  extendedIcon: { marginRight: theme.spacing(1) },
  reserverButton: { backgroundColor: theme.palette.background.paper },
}))

interface Props {
  selectedMatos: MatosDetails[]
  handleRemoveMatos: any
  handleClose: Function
  actionMainButton: Function
  setOpenDialogBooking: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void
  hasOneMatosAlreadyBorrowed: boolean
  isAdminAccess?: boolean
  titleMain: string
  selectAllMatosFromCatalog?: Function
}

export default function CartDialog({
  selectedMatos,
  handleRemoveMatos,
  handleClose,
  actionMainButton,
  setOpenDialogBooking,
  hasOneMatosAlreadyBorrowed,
  isAdminAccess,
  titleMain,
  selectAllMatosFromCatalog,
}: Props) {
  const classes = useStyles()
  const [
    selectAllMatosCheckbox,
    setSelectAllMatosCheckbox,
  ] = React.useState<boolean>(false)
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAllMatosCheckbox(event.target.checked)
    if (selectAllMatosFromCatalog) {
      selectAllMatosFromCatalog(event.target.checked)
    }
  }
  React.useEffect(() => {
    selectedMatos.length === 0 && handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatos])

  return (
    <>
      <DialogTitle id="alert-dialog-title">{'Panier'}</DialogTitle>
      {isAdminAccess && selectAllMatosFromCatalog && (
        <DialogContent>
          <Checkbox
            color="primary"
            checked={selectAllMatosCheckbox}
            onChange={handleChangeCheckbox}
            inputProps={{ 'aria-label': 'Select-all' }}
          />
          <Typography variant={'caption'}>
            Sélectionner tout le catalogue (sauf éléments en couverture)
          </Typography>
        </DialogContent>
      )}
      {selectedMatos.map((item: MatosDetails) => {
        return (
          <DialogContent>
            <SummaryIndividualCardMatos
              matos={item}
              handleRemoveMatos={handleRemoveMatos}
              canDeleteMatos={true}
            />
          </DialogContent>
        )
      })}
      <DialogActions>
        {!isAdminAccess && (
          <MyButton
            title={'Réserver'}
            callback={() => {
              setOpenDialogBooking(true)
            }}
            iconLeft={
              !isAdminAccess && (
                <WatchLaterIcon className={classes.extendedIcon} />
              )
            }
            secondary
          />
        )}
        {!hasOneMatosAlreadyBorrowed && (
          <MyButton
            title={titleMain}
            callback={() => {
              actionMainButton()
            }}
            iconLeft={
              !isAdminAccess && <SendIcon className={classes.extendedIcon} />
            }
          />
        )}
      </DialogActions>
    </>
  )
}
