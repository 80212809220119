import { DialogTitle, Grid, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  icon: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[600],
  },
}))

interface Props {
  title: string
  handleClose: () => void
}

export default function CustomDialogTitle({ title, handleClose }: Props) {
  const classes = useStyles()

  return (
    <DialogTitle id="alert-dialog-title">
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Typography variant={'h6'} noWrap>
          {title}
        </Typography>
        <IconButton
          onClick={() => handleClose()}
          className={classes.icon}
          aria-label="Close"
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </DialogTitle>
  )
}
