import {
  DialogContent,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  IconButton,
} from '@material-ui/core'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { MatosDetails } from '../../../pages/Catalogue/utils'
import IndividualCardMatos from '../../Matos/IndividualCardMatos'
import CloseIcon from '@material-ui/icons/Close'

interface Props {
  openCatalogSearchDialog: boolean
  handleClose: Function
  catalog: MatosDetails[]
  handleAddMatos: Function
  handleRemoveMatos: Function
  selectedMatos: MatosDetails[]
}
const useStyles = makeStyles((theme) => ({
  icon: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[600],
  },
  textField: { width: '95%' },
}))
export default function CatalogSearchDialog({
  openCatalogSearchDialog,
  handleClose,
  catalog,
  handleAddMatos,
  handleRemoveMatos,
  selectedMatos,
}: Props) {
  const classes = useStyles()
  const [inputSearch, setInputSearch] = React.useState<string>('')

  return (
    <div>
      <Dialog
        open={openCatalogSearchDialog}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <TextField
              autoComplete="off"
              variant="outlined"
              className={classes.textField}
              autoFocus={true}
              onChange={(e) =>
                setInputSearch(e.target.value.toLocaleLowerCase())
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRoundedIcon />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton
              onClick={() => handleClose()}
              className={classes.icon}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>

        {inputSearch.length > 1 && (
          <DialogContent>
            <Grid
              alignContent="center"
              justify="center"
              alignItems="center"
              container
            >
              {catalog
                .filter(
                  (matos) =>
                    matos.name.toLowerCase().indexOf(inputSearch) > -1 ||
                    matos.brand.toLowerCase().indexOf(inputSearch) > -1
                )
                .map((matos) => {
                  return (
                    <Grid item key={matos.id}>
                      <IndividualCardMatos
                        matos={matos}
                        handleAddMatos={handleAddMatos}
                        selectedMatos={selectedMatos}
                        handleRemoveMatos={handleRemoveMatos}
                      />
                    </Grid>
                  )
                })}
            </Grid>
          </DialogContent>
        )}
      </Dialog>
    </div>
  )
}
