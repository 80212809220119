import { Avatar, Theme } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import BlockRoundedIcon from '@material-ui/icons/BlockRounded'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'
import HomeRoundedIcon from '@material-ui/icons/HomeRounded'
import PanToolRoundedIcon from '@material-ui/icons/PanToolRounded'
import { MatosStatusEnum } from '../../pages/Catalogue/utils'

interface Props {
  status: MatosStatusEnum
  dontShowLabel?: boolean
}

export const useStylesForMatosChip = makeStyles((theme: Theme) => ({
  Archived: {
    backgroundColor: theme.palette.matos.backgroundColorStatus.Archived,
    color: theme.palette.matos.labelStatus.Archived,
  },
  Couv: {
    backgroundColor: theme.palette.matos.backgroundColorStatus.Couv,
    color: theme.palette.matos.labelStatus.Couv,
  },
  Local: {
    backgroundColor: theme.palette.matos.backgroundColorStatus.Local,
    color: theme.palette.matos.labelStatus.Local,
  },
  Indisponible: {
    backgroundColor: theme.palette.matos.backgroundColorStatus.Indisponible,
    color: theme.palette.matos.labelStatus.Indisponible,
  },
}))

export default function ChipMatosStatus({ status, dontShowLabel }: Props) {
  const classes = useStylesForMatosChip()
  const labelToShow = (status: MatosStatusEnum) => {
    switch (status) {
      case MatosStatusEnum.COUV:
        return 'Couverture'
      case MatosStatusEnum.INDISPONIBLE:
        return 'Indisponible'
      case MatosStatusEnum.LOCAL:
        return 'Local'
      case MatosStatusEnum.ARCHIVED:
        return 'Archivé'
    }
  }
  const iconToShow = (status: MatosStatusEnum) => {
    switch (status) {
      case MatosStatusEnum.COUV:
        return <PanToolRoundedIcon className={classes[status]} />
      case MatosStatusEnum.INDISPONIBLE:
        return <BlockRoundedIcon className={classes[status]} />
      case MatosStatusEnum.LOCAL:
        return <HomeRoundedIcon className={classes[status]} />
      case MatosStatusEnum.ARCHIVED:
        return <DeleteRoundedIcon className={classes[status]} />
    }
  }

  return (
    <Chip
      variant="outlined"
      avatar={<Avatar className={classes[status]}>{iconToShow(status)}</Avatar>}
      label={!dontShowLabel && labelToShow(status)}
      className={classes[status]}
    />
  )
}
