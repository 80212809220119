import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { Dispatch, SetStateAction } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import PACKAGE from '../../package.json'
import AppBar from '../components/AppBar'
import { FirebaseNotification } from '../firebase'
import { defaultValueToken, getIsAdmin, validateToken } from '../utils/Auth'
import { snackBarErrorOrFailToFetch } from '../utils/SnackBarGenericError'
import {
  EnvEnum,
  getNameOfEnv,
  getVersionBACK,
  showNotifReload,
  showNotifWrongEnv,
} from '../utils/Version'
import AccessDenied from './AccessDenied/AccessDenied'
import Admin from './Admin'
import Calendar from './Calendar'
import Catalogue from './Catalogue'
import { SwiperIndexCatalogue } from './Catalogue/utils'
import History from './History'
import { SwiperIndexHistory } from './History/HistoryTabs'
import HomePage from './HomePage'
import Login from './Login'
import Metrics from './Metrics'
import NotificationsPage from './Notifications'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      height: '100vh',
    },
  })
)

interface Props {
  token: string
  firebaseNotification: FirebaseNotification | null
  darkMode: boolean | undefined
  setDarkMode: Dispatch<SetStateAction<boolean | undefined>>
}

export const MainPages = ({
  token,
  firebaseNotification,
  darkMode,
  setDarkMode,
}: Props) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [isValid, setIsValid] = React.useState<boolean>(true)
  const isAdmin = getIsAdmin()

  React.useEffect(() => {
    validateToken(token, enqueueSnackbar).then((res) => res && setIsValid(res))
  }, [token, enqueueSnackbar])

  React.useEffect(() => {
    if (firebaseNotification) {
      enqueueSnackbar(
        firebaseNotification?.body
          ? firebaseNotification.title
              ?.toString()
              .concat(' || ', firebaseNotification.body.toString())
          : firebaseNotification.title,
        {
          variant: 'info',
          title: firebaseNotification.title,
        }
      )
    }
  }, [enqueueSnackbar, firebaseNotification])
  async function getVersion() {
    await getVersionBACK()
      .then((res: { version: string; env: string }) => {
        if (
          res.version !== PACKAGE.version &&
          /* Avoid to render snackbar in local */
          getNameOfEnv() !== EnvEnum.DEVELOPMENT
        ) {
          showNotifReload(
            classes,
            enqueueSnackbar,
            res.version,
            PACKAGE.version
          )
        }
        if (
          (process.env.REACT_APP_NODE_STAGING_ENV &&
            res.env !== process.env.REACT_APP_NODE_STAGING_ENV) ||
          (!process.env.REACT_APP_NODE_STAGING_ENV &&
            res.env !== process.env.NODE_ENV)
        ) {
          showNotifWrongEnv(
            enqueueSnackbar,
            res.env,
            process.env.REACT_APP_NODE_STAGING_ENV || process.env.NODE_ENV
          )
        }
      })
      .catch((error) => {
        snackBarErrorOrFailToFetch(error, enqueueSnackbar, 'getVersionBack')
      })
  }
  React.useEffect(() => {
    getVersion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isValid === true && token !== defaultValueToken) {
    return (
      <div id="main" className={classes.root}>
        <AppBar darkMode={darkMode} setDarkMode={setDarkMode} />
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/catalogue/boitiers">
            <Catalogue openOnSlide={SwiperIndexCatalogue.BOITIER} />
          </Route>
          <Route path="/catalogue/objectifs">
            <Catalogue openOnSlide={SwiperIndexCatalogue.OBJECTIF} />
          </Route>
          <Route path="/catalogue/cartes">
            <Catalogue openOnSlide={SwiperIndexCatalogue.CARTE} />
          </Route>
          <Route path="/catalogue/batteries">
            <Catalogue openOnSlide={SwiperIndexCatalogue.BATTERIE} />
          </Route>
          <Route path="/catalogue/stand">
            <Catalogue openOnSlide={SwiperIndexCatalogue.STAND} />
          </Route>
          <Route path="/catalogue/divers">
            <Catalogue openOnSlide={SwiperIndexCatalogue.OTHER} />
          </Route>
          <Route path="/emprunts/reservations" exact>
            <History openOnSlide={SwiperIndexHistory.BOOKING} />
          </Route>
          <Route path="/emprunts/sortis" exact>
            <History openOnSlide={SwiperIndexHistory.SORTI} />
          </Route>
          <Route path="/emprunts/rendus" exact>
            <History openOnSlide={SwiperIndexHistory.TERMINE} />
          </Route>
          <Route path="/planning" exact>
            <Calendar />
          </Route>
          <Route path="/historique" exact>
            <Metrics />
          </Route>
          <Route path="/admin" exact>
            {isAdmin ? <Admin /> : <AccessDenied />}
          </Route>
          <Route path="/notifications" exact>
            <NotificationsPage />
          </Route>
          <Route path="/catalogue" exact>
            <Redirect to="/catalogue/boitiers" />
          </Route>
          <Route path="/emprunts" exact>
            <Redirect to="/emprunts/sortis" />
          </Route>
          <Redirect to="/" />
        </Switch>
      </div>
    )
  } else {
    return (
      <>
        <Redirect to="/login" />
        <Route path="/login" exact>
          <Login />
        </Route>
      </>
    )
  }
}
