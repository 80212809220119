import {
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useContext, useMemo } from 'react'
import { LoadingContext } from '../../../contexts/loading/LoadingContext'
import {
  EventFromGoogleCalendar,
  getAllCalendars,
  getAPIKey,
} from '../../../pages/Calendar/utils'
import { getToken } from '../../../utils/Auth'
import { IS_MOBILE_MODE_SIZE } from '../../../utils/Front'
import IndividualEvent from '../../Calendar/IndividualEvent'

const useStyles = makeStyles((theme: Theme) => ({
  textIsEmpty: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 15,
    color: theme.palette.customText.secondary,
  },
}))

export default function NextEvents() {
  const classes = useStyles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  const { incrementLoading, decrementLoading } = useContext(LoadingContext)
  const [couv, setCouv] = React.useState<EventFromGoogleCalendar[]>([])
  const [studio, setStudio] = React.useState<EventFromGoogleCalendar[]>([])
  const [presta, setPresta] = React.useState<EventFromGoogleCalendar[]>([])
  const [interne, setInterne] = React.useState<EventFromGoogleCalendar[]>([])
  const [googleAPISecretKey, setGoogleAPISecretKey] = React.useState<string>('')
  const isMobileMode = useMediaQuery(
    theme.breakpoints.down(IS_MOBILE_MODE_SIZE)
  )
  React.useEffect(() => {
    setCouv([])
    getAPIKey(
      enqueueSnackbar,
      incrementLoading,
      decrementLoading,
      getToken(),
      setGoogleAPISecretKey
    )
    googleAPISecretKey &&
      getAllCalendars(
        incrementLoading,
        decrementLoading,
        enqueueSnackbar,
        googleAPISecretKey,
        setCouv,
        setPresta,
        setStudio,
        setInterne
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleAPISecretKey])

  const eventsToDisplay = useMemo(() => {
    return couv
      ?.concat(presta, interne, studio)
      .filter(
        (elt) =>
          moment().isBefore(elt.end.dateTime) &&
          moment().add(7, 'days').isAfter(elt.end.dateTime)
      )
      .sort(function (a, b) {
        return moment(a.start.dateTime).unix() - moment(b.start.dateTime).unix()
      })
      .slice(0, isMobileMode ? 3 : 6)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couv, interne, isMobileMode, presta, studio])

  return (
    <span id="NextEvents">
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justify="center"
      >
        {eventsToDisplay ? (
          eventsToDisplay.map((e) => {
            return (
              <Grid key={e.id}>
                <IndividualEvent event={e} />
              </Grid>
            )
          })
        ) : (
          <Typography variant={'caption'} className={classes.textIsEmpty}>
            Aucun évènement de prévu dans les 7 prochains jours.
          </Typography>
        )}
      </Grid>
    </span>
  )
}
