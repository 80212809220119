import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import 'firebase/analytics'
import firebase from 'firebase/app'
import React from 'react'
import { useHistory } from 'react-router-dom'
import PACKAGE from '../../../package.json'
import BorrowingsLive from '../../components/Home/BorrowingsLive'
import Information from '../../components/Home/Information'
import NextEvents from '../../components/Home/NextEvents'
import { appBarHeight, upperCaseFirst } from '../../utils/various'
import { showTitleAccordingToEnv } from '../../utils/Version'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootDiv: {
      backgroundColor: theme.palette.background.default,
      height: '100vh',
    },
    mainGrid: {
      marginTop: `${appBarHeight}`,
      backgroundColor: theme.palette.background.default,
      alignContent: 'center',
    },
    mainButton: {
      margin: theme.spacing(1),
      borderRadius: 10,
      backgroundColor: theme.palette.customButtons.main.default,
      justifyContent: 'center',
    },
    smallButton: { height: '50px', width: '125px' },
    catalogButton: { height: '65px', width: '200px' },
    buttonGrid: { textAlign: 'center', justifyContent: 'center' },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
      textAlign: 'center',
      textTransform: 'none',
      color: theme.palette.customText.primary,
    },
    paperGrid: {
      borderWidth: '5px',
      padding: theme.spacing(2),
      margin: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      borderRadius: '5px',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      textAlign: 'center',
      maxWidth: '95%',
    },
    textButton: {
      fontSize: 15,
      fontWeight: 'bold',
      marginTop: theme.spacing(1),
      textAlign: 'center',
      textTransform: 'none',
      color: theme.palette.customText.primary,
    },
    versionTypography: {
      color: theme.palette.customText.primary,
      textAlign: 'right',
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
)

export default function HomePage() {
  const analytics = firebase.analytics()
  const classes = useStyles()
  const history = useHistory()
  React.useEffect(() => {
    document.title = showTitleAccordingToEnv("Ap'Pics")
  }, [])

  const handleClickGoTo = (path: string) => {
    analytics.logEvent(`home-go_to-${path}`)
    history.push(path)
  }

  return (
    <div className={classes.rootDiv}>
      <Grid
        container
        direction="column"
        className={classes.mainGrid}
        justify="center"
      >
        <Grid
          container
          item
          direction="column"
          className={classes.paperGrid}
          justify="center"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textPrimary"
              variant="h2"
            >
              En cours
            </Typography>
          </Grid>
          <BorrowingsLive />
          <Grid item className={classes.buttonGrid}>
            <Button
              size="medium"
              variant="contained"
              className={classNames(classes.mainButton, classes.smallButton)}
              onClick={() => handleClickGoTo('/emprunts/rendus')}
              aria-label="goToBorrowings"
            >
              <Typography
                className={classes.textButton}
                color="textPrimary"
                gutterBottom
                variant="h3"
              >
                Emprunts
              </Typography>
            </Button>
            <Button
              size="medium"
              variant="contained"
              className={classNames(classes.mainButton, classes.smallButton)}
              onClick={() => handleClickGoTo('/emprunts/reservations')}
              aria-label="goToBookings"
            >
              <Typography
                className={classes.textButton}
                color="textPrimary"
                gutterBottom
                variant="h3"
              >
                Réservations
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid item className={classes.buttonGrid}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            className={classNames(classes.mainButton, classes.catalogButton)}
            onClick={() => handleClickGoTo('/catalogue')}
            aria-label="goToCatalogue"
          >
            <Typography
              className={classes.title}
              color="textPrimary"
              gutterBottom
              variant="h2"
            >
              Catalogue
            </Typography>
          </Button>
        </Grid>
        <Information customClass={classes.paperGrid} />
        <Grid
          container
          item
          direction="column"
          className={classes.paperGrid}
          justify="center"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textPrimary"
              gutterBottom
              variant="h2"
            >
              Couvertures à venir
            </Typography>
          </Grid>
          <NextEvents />
          <Grid item className={classes.buttonGrid}>
            <Button
              size="medium"
              variant="contained"
              className={classNames(classes.mainButton, classes.smallButton)}
              onClick={() => handleClickGoTo('/planning')}
              aria-label="goToPlanning"
            >
              <Typography
                className={classes.textButton}
                color="textPrimary"
                gutterBottom
                variant="h3"
              >
                Planning
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Typography variant="caption" className={classes.versionTypography}>
          {upperCaseFirst(
            process.env.REACT_APP_NODE_STAGING_ENV || process.env.NODE_ENV
          ).concat(' ', PACKAGE.version)}
        </Typography>
      </Grid>
    </div>
  )
}
