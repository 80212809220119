import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import { LoadingContext } from '../../contexts/loading/LoadingContext'
import { NotificationsContext } from '../../contexts/notifications/NotificationsContext'
import { getToken } from '../../utils/Auth'
import HistoryTabs, { SwiperIndexHistory } from './HistoryTabs'
import {
  Booking,
  Borrowing,
  BorrowingStatus,
  DataHistory,
  getAllPossibleSchoolYears,
  getBorrowingsBetweenDateRange,
  getNextBookings,
  SchoolYearDateRange,
} from './utils'

interface Props {
  openOnSlide?: SwiperIndexHistory
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignContent: 'center',
      backgroundColor: theme.palette.background.paper,
      height: '100vh',
    },
  })
)
export default function History({ openOnSlide }: Props) {
  const token = getToken()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { notifications } = useContext(NotificationsContext)
  const allPossibleSchoolYears = getAllPossibleSchoolYears()
  const [
    selectedSchoolYearDateRange,
    setSelectedSchoolYearDateRange,
  ] = React.useState<SchoolYearDateRange>(
    allPossibleSchoolYears[allPossibleSchoolYears.length - 1]
  )
  const [data, setData] = React.useState<DataHistory>()
  const { incrementLoading, decrementLoading } = useContext(LoadingContext)
  React.useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications?.length, selectedSchoolYearDateRange])

  async function getData() {
    let borrowings: Borrowing[] = []
    let nextBookings: Booking[] = []
    incrementLoading()
    await getBorrowingsBetweenDateRange(
      token,
      enqueueSnackbar,
      decrementLoading,
      selectedSchoolYearDateRange
    ).then(async (res) => {
      borrowings = res?.sort((a, b) =>
        b.startdate_log.localeCompare(a.startdate_log)
      )
      decrementLoading()
      incrementLoading()
      await getNextBookings(token, enqueueSnackbar, decrementLoading).then(
        (res) => {
          nextBookings = res?.sort((a, b) =>
            b.startdate_log.localeCompare(a.startdate_log)
          )
          setData({
            borrowingsTermine: borrowings.filter(
              (item) => item.status === BorrowingStatus.TERMINE
            ),
            borrowingsSorti: borrowings.filter(
              (item) => item.status === BorrowingStatus.SORTI
            ),
            bookings: nextBookings,
          })
          decrementLoading()
        }
      )
    })
  }
  return (
    <div className={classes.root}>
      <HistoryTabs
        data={data}
        rerenderParentCallback={getData}
        openOnSlide={openOnSlide}
        allPossibleSchoolYears={allPossibleSchoolYears}
        selectedSchoolYearDateRange={selectedSchoolYearDateRange}
        setSelectedSchoolYearDateRange={setSelectedSchoolYearDateRange}
      />
    </div>
  )
}
