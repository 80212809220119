import React, { useContext } from 'react'
import { DataGrid, GridColDef, GridSortDirection } from '@material-ui/data-grid'
import { getAllFrontNotifs } from './utils'
import { LoadingContext } from '../../contexts/loading/LoadingContext'
import { useSnackbar } from 'notistack'
import { makeStyles, Theme } from '@material-ui/core'
import { FrontNotificationsRES } from '../../components/AppBar/utils'

const columns: GridColDef[] = [
  { field: 'originalTable', headerName: 'Type', flex: 1 },
  { field: 'title', headerName: 'Titre', flex: 2 },
  { field: 'createdAt', headerName: 'Créée le (UTC)', flex: 2 },
  { field: 'removedAt', headerName: 'Supprimée le (UTC)', flex: 2 },
]

const useStyles = makeStyles((theme: Theme) => ({
  dataGrid: {
    padding: theme.spacing(2),
  },
}))
export default function HistoryNotificationsFrontCreated() {
  const classes = useStyles()

  const [rows, setRows] = React.useState<Readonly<FrontNotificationsRES[]>>([])
  const { enqueueSnackbar } = useSnackbar()
  const { incrementLoading, decrementLoading } = useContext(LoadingContext)
  const getData = () => {
    incrementLoading()
    getAllFrontNotifs(enqueueSnackbar, decrementLoading).then((res) => {
      setRows(res)
      decrementLoading()
    })
  }
  React.useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div style={{ height: 680, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        className={classes.dataGrid}
        sortModel={[
          {
            field: 'removedAt',
            sort: 'desc' as GridSortDirection,
          },
        ]}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
      />
    </div>
  )
}
