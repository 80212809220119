import { Divider, InputAdornment } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined'
import InfoIcon from '@mui/icons-material/Info'
import 'firebase/analytics'
import firebase from 'firebase/app'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import { LoadingContext } from '../../contexts/loading/LoadingContext'
import {
  convertToUTC,
  getCatalogExceptArchived,
  MatosDetailInBorrowingOrBooking,
  MatosDetails,
  MatosStatusEnum,
  NewBorrowingOrBookingForm,
  postNewBorrowing,
} from '../../pages/Catalogue/utils'
import {
  Borrowing,
  BorrowingStatus,
  EndBorrowingForm,
  getBorrowingsOrBookingsDetails,
  putChangeBorrowing,
  putEndBorrowing,
} from '../../pages/History/utils'
import { getGivenName, getToken } from '../../utils/Auth'
import { LIMIT_VARCHAR, LIMIT_VARCHAR_COMMENT } from '../../utils/various'
import MyButton from '../Buttons/MyButton'
import { checkIfBorrowingFormHasError } from '../Catalogue/DialogValidationCart/DialogValidationCart'
import { TextfieldsForValidationCart } from '../Catalogue/DialogValidationCart/TextfieldsForValidationCart'
import CustomDialogTitle from '../Dialog/CustomDialogTitle'
import MyCustomListItem from '../MyCustomListItem'
import MatosItemsInCreatedBorrowAccordeon from './MatosItemsInCreatedBorrowAccordeon'
import QuickAddMatosSearchBar, {
  DisplayBeginInfoBorrowing,
  DisplayEndInfoBorrowing,
  overrideMatosStatus,
  transformMatosDetailInBorrowingOrBooking_To_MatosDetails,
} from './utils'

interface Props {
  openDetails: boolean
  originalBorrowing: Borrowing
  setOpenDetails: any
  rerenderParentCallback: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    commentInput: { margin: theme.spacing(2), width: '90%' },
    icon: { marginRight: 5, alignItems: 'center' },
    title: { marginBottom: theme.spacing(2) },
    dialogContent: { marginTop: 10, marginBottom: 10 },
    divider: { width: '100%' },
    contentText: { margin: theme.spacing(1) },
  })
)

enum TypeOfUpdateEnum {
  NEW_BORROWING = 'NEW_BORROWING',
  UPDATE = 'UPDATE',
}

export default function BorrowingCardDetails({
  openDetails,
  originalBorrowing,
  setOpenDetails,
  rerenderParentCallback,
}: Props) {
  var moment = require('moment')
  const analytics = firebase.analytics()
  const classes = useStyles()
  const token = getToken()
  const givenName = getGivenName()
  const isSorti = originalBorrowing.status === BorrowingStatus.SORTI
  const [catalog, setCatalog] = React.useState<MatosDetails[]>([])
  const [typeOfUpdate, setTypeOfUpdate] = React.useState<TypeOfUpdateEnum>(
    TypeOfUpdateEnum.UPDATE
  )
  const [editionInterface, setEditionInterface] = React.useState<boolean>(false)
  const { incrementLoading, decrementLoading } = useContext(LoadingContext)
  const { enqueueSnackbar } = useSnackbar()
  const [
    originalBorrowingDetails,
    setOriginalBorrowingDetails,
  ] = React.useState<MatosDetailInBorrowingOrBooking[]>([])
  const [
    bodyEndBorrowing,
    setBodyEndBorrowing,
  ] = React.useState<EndBorrowingForm>({
    title: originalBorrowing.title,
    id: originalBorrowing.id,
    enddate: moment.utc().format().substr(0, 19),
    borrower_log_end: givenName,
    comment_end: null,
  })
  const [
    formStateEdition,
    setFormStateEdition,
  ] = React.useState<NewBorrowingOrBookingForm>({
    title: originalBorrowing.title,
    borrower_log_start: originalBorrowing.borrower_log_start,
    borrower_start: originalBorrowing.borrower_start,
    comment_start: originalBorrowing.comment_start,
    startdate_log: originalBorrowing.startdate_log,
    startdate: originalBorrowing.startdate,
    enddate: moment(originalBorrowing.enddate).format('yyyy-MM-DDTHH:mm:ss'),
    status: BorrowingStatus.SORTI,
    matos: transformMatosDetailInBorrowingOrBooking_To_MatosDetails(
      originalBorrowingDetails
    ),
  })
  async function getInitialBorrowingData() {
    incrementLoading()
    await getBorrowingsOrBookingsDetails(
      token,
      originalBorrowing.id,
      enqueueSnackbar,
      decrementLoading,
      true
    ).then((res) => {
      setOriginalBorrowingDetails(res)
      decrementLoading()
    })
    incrementLoading()
    await getCatalogExceptArchived(
      token,
      enqueueSnackbar,
      decrementLoading
    ).then((res) => {
      setCatalog(res)
      decrementLoading()
    })
  }
  const handleClose = () => {
    setOpenDetails(false)
  }

  function handleRemoveMatos(matosToRemove: MatosDetails) {
    const newSelection = originalBorrowingDetails.filter(
      (matos) => matos.id_matos !== matosToRemove.id
    )
    setFormStateEdition({
      ...formStateEdition,
      matos: transformMatosDetailInBorrowingOrBooking_To_MatosDetails(
        newSelection
      ),
    })
  }

  React.useEffect(() => {
    getInitialBorrowingData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDetails, originalBorrowing])

  React.useEffect(() => {
    setFormStateEdition({
      ...formStateEdition,
      matos: overrideMatosStatus(
        transformMatosDetailInBorrowingOrBooking_To_MatosDetails(
          originalBorrowingDetails
        ),
        MatosStatusEnum.COUV
      ),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editionInterface])

  const handleEditionValidation = () => {
    incrementLoading()

    let body: any = {
      ...formStateEdition,
    } /* Pas ouf mais faut rajouter l'id dans les params */
    body.enddate = convertToUTC(formStateEdition.enddate)

    if (typeOfUpdate === TypeOfUpdateEnum.UPDATE) {
      analytics.logEvent('borrowingDialog-edition-UPDATE')
      body.id = originalBorrowing.id
      putChangeBorrowing(
        body,
        token,
        decrementLoading,
        enqueueSnackbar,
        rerenderParentCallback,
        handleClose
      )
    } else {
      analytics.logEvent('borrowingDialog-edition-NEW_BORROWING')
      body.startdate = moment().format('yyyy-MM-DDTHH:mm:ss')
      body.startdate_log = moment().format('yyyy-MM-DDTHH:mm:ss')
      body.borrower_log_start = getGivenName()
      putEndBorrowing(
        bodyEndBorrowing,
        token,
        decrementLoading,
        enqueueSnackbar,
        () => {
          postNewBorrowing(
            token,
            body,
            decrementLoading,
            handleClose,
            handleRemoveMatos,
            rerenderParentCallback,
            enqueueSnackbar
          )
        },
        handleClose
      )
    }
  }

  const handleSubmitFullReturn = () => {
    incrementLoading()
    putEndBorrowing(
      bodyEndBorrowing,
      token,
      decrementLoading,
      enqueueSnackbar,
      rerenderParentCallback,
      handleClose
    )
  }

  const handleChangeSelectedMatos = (newSelection: MatosDetails[]) => {
    setFormStateEdition({
      ...formStateEdition,
      matos: newSelection,
    })
  }

  const handleChangeFormEdition = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setFormStateEdition({
      ...formStateEdition,
      [key]: event.target.value,
    })
  }

  React.useEffect(() => {
    const originalMatosIds = overrideMatosStatus(
      transformMatosDetailInBorrowingOrBooking_To_MatosDetails(
        originalBorrowingDetails
      ),
      MatosStatusEnum.COUV
    ).map((elt) => elt.id)
    const newMatosIds = formStateEdition.matos.map((elt) => elt.id)
    if (formStateEdition.borrower_start !== originalBorrowing.borrower_start) {
      setTypeOfUpdate(TypeOfUpdateEnum.NEW_BORROWING)
    } else if (
      JSON.stringify(newMatosIds) !== JSON.stringify(originalMatosIds)
    ) {
      setTypeOfUpdate(TypeOfUpdateEnum.NEW_BORROWING)
    } else {
      setTypeOfUpdate(TypeOfUpdateEnum.UPDATE)
    }
  }, [
    formStateEdition.borrower_start,
    formStateEdition.matos,
    originalBorrowing.borrower_start,
    originalBorrowingDetails,
  ])

  const errorForReturnExists = () => {
    const comment_end_error = bodyEndBorrowing.comment_end
      ? bodyEndBorrowing.comment_end.length > LIMIT_VARCHAR_COMMENT
      : false
    return (
      comment_end_error ||
      bodyEndBorrowing.borrower_log_end.length > LIMIT_VARCHAR ||
      bodyEndBorrowing.title.length > LIMIT_VARCHAR
    )
  }

  return (
    <Dialog open={openDetails} onClose={handleClose}>
      {!editionInterface && (
        <>
          <CustomDialogTitle
            title={originalBorrowing.title}
            handleClose={handleClose}
          />
          <DialogContent className={classes.dialogContent}>
            <DisplayBeginInfoBorrowing borrowing={originalBorrowing} />
            <Divider className={classes.divider} />
            <DisplayEndInfoBorrowing borrowing={originalBorrowing} />
            {isSorti && (
              <TextField
                autoComplete="off"
                className={classes.commentInput}
                margin="dense"
                id="commentEnd"
                label="Commentaire de fin"
                type="comment"
                variant="outlined"
                value={bodyEndBorrowing?.comment_end}
                error={
                  bodyEndBorrowing.comment_end
                    ? bodyEndBorrowing.comment_end.length >
                      LIMIT_VARCHAR_COMMENT
                    : false
                }
                helperText={errorForReturnExists() && 'Trop long'}
                onChange={(e) =>
                  setBodyEndBorrowing({
                    ...bodyEndBorrowing,
                    comment_end: e.target.value,
                  })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SmsOutlinedIcon className={classes.icon} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {originalBorrowingDetails && (
              <MatosItemsInCreatedBorrowAccordeon
                matosInBorrowing={originalBorrowingDetails}
              />
            )}
          </DialogContent>
        </>
      )}
      {editionInterface && (
        <>
          <CustomDialogTitle
            title={'Modifier : '.concat(originalBorrowing.title)}
            handleClose={handleClose}
          />
          <DialogContent>
            <TextfieldsForValidationCart
              handleChange={handleChangeFormEdition}
              isBorrowing={true}
              formState={formStateEdition}
              hideSomeLabels={{
                TITLE: false,
                FOR_WHO: false,
                START_DATE: true,
                END_DATE: false,
                COMMENT_START: false,
              }}
            />

            <QuickAddMatosSearchBar
              originalMatosBorrowed={transformMatosDetailInBorrowingOrBooking_To_MatosDetails(
                originalBorrowingDetails
              )}
              catalog={catalog}
              selectedMatos={formStateEdition.matos}
              setSelectedMatos={handleChangeSelectedMatos}
            />
          </DialogContent>
        </>
      )}

      {editionInterface && typeOfUpdate === TypeOfUpdateEnum.NEW_BORROWING && (
        <DialogContentText className={classes.contentText}>
          <MyCustomListItem
            icon={<InfoIcon />}
            text={'Cet emprunt va être rendu, un nouvel emprunt sera créé.'}
            dense
          />
        </DialogContentText>
      )}
      {isSorti && (
        <DialogActions>
          <MyButton
            title={editionInterface ? 'Annuler' : 'Modifier'}
            callback={() => {
              setEditionInterface(!editionInterface)
            }}
            secondary
          />
          <MyButton
            title={editionInterface ? 'Valider' : 'Rendre'}
            callback={() => {
              editionInterface
                ? handleEditionValidation()
                : handleSubmitFullReturn()
            }}
            isDisabled={
              editionInterface
                ? checkIfBorrowingFormHasError(formStateEdition)
                : errorForReturnExists()
            }
          />
        </DialogActions>
      )}
    </Dialog>
  )
}
