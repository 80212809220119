import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import 'firebase/analytics'
import firebase from 'firebase/app'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { LoadingContext } from '../../../contexts/loading/LoadingContext'
import {
  MatosDetails,
  NewBorrowingOrBookingForm,
  postNewBooking,
  postNewBorrowing,
} from '../../../pages/Catalogue/utils'
import { BookingStatus } from '../../../pages/History/utils'
import { getToken } from '../../../utils/Auth'
import { LIMIT_VARCHAR, LIMIT_VARCHAR_COMMENT } from '../../../utils/various'
import MyButton from '../../Buttons/MyButton'
import CustomDialogTitle from '../../Dialog/CustomDialogTitle'
import WarningDialog from '../../WarningDialog'
import MatosItemsInBorrowAccordeon from './MatosItemsInBorrowAccordeon'
import { TextfieldsForValidationCart } from './TextfieldsForValidationCart'

interface Props {
  openDialogValidationCart: boolean
  setOpenDialogValidationCart: Function
  selectedMatos: MatosDetails[]
  handleRemoveMatos: Function
  rerenderParentCallback: Function
  isBorrowing: boolean
  formState: NewBorrowingOrBookingForm
  setForm: (form: NewBorrowingOrBookingForm) => void
}

export const checkIfBorrowingFormHasError = (
  formState: NewBorrowingOrBookingForm
) => {
  var moment = require('moment')
  return (
    formState.matos.length === 0 ||
    formState.title.length === 0 ||
    formState.title.length > LIMIT_VARCHAR ||
    formState.borrower_start.length === 0 ||
    formState.borrower_start.length > LIMIT_VARCHAR ||
    formState.borrower_log_start.length > LIMIT_VARCHAR ||
    formState.comment_start.length > LIMIT_VARCHAR_COMMENT ||
    !moment.isDate(new Date(formState.startdate)) ||
    !moment.isDate(new Date(formState.enddate)) ||
    !formState.startdate ||
    !formState.enddate ||
    moment(new Date(formState.enddate)).isBefore(new Date(formState.startdate))
  )
}
export default function DialogValidationCart({
  openDialogValidationCart,
  setOpenDialogValidationCart,
  selectedMatos,
  handleRemoveMatos,
  isBorrowing,
  formState,
  setForm,
}: Props) {
  const analytics = firebase.analytics()
  const { incrementLoading, decrementLoading } = useContext(LoadingContext)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const location = useLocation()
  const [formHasErrors, setFormHasErros] = React.useState<boolean>(true)
  const [openWarningDialog, setOpenWarningDialog] = React.useState<boolean>(
    false
  )
  React.useEffect(() => {
    setFormHasErros(checkIfBorrowingFormHasError(formState))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  React.useEffect(() => {
    const p = location.state as
      | { start: Date; end: Date; title: string }
      | undefined
    p &&
      setForm({
        ...formState,
        startdate: p.start,
        enddate: p.end,
        title: p.title,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  const handleChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setForm({ ...formState, [key]: event.target.value })
  }

  const handleSubmit = (ev: React.FormEvent) => {
    if (formState.matos.length !== 0) {
      if (isBorrowing) {
        incrementLoading()
        postNewBorrowing(
          getToken(),
          formState,
          decrementLoading,
          setOpenDialogValidationCart,
          handleRemoveMatos,
          () => history.push('/'),
          enqueueSnackbar
        )
        analytics.logEvent('dialogValidationCart-create_borrowing')
      } else {
        setOpenWarningDialog(true)
      }
    } else {
      enqueueSnackbar("Impossible d'emprunter sans matos", {
        variant: 'error',
      })
    }
  }

  const handleConfirmationBooking = () => {
    setOpenWarningDialog(false)
    formState.status = BookingStatus.AVENIR
    postNewBooking(
      getToken(),
      formState,
      decrementLoading,
      setOpenDialogValidationCart,
      handleRemoveMatos,
      () => history.push('/'),
      enqueueSnackbar
    )
    analytics.logEvent('dialogValidationCart-create_booking')
  }

  return (
    <>
      <WarningDialog
        title={'Valider la réservation ?'}
        textContent={
          "Réserver informera directement les gens qui veulent emprunter ce matos qu'il a été réservé. Mais il sera quand même possible de l'emprunter. Pour valider ta réservation, il faudra la transformer en emprunt au moment où tu le souhaites depuis l'onglet emprunts/réservations"
        }
        openWarningDialog={openWarningDialog}
        setOpenWarningDialog={setOpenWarningDialog}
        callbackIfConfirmed={() => {
          handleConfirmationBooking()
        }}
        callbackIfCanceled={() => {
          decrementLoading()
          setOpenWarningDialog(false)
          setOpenDialogValidationCart(false)
        }}
        textButtonConfirm={'Réserver'}
        textButtonCancel={'Annuler'}
      />
      <form onSubmit={handleSubmit}>
        <Dialog
          open={openDialogValidationCart}
          onClose={() => setOpenDialogValidationCart(false)}
          aria-labelledby="form-dialog-title"
        >
          <CustomDialogTitle
            title={isBorrowing ? 'Emprunter' : 'Réserver'}
            handleClose={() => setOpenDialogValidationCart(false)}
          />
          <DialogContent>
            <TextfieldsForValidationCart
              formState={formState}
              handleChange={handleChange}
              isBorrowing={isBorrowing}
            />
          </DialogContent>
          <DialogContent>
            <MatosItemsInBorrowAccordeon
              title={'Matos'}
              selectedMatos={selectedMatos}
              handleRemoveMatos={handleRemoveMatos}
            />
          </DialogContent>
          <DialogActions>
            <MyButton
              title={isBorrowing ? 'Emprunter' : 'Réserver'}
              callback={handleSubmit}
              isDisabled={formHasErrors}
            />
          </DialogActions>
        </Dialog>
      </form>
    </>
  )
}
