import { useMemo } from 'react'
import Chart from 'react-apexcharts'
import { SeriesGraph } from '../../pages/Metrics/utils'
import { getDarkModeLocalStorage } from '../../utils/Auth'

interface Props {
  series: SeriesGraph[]
}
export const AreaGraphMatosBorrowed = ({ series }: Props) => {
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  const isDarkMode = useMemo(() => getDarkModeLocalStorage(), [])
  return (
    <Chart
      options={{
        theme: {
          mode: isDarkMode ? 'dark' : 'light',
        },
        chart: {
          id: 'area-datetime',
          type: 'area',
          dropShadow: {
            enabled: true,
            top: 5,
            left: 5,
            blur: 10,
            opacity: 0.2,
          },
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },

        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 6,
        },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              },
            },
          },
        ],
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
        tooltip: {
          enabled: true,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: false,
          style: {
            fontSize: '12px',
            fontFamily: undefined,
          },
          onDatasetHover: {
            highlightDataSeries: true,
          },
          x: {
            show: true,
            format: 'dd MMM',
            formatter: function (value) {
              return moment(value).format('Do MMM')
            },
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
          z: {
            formatter: undefined,
            title: 'Size: ',
          },
          marker: {
            show: true,
          },
          fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        },
      }}
      series={series}
      type="area"
      height={350}
    />
  )
}
