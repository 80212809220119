import {
  Card,
  CardActionArea,
  CardHeader,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import classNames from 'classnames'
import 'firebase/analytics'
import firebase from 'firebase/app'
import React, { useMemo } from 'react'
import {
  DisplayNameCalendars,
  EventFromGoogleCalendar,
  isEventOK,
} from '../../pages/Calendar/utils'
import MyCustomListItem from '../MyCustomListItem'
import DialogDetailsEvent from './DialogDetailsEvent'

interface Props {
  event: EventFromGoogleCalendar
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 5,
    width: 300,
    height: 115,
    marginBottom: 10,
    marginLeft: 5,
    marginRight: 5,
    boxShadow: `0 8px 40px -12px ${theme.palette.boxShadow.primary}`,
    '&:hover': {
      boxShadow: `0 16px 70px -12.125px ${theme.palette.boxShadow.primary}`,
    },
    textAlign: 'center',
  },
  isNowRoot: { borderStyle: 'solid' },
  cardHeader: {
    marginBottom: -10,
    marginTop: -20,
    color: theme.palette.customText.primary,
    display: 'block',
    overflow: 'hidden',
  },
  isNowTypo: { color: theme.palette.customText.main },
  Pics_Couv: {
    backgroundColor: theme.palette.events.PICS_COUV,
  },
  Pics_Presta: { backgroundColor: theme.palette.events.PICS_PRESTA },
  Pics_Studio: { backgroundColor: theme.palette.events.PICS_STUDIO },
  Pics_Interne: { backgroundColor: theme.palette.events.default },
}))
export default function IndividualEvent({ event }: Props) {
  const analytics = firebase.analytics()
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  const classes = useStyles()

  const isNow: boolean = useMemo(() => {
    return (
      moment().isAfter(event.start.dateTime) &&
      moment().isBefore(event.end.dateTime)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.end.dateTime, event.start.dateTime])

  const summaryOK: { boolean: boolean; newValue: string } = useMemo(() => {
    return isEventOK(event.summary)
  }, [event.summary])

  const calendarName: DisplayNameCalendars = useMemo(() => {
    return event.organizer.displayName
      ? event.organizer.displayName
      : DisplayNameCalendars.PICS_INTERNE
  }, [event])

  const dateOrDateRange = useMemo(() => {
    if (moment(event.start.dateTime).isSame(moment().format(), 'day')) {
      return <b>Aujourd'hui</b>
    } else if (moment(event.end.dateTime).isSame(event.start.dateTime, 'day')) {
      return moment(event.start.dateTime).format('ddd Do MMM')
    } else {
      return `${moment(event.start.dateTime).format('ddd Do MMM')} ➝ ${moment(
        event.end.dateTime
      ).format('ddd Do MMM')}`
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event.end.dateTime, event.start.dateTime])

  const eventOrganiser = useMemo(() => {
    return event.organizer.displayName
      ? event.organizer.displayName.replace('Pics_', '')
      : DisplayNameCalendars.PICS_INTERNE.replace('Pics_', '')
  }, [event.organizer.displayName])

  const [
    openDetailsEventDialog,
    setOpenDetailsEventDialog,
  ] = React.useState<boolean>(false)

  const handleClickOpenDetailsEventDialog = () => {
    analytics.logEvent('eventsCard-open_eventsDialog')
    setOpenDetailsEventDialog(!openDetailsEventDialog)
  }

  return (
    <div id={`event-${event.summary}`}>
      <Card
        className={classNames(
          classes.root,
          classes[calendarName],
          isNow && classes.isNowRoot
        )}
        onClick={handleClickOpenDetailsEventDialog}
      >
        <CardActionArea>
          <CardHeader
            title={
              <Typography noWrap variant="h6" component="h4">
                {summaryOK.boolean ? event.summary : summaryOK.newValue}
              </Typography>
            }
            subheader={<Typography noWrap>{dateOrDateRange}</Typography>}
            className={classNames(
              classes.cardHeader,
              isNow && classes.isNowTypo
            )}
          />
          <Grid container direction="column">
            <Grid container>
              <Grid item xs={6}>
                <MyCustomListItem
                  dense
                  text={moment(event.start.dateTime).format('LT')}
                  icon={<WatchLaterIcon />}
                  accentuatedColor={isNow}
                />

                <MyCustomListItem
                  dense
                  text={eventOrganiser}
                  icon={<EventIcon />}
                  accentuatedColor={isNow}
                />
              </Grid>
              <Grid item xs={6}>
                <MyCustomListItem
                  dense
                  text={moment(event.end.dateTime).format('LT')}
                  icon={<QueryBuilderIcon />}
                  accentuatedColor={isNow}
                />

                {event.location && (
                  <MyCustomListItem
                    dense
                    text={event.location}
                    icon={<LocationOnIcon />}
                    accentuatedColor={isNow}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
      {openDetailsEventDialog && (
        <DialogDetailsEvent
          event={event}
          openDetailsEventDialog={openDetailsEventDialog}
          setOpenDetailsEventDialog={setOpenDetailsEventDialog}
          isNow={isNow}
        />
      )}
    </div>
  )
}
