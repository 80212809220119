import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SvgIconProps,
  Theme,
} from '@material-ui/core'
import classNames from 'classnames'

interface Props {
  icon: React.ReactElement<SvgIconProps>
  text: string | JSX.Element
  title?: string
  accentuatedColor?: boolean
  accentuatedColorError?: boolean
  customClassText?: string
  customClassAccentuated?: string
  dense?: boolean
}
const useDenseClassesStyles = makeStyles((theme: Theme) => ({
  listItem: { marginTop: -15, justifyContent: 'center' },
  listItemText: {
    marginLeft: -theme.spacing(3),
    marginTop: theme.spacing(0.5),
    color: theme.palette.customText.secondary,
    overflowWrap: 'break-word',
  },
}))

const useNoDenseClassesStyles = makeStyles((theme: Theme) => ({
  listItem: { marginTop: -5, justifyContent: 'center' },
  listItemText: {
    marginLeft: -theme.spacing(2),
    marginTop: theme.spacing(1),
    color: theme.palette.customText.secondary,
  },
}))
const useClasses = makeStyles((theme: Theme) => ({
  icon: { color: theme.palette.customText.secondary },
  accentuatedColor: { color: theme.palette.customText.main },
  accentuatedColorError: { color: theme.palette.error.main },
}))

const MyCustomListItem = ({
  icon,
  title,
  text,
  customClassText,
  accentuatedColor,
  accentuatedColorError,
  customClassAccentuated,
  dense,
}: Props): JSX.Element => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = dense ? useDenseClassesStyles() : useNoDenseClassesStyles()
  const commonClasses = useClasses()
  return (
    <ListItem className={classes.listItem}>
      <ListItemIcon
        className={classNames(
          commonClasses.icon,
          accentuatedColor && commonClasses.accentuatedColor,
          accentuatedColorError && commonClasses.accentuatedColorError,
          customClassAccentuated && customClassAccentuated
        )}
      >
        {icon}
      </ListItemIcon>
      <ListItemText
        className={classNames(
          classes.listItemText,
          customClassText && customClassText,
          accentuatedColor && commonClasses.accentuatedColor,
          customClassAccentuated && customClassAccentuated,
          dense && accentuatedColorError && commonClasses.accentuatedColorError
        )}
        primaryTypographyProps={{
          style: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            justifyContent: 'center',
            fontSize: dense ? 14 : 17,
          },
        }}
        primary={title ? title : text}
        secondaryTypographyProps={{
          style: {
            justifyContent: 'center',
            fontSize: dense ? 12 : 15,
          },
        }}
        secondary={title ? text : null}
      />
    </ListItem>
  )
}
export default MyCustomListItem
