import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { FormControl, Select, MenuItem } from '@material-ui/core'
import { SchoolYearDateRange } from '../../pages/History/utils'

const useStyles = makeStyles((theme) => ({
  typography: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}))
interface Props {
  anchorPopoverDate: HTMLButtonElement | null
  setAnchorPopoverDate: (value: any) => void
  allPossibleSchoolYears: SchoolYearDateRange[]
  selectedSchoolYearDateRange: SchoolYearDateRange
  handleChangeSchoolYear: (value: SchoolYearDateRange) => void
}
export default function SchoolYearSelectPopover({
  anchorPopoverDate,
  setAnchorPopoverDate,
  allPossibleSchoolYears,
  selectedSchoolYearDateRange,
  handleChangeSchoolYear,
}: Props) {
  var moment = require('moment')
  const classes = useStyles()
  const handleClosePopover = () => {
    setAnchorPopoverDate(null)
  }
  const [openSelect, setOpenSelect] = React.useState(false)
  const open = Boolean(anchorPopoverDate)
  const id = open ? 'simple-popover' : undefined
  const handleCloseSelect = () => {
    setOpenSelect(false)
  }
  const handleOpenSelect = () => {
    setOpenSelect(true)
  }
  const handleLocalChangeYear = (event) => {
    handleChangeSchoolYear({
      start: event.target.value,
      end: moment(event.target.value).add(1, 'years').format('yyyy-MM-DD'),
    })
    handleClosePopover()
  }

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorPopoverDate}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>Année scolaire</Typography>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            open={openSelect}
            onClose={handleCloseSelect}
            onOpen={handleOpenSelect}
            value={selectedSchoolYearDateRange.start}
            onChange={handleLocalChangeYear}
          >
            {allPossibleSchoolYears.map((schoolYear) => {
              return (
                <MenuItem value={schoolYear.start.toString()}>
                  {moment(schoolYear.start).format('YYYY').toString()}-
                  {moment(schoolYear.end).format('YYYY').toString()}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Popover>
    </div>
  )
}
