import camera_loading from '../../assets/camera_loading.gif'
import { showTitleAccordingToEnv } from '../../utils/Version'
import './AccessDenied.css'

export default function AccessDenied() {
  document.title = showTitleAccordingToEnv('OUPS')
  return (
    <div>
      <header className="App-header">
        <img src={camera_loading} className="Camera-loading" alt="logo" />
        <p>Tu n'as pas les autorisations pour accéder à cette page</p>
      </header>
    </div>
  )
}
