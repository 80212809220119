import {
  createStyles,
  Divider,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Theme,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useSnackbar } from 'notistack'
import React, { useContext, useMemo, useState } from 'react'
import PACKAGE from '../../../package.json'
import logo from '../../assets/camera_white.png'
import MyButton from '../../components/Buttons/MyButton'
import { LoadingContext } from '../../contexts/loading/LoadingContext'
import {
  clearAllAuthAsync,
  setAdmin,
  setGivenName,
  setToken,
} from '../../utils/Auth'
import { upperCaseFirst } from '../../utils/various'
import { showTitleAccordingToEnv } from '../../utils/Version'
import './Login.css'
import {
  CredentialsLogin,
  getPromoFromVRLogin,
  isAVRUsername,
  postLogin,
  ResLogin,
} from './utils'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputPassword: {
      width: '300px',
      margin: '10px',
      color: 'white',
    },
    inputUsername: {
      width: '300px',
      margin: '10px',
      marginTop: '20px',
      color: 'white',
    },

    paper: {
      width: '350px',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.default,
    },
  })
)

export default function Login() {
  React.useEffect(() => {
    document.title = showTitleAccordingToEnv('Login')
  }, [])
  const defaultCredentials = {
    username: '',
    password: '',
  }
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [credentials, setCredentials] = useState<CredentialsLogin>(
    defaultCredentials
  )
  const { incrementLoading, decrementLoading } = useContext(LoadingContext)

  const handleSubmit = async () => {
    if (credentials.username && credentials.password) {
      incrementLoading()
      postLogin(
        {
          username: credentials.username.concat(' null'),
          promo: getPromoFromVRLogin(credentials.username),
          password: credentials.password,
        },
        enqueueSnackbar,
        decrementLoading
      ).then((res: ResLogin) => {
        if (res?.success === true) {
          const givenName = res.givenName.replace('["', '').replace('"]', '')
          setAdmin(res.groups)
          setGivenName(givenName)
          setToken('Bearer '.concat(res.token))
          enqueueSnackbar(
            'Connexion réussie '.concat(givenName.split(' ')[0]),
            {
              variant: 'success',
            }
          )
        } else {
          clearAllAuthAsync()
          decrementLoading()
        }
      })
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }
  const handleChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setCredentials({ ...credentials, [key]: event.target.value })
  }
  React.useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault()
        credentials !== defaultCredentials && handleSubmit()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials])

  const isUsernameInError = useMemo(() => {
    /* Error if the user has tried to set both the username and the password but the username is not a valid one */
    return (
      credentials.password.length > 1 && !isAVRUsername(credentials.username)
    )
  }, [credentials.password, credentials.username])

  const isSubmitionDisabled = useMemo(() => {
    return credentials.password.length === 0 || isUsernameInError
  }, [credentials.password.length, isUsernameInError])

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Connexion</p>
        <Paper className={classes.paper} variant="elevation">
          <TextField
            autoComplete="off"
            error={isUsernameInError}
            autoFocus
            required
            id="username"
            variant="outlined"
            label="Login VR"
            value={credentials.username}
            onChange={(e) => handleChange('username', e)}
            className={classes.inputUsername}
            helperText={isUsernameInError && 'Du type 2001FLANTIERN'}
          />

          <TextField
            autoComplete="off"
            type={showPassword ? 'text' : 'password'}
            required
            id="password"
            variant="outlined"
            label="Mot de passe LDAP"
            value={credentials.password}
            onChange={(e) => handleChange('password', e)}
            className={classes.inputPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <MyButton
            title={'Se connecter'}
            callback={handleSubmit}
            isDisabled={isSubmitionDisabled}
          />

          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          <MyButton
            title={'Modifier mon compte'}
            callback={() =>
              (window.location.href = 'https://account.galerie.pics/modify/')
            }
            secondary
            shouldNeverBeDisabled={true}
          />
        </Paper>
        <p style={{ fontSize: 10 }}>
          {upperCaseFirst(
            process.env.REACT_APP_NODE_STAGING_ENV || process.env.NODE_ENV
          ).concat(' ', PACKAGE.version)}
        </p>
      </header>
    </div>
  )
}
