import {
  AppBar,
  Fab,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import TablePagination from '@material-ui/core/TablePagination'
import SettingsIcon from '@material-ui/icons/Settings'
import clsx from 'clsx'
import 'firebase/analytics'
import firebase from 'firebase/app'
import { useSnackbar } from 'notistack'
import React, { useContext, useMemo } from 'react'
import CalendarChoicesDialog from '../../components/Calendar/CalendarChoicesDialog'
import IndividualEvent from '../../components/Calendar/IndividualEvent'
import { MyDateRangePicker } from '../../components/Pickers/MyDateRangePicker'
import { LoadingContext } from '../../contexts/loading/LoadingContext'
import { getToken } from '../../utils/Auth'
import { appBarHeight, useFabStyles } from '../../utils/various'
import { showTitleAccordingToEnv } from '../../utils/Version'
import {
  CalendarChoices,
  EventFromGoogleCalendar,
  filterEventsCalendar,
  getAllCalendars,
  getAPIKey,
} from './utils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignContent: 'center',
    alignItems: 'center',
    justify: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  mainGrid: {
    alignContent: 'center',
    alignItems: 'center',
    justify: 'center',
    justifyContent: 'center',
    paddingTop: '130px',
    backgroundColor: theme.palette.background.paper,
  },
  nestedAppBar: {
    marginTop: appBarHeight,
    alignItems: 'center',
    height: appBarHeight,
    backgroundColor: theme.palette.background.default,
  },
}))
export default function Calendar() {
  React.useEffect(() => {
    document.title = showTitleAccordingToEnv('Planning')
  }, [])

  const analytics = firebase.analytics()
  const fabClasses = useFabStyles()
  const classes = useStyles()
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  const { incrementLoading, decrementLoading } = useContext(LoadingContext)
  const { enqueueSnackbar } = useSnackbar()
  const [
    openCalendarChoicesDialog,
    setOpenCalendarChoicesDialog,
  ] = React.useState<boolean>(false)
  const [page, setPage] = React.useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(8)
  const [couv, setCouv] = React.useState<EventFromGoogleCalendar[]>([])
  const [studio, setStudio] = React.useState<EventFromGoogleCalendar[]>([])
  const [presta, setPresta] = React.useState<EventFromGoogleCalendar[]>([])
  const [interne, setInterne] = React.useState<EventFromGoogleCalendar[]>([])
  const [calendarChoices, setCalendarChoices] = React.useState<CalendarChoices>(
    {
      Pics_Interne: true,
      Pics_Couv: true,
      Pics_Studio: true,
      Pics_Presta: true,
    }
  )
  const [dateRange, setDateRange] = React.useState<[Date, Date]>([
    moment().format('yyyy-MM-DD'),
    moment().add(1, 'months').format('yyyy-MM-DD'),
  ])
  const [googleAPISecretKey, setGoogleAPISecretKey] = React.useState<string>('')

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const sliceForPagination = () => {
    return { start: rowsPerPage * page, end: rowsPerPage * (page + 1) + 1 }
  }
  const handleClickCalendarSettings = () => {
    analytics.logEvent('calendar-open_choices')
    setOpenCalendarChoicesDialog(true)
  }

  React.useEffect(() => {
    setCouv([])
    getAPIKey(
      enqueueSnackbar,
      incrementLoading,
      decrementLoading,
      getToken(),
      setGoogleAPISecretKey
    )
    googleAPISecretKey &&
      getAllCalendars(
        incrementLoading,
        decrementLoading,
        enqueueSnackbar,
        googleAPISecretKey,
        setCouv,
        setPresta,
        setStudio,
        setInterne
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleAPISecretKey])

  const eventToShow = useMemo(() => {
    return couv
      .concat(presta, interne, studio)
      ?.filter((elt) => filterEventsCalendar(calendarChoices, elt))
      .filter(
        (elt) =>
          /* TODO Faudrait plutôt filtrer sur la query, pas en front  */
          moment(dateRange[0]).isBefore(elt.start.dateTime) &&
          moment(dateRange[1]).isAfter(elt.end.dateTime)
      )
      .filter((elt) => elt.start.dateTime)
    /* EntireDay events not working otherwise */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarChoices, couv, dateRange, interne, presta, studio])

  const onDateRangeChange = (range: [Date, Date]): void => {
    if (range[0] && range[1]) {
      setDateRange(range)
    }
  }
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.nestedAppBar}>
        <MyDateRangePicker
          dateRange={dateRange}
          disableFuture={false}
          handleDateRangeChange={onDateRangeChange}
        />
      </AppBar>
      <Grid container className={classes.mainGrid}>
        {eventToShow
          .sort(function (a, b) {
            return (
              moment(a.start.dateTime).unix() - moment(b.start.dateTime).unix()
            )
          })
          .slice(sliceForPagination().start, sliceForPagination().end)
          .map((e) => {
            return <IndividualEvent event={e} />
          })}
      </Grid>
      {eventToShow.length === 0 && (
        <Typography variant={'h5'} align="center" color="textPrimary">
          Aucun évènement sur cette période
        </Typography>
      )}

      {eventToShow.length > rowsPerPage && (
        <TablePagination
          ///TODO PERFS
          component={'div'}
          count={eventToShow.length}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} sur ${count}`
          }
          labelRowsPerPage={''}
        />
      )}
      <Fab
        variant="extended"
        color="secondary"
        aria-label="settings"
        className={clsx(
          fabClasses.common,
          fabClasses.firstTop,
          fabClasses.rounded
        )}
        onClick={handleClickCalendarSettings}
      >
        <SettingsIcon color={'primary'} />
      </Fab>
      <CalendarChoicesDialog
        openCalendarChoicesDialog={openCalendarChoicesDialog}
        setOpenCalendarChoicesDialog={setOpenCalendarChoicesDialog}
        calendarChoices={calendarChoices}
        setCalendarChoices={setCalendarChoices}
      />
    </div>
  )
}
