import { snackBarErrorOrFailToFetch } from '../../utils/SnackBarGenericError'
import { NewBorrowingOrBookingForm } from '../Catalogue/utils'
export enum BorrowingStatus {
  SORTI = 'Sorti',
  TERMINE = 'Termine',
}
export enum BookingStatus {
  CANCELED = 'Canceled',
  AVENIR = 'A venir',
  TRANSFORMED = 'Transformed',
}
export interface Borrowing {
  id: number
  title: string
  startdate_log: Date
  startdate: Date
  enddate: Date
  borrower_log_start: string
  borrower_log_end: string | null
  borrower_start: string
  status: BorrowingStatus
  comment_start: string
  comment_end: string | null
}
export interface Booking {
  id: number
  title: string
  startdate_log: string
  startdate: string
  enddate: string
  borrower_log_start: string
  borrower_log_end: string | null
  borrower_start: string
  status: BookingStatus
  comment_start: string
  comment_end: string | null
}

export interface EndBorrowingForm {
  title: string
  id: number
  enddate: Date
  borrower_log_end: string
  comment_end: string | null
}
export interface SchoolYearDateRange {
  start: Date
  end: Date
}
export interface DataHistory {
  bookings: Booking[]
  borrowingsSorti: Borrowing[]
  borrowingsTermine: Borrowing[]
}
export function getAllBorrowings(
  token: string,
  enqueueSnackbar: Function,
  decrementLoading: Function
) {
  const url = process.env.REACT_APP_URL_BACK + 'borrowings/all/get'
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getAllBorrowings',
        decrementLoading
      )
    })
}
export function getBorrowingsBetweenDateRange(
  token: string,
  enqueueSnackbar: Function,
  decrementLoading: Function,
  dateRange: SchoolYearDateRange
) {
  const url =
    process.env.REACT_APP_URL_BACK +
    'borrowings/date_range/get?start=' +
    dateRange.start +
    '&end=' +
    dateRange.end
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getBorrowingsBetweenDateRange',
        decrementLoading
      )
    })
}

export function getNextBookings(
  token: string,
  enqueueSnackbar: Function,
  decrementLoading: Function
) {
  const url = process.env.REACT_APP_URL_BACK + 'bookings/a_venir/get'
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getNextBookings',
        decrementLoading
      )
    })
}

export function getBorrowingsLive(
  token: string,
  enqueueSnackbar: Function,
  decrementLoading: Function
) {
  const url = process.env.REACT_APP_URL_BACK + 'borrowings/live/get'
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getBorrowingsLive',
        decrementLoading
      )
    })
}

export function getBorrowingsOrBookingsDetails(
  token: string,
  id: number,
  enqueueSnackbar: Function,
  decrementLoading: Function,
  isBorrowing: boolean
) {
  const query = isBorrowing
    ? 'borrowings/allMatos/get?id='
    : 'bookings/allMatos/get?id='
  const url = process.env.REACT_APP_URL_BACK + query + id.toString()
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getBorrowingsOrBookingsDetails',
        decrementLoading
      )
    })
}
export function putEndBorrowing(
  bodyToSent: EndBorrowingForm,
  token: string,
  decrementLoading: Function,
  enqueueSnackbar: Function,
  rerenderParentCallback: any,
  handleClose: any
) {
  let newBody = {
    comment_end: bodyToSent.comment_end
      ? bodyToSent.comment_end.replace("'", "\\'")
      : '',
    borrower_log_end: bodyToSent.borrower_log_end.replace("'", "\\'"),
    title: bodyToSent.title.replace("'", "\\'"),
    id: bodyToSent.id,
    enddate: bodyToSent.enddate,
  }
  return fetch(process.env.REACT_APP_URL_BACK + 'borrowings/end/put', {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(newBody),
  })
    .then((res) => {
      res.json()
      decrementLoading()
      enqueueSnackbar('Matos rendu !', {
        variant: 'success',
      })
      rerenderParentCallback()
      handleClose()
    })
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'putEndBorrowing',
        decrementLoading
      )
    })
}

export function getAllPossibleSchoolYears() {
  var moment = require('moment')
  const now = new Date()
  let firstBorrowingsInApp = new Date(2017, 7, 20) ///En réalité, 1 an après mais boucle while oblige
  let ranges: SchoolYearDateRange[] = []
  while (moment(firstBorrowingsInApp).isBefore(now, 'years')) {
    firstBorrowingsInApp = moment(firstBorrowingsInApp).add(1, 'years')

    if (moment(firstBorrowingsInApp).year() !== moment(now).year()) {
      ranges.push({
        start: moment(firstBorrowingsInApp).format('yyyy-MM-DD'),
        end: moment(firstBorrowingsInApp).add(1, 'years').format('yyyy-MM-DD'),
      })
    } else {
      /* If this year, needs to be before August to be pushed as a new scholar year*/
      if (moment(now).month() > moment(firstBorrowingsInApp).month()) {
        ranges.push({
          start: moment(firstBorrowingsInApp).format('yyyy-MM-DD'),
          end: moment(firstBorrowingsInApp)
            .add(1, 'years')
            .format('yyyy-MM-DD'),
        })
      }
    }
  }

  return ranges
}

export function putChangeBorrowing(
  bodyToSent: NewBorrowingOrBookingForm,
  token: string,
  decrementLoading: Function,
  enqueueSnackbar: Function,
  rerenderParentCallback: any,
  handleClose: any
) {
  return fetch(process.env.REACT_APP_URL_BACK + 'borrowings/update/put', {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(bodyToSent),
  })
    .then((res) => {
      res.json()
      decrementLoading()
      enqueueSnackbar('Emprunt modifié', {
        variant: 'success',
      })
      rerenderParentCallback()
      handleClose()
    })
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'putChangeBorrowing',
        decrementLoading
      )
    })
}
