import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MyButton from './Buttons/MyButton'

interface Props {
  title: string
  textContent: string
  openWarningDialog: boolean
  setOpenWarningDialog: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void
  callbackIfConfirmed: Function
  callbackIfCanceled?: Function
  textButtonConfirm: string
  textButtonCancel: string
}

export default function WarningDialog({
  title,
  textContent,
  openWarningDialog,
  setOpenWarningDialog,
  callbackIfConfirmed,
  callbackIfCanceled,
  textButtonConfirm,
  textButtonCancel,
}: Props) {
  const handleClose = () => {
    setOpenWarningDialog(false)
    callbackIfCanceled ? callbackIfCanceled() : setOpenWarningDialog(false)
  }
  return (
    <div>
      <Dialog
        open={openWarningDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {textContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MyButton
            title={textButtonCancel}
            callback={() => {
              callbackIfCanceled
                ? callbackIfCanceled()
                : setOpenWarningDialog(false)
            }}
            secondary
          />
          <MyButton title={textButtonConfirm} callback={callbackIfConfirmed} />
        </DialogActions>
      </Dialog>
    </div>
  )
}
