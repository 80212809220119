import React, { useContext } from 'react'

/*TODOBETTER https://fr.reactjs.org/docs/context.html#updating-context-from-a-nested-component */
const ThemeSwitcherContext = React.createContext<(() => void) | undefined>(
  undefined
)

export const useThemeSwitcher = () => {
  return useContext(ThemeSwitcherContext)
}

export default ThemeSwitcherContext
