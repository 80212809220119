import {
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core'
import {
  CalendarChoices,
  DisplayNameCalendars,
} from '../../pages/Calendar/utils'
import CustomDialogTitle from '../Dialog/CustomDialogTitle'

interface Props {
  openCalendarChoicesDialog: boolean
  setOpenCalendarChoicesDialog: Function
  calendarChoices: CalendarChoices
  setCalendarChoices
}
const useStyles = makeStyles((theme: Theme) => ({
  checkbox_Pics_Couv: {
    color: theme.palette.events.PICS_COUV,
  },
  checkbox_Pics_Presta: { color: theme.palette.events.PICS_PRESTA },
  checkbox_Pics_Studio: { color: theme.palette.events.PICS_STUDIO },
  checkbox_Pics_Interne: { color: theme.palette.events.default },
}))

export default function CalendarChoicesDialog({
  openCalendarChoicesDialog,
  setOpenCalendarChoicesDialog,
  calendarChoices,
  setCalendarChoices,
}: Props) {
  const handleClose = () => {
    setOpenCalendarChoicesDialog(false)
  }
  const classes = useStyles()
  interface checkboxesProps {
    text: string
    type: DisplayNameCalendars
  }
  const DisplayCheckboxes = ({ text, type }: checkboxesProps): JSX.Element => {
    const newChoices = { ...calendarChoices }
    newChoices[type] = !newChoices[type]
    return (
      <span
        onClick={() => {
          setCalendarChoices(newChoices)
        }}
        style={{ cursor: 'pointer' }}
      >
        <Grid item>
          <Checkbox
            checked={calendarChoices[type]}
            className={classes[`checkbox_${type}`]}
            color="default"
          />
          {text}
        </Grid>
      </span>
    )
  }
  return (
    <form>
      <Dialog open={openCalendarChoicesDialog} onClose={handleClose}>
        <CustomDialogTitle title={'Filtrer'} handleClose={handleClose} />
        <DialogContent>
          <Grid container direction={'column'}>
            <DisplayCheckboxes
              text={'Couverture'}
              type={DisplayNameCalendars.PICS_COUV}
            />
            <DisplayCheckboxes
              text={'Presta'}
              type={DisplayNameCalendars.PICS_PRESTA}
            />
            <DisplayCheckboxes
              text={'Studio'}
              type={DisplayNameCalendars.PICS_STUDIO}
            />
            <DisplayCheckboxes
              text={'Interne'}
              type={DisplayNameCalendars.PICS_INTERNE}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </form>
  )
}
