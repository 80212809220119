import {
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import { LoadingContext } from '../../contexts/loading/LoadingContext'
import { putUpdateMultipleMatosStatusAdmin } from '../../pages/Admin/utils'
import {
  itemMatosStatusMenu,
  MatosDetails,
  MatosStatusEnum,
} from '../../pages/Catalogue/utils'
import { getToken } from '../../utils/Auth'
import MyButton from '../Buttons/MyButton'
import CustomDialogTitle from '../Dialog/CustomDialogTitle'
import ChipMatosStatus from '../Matos/ChipMatosStatus'
const useStyles = makeStyles((theme) => ({
  textField: { marginTop: '20px' },
}))

interface Props {
  matos: MatosDetails[]
  handleClose: () => void
  openDialogChangeMultipleStatus: boolean
  rerenderParentCallback: Function
}

export default function ChangeMultipleStatus({
  matos,
  handleClose,
  openDialogChangeMultipleStatus,
  rerenderParentCallback,
}: Props) {
  const classes = useStyles()
  const [newStatus, setNewStatus] = React.useState<MatosStatusEnum>(
    MatosStatusEnum.LOCAL
  )
  const { incrementLoading, decrementLoading } = useContext(LoadingContext)
  const { enqueueSnackbar } = useSnackbar()

  async function handleSubmit(event: React.FormEvent) {
    incrementLoading()
    await putUpdateMultipleMatosStatusAdmin(
      matos,
      newStatus,
      getToken(),
      decrementLoading,
      enqueueSnackbar,
      rerenderParentCallback,
      handleClose
    )
  }

  return (
    <Dialog
      open={openDialogChangeMultipleStatus}
      onClose={() => {
        handleClose()
      }}
    >
      <CustomDialogTitle
        title={'Changer tous les status'}
        handleClose={handleClose}
      />
      <DialogContent>
        <Typography variant={'caption'}>
          Tout le matos sélectionné aura ce nouveau statut
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            autoComplete="off"
            select
            className={classes.textField}
            margin="dense"
            id="pics"
            label="Statut"
            type="comment"
            fullWidth
            variant="outlined"
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value as MatosStatusEnum)}
          >
            {itemMatosStatusMenu.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={option.value === MatosStatusEnum.COUV}
              >
                <ChipMatosStatus status={option.value} />
              </MenuItem>
            ))}
          </TextField>
        </form>
      </DialogContent>
      <DialogActions>
        <MyButton
          title={'Modifier'}
          callback={handleSubmit}
          isDisabled={false}
        />
      </DialogActions>
    </Dialog>
  )
}
