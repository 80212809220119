import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  makeStyles,
  Theme,
} from '@material-ui/core'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import EventIcon from '@material-ui/icons/Event'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import GroupIcon from '@material-ui/icons/Group'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PhoneIcon from '@material-ui/icons/Phone'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import SubjectIcon from '@material-ui/icons/Subject'
import WarningIcon from '@material-ui/icons/Warning'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import 'firebase/analytics'
import firebase from 'firebase/app'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  defaultTransformedDescriptionForEvents,
  DisplayNameCalendars,
  EventFromGoogleCalendar,
  isEventOK,
  isPhoneNumber,
  returnStrictTextBetweenStringsForDescription,
  TransformedDescriptionForEvents,
} from '../../pages/Calendar/utils'
import MyButton from '../Buttons/MyButton'
import CustomDialogTitle from '../Dialog/CustomDialogTitle'
import MyCustomListItem from '../MyCustomListItem'

interface Props {
  event: EventFromGoogleCalendar
  openDetailsEventDialog: boolean
  setOpenDetailsEventDialog: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void
  isNow?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: { marginLeft: -20, marginTop: 10, marginBottom: 10 },
  typographyCategorie: {
    fontWeight: 600,
    marginRight: 5,
  },
  divider: { width: '100%' },
  warning: {
    color: theme.palette.warning.main,
  },
}))
export default function DialogDetailsEvent({
  event,
  openDetailsEventDialog,
  setOpenDetailsEventDialog,
  isNow,
}: Props) {
  const analytics = firebase.analytics()
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  const history = useHistory()
  const classes = useStyles()
  const handleGoToCatalogue = () => {
    history.push({
      pathname: '/catalogue/boitiers',
      state: {
        start: moment(event.start.dateTime).format('yyyy-MM-DDTHH:mm'),
        end: moment(event.end.dateTime).format('yyyy-MM-DDTHH:mm'),
        title: event.summary,
      },
    })
    analytics.logEvent('eventsDialog-go_to-catalogue')
  }
  const summaryOK = isEventOK(event.summary)

  const handleClose = () => {
    setOpenDetailsEventDialog(false)
  }
  const handleGoToGGCalendar = () => {
    analytics.logEvent('eventsDialog-register')
    window.open(event.htmlLink)
  }

  const [
    description,
    setDescription,
  ] = React.useState<TransformedDescriptionForEvents>(
    defaultTransformedDescriptionForEvents
  )

  const eventOrganiser = useMemo(() => {
    return event.organizer.displayName
      ? event.organizer.displayName.replace('Pics_', '')
      : DisplayNameCalendars.PICS_INTERNE.replace('Pics_', '')
  }, [event.organizer.displayName])

  React.useEffect(() => {
    let copy = { ...defaultTransformedDescriptionForEvents }
    copy.details = returnStrictTextBetweenStringsForDescription(
      event.description,
      1
    )?.split(': ')[1]
    copy.numberPhotographers = returnStrictTextBetweenStringsForDescription(
      event.description,
      2
    )?.split(': ')[1]
    copy.deadline = moment(
      returnStrictTextBetweenStringsForDescription(event.description, 3)?.split(
        ': '
      )[1]
    ).format('dddd Do MMMM ')
    copy.matos = returnStrictTextBetweenStringsForDescription(
      event.description,
      4
    )?.split(': ')[1]
    copy.organizer = event.description?.split('Organisateur </b> : ')[1]
    setDescription(copy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  const displayContact = useMemo(() => {
    const potentialTel = '+33'.concat(description.organizer)
    const isTel = isPhoneNumber(potentialTel)
    if (isTel) {
      return <a href={'tel:'.concat(potentialTel)}>{potentialTel}</a>
    } else {
      return description.organizer
    }
  }, [description.organizer])

  return (
    <Dialog open={openDetailsEventDialog} onClose={handleClose}>
      <CustomDialogTitle
        title={summaryOK.boolean ? event.summary : summaryOK.newValue}
        handleClose={handleClose}
      />
      <DialogContent className={classes.dialogContent}>
        {summaryOK.boolean && event.organizer.displayName && (
          <MyCustomListItem
            text={'Photographe manquant'}
            icon={<WarningIcon />}
            customClassAccentuated={classes.warning}
          />
        )}
        {isNow && (
          <MyCustomListItem
            text={'En ce moment'}
            icon={<QueryBuilderIcon />}
            customClassAccentuated={classes.warning}
          />
        )}
        {((summaryOK.boolean && event.organizer.displayName) || isNow) && (
          <Divider className={classes.divider} />
        )}
        <MyCustomListItem
          title={'Début'}
          text={moment(event.start.dateTime).format('dddd Do MMM LT')}
          icon={<WatchLaterIcon />}
          accentuatedColor
        />
        <MyCustomListItem
          title={'Fin'}
          text={moment(event.end.dateTime).format('dddd Do MMM LT')}
          icon={<QueryBuilderIcon />}
          accentuatedColor
        />
        <MyCustomListItem
          title={'Type'}
          text={eventOrganiser}
          icon={<EventIcon />}
          accentuatedColor
        />
        {event.location && (
          <MyCustomListItem
            title={'Lieu'}
            text={event.location}
            icon={<LocationOnIcon />}
            accentuatedColor
          />
        )}
        <Divider className={classes.divider} />
        {description.details.length > 0 && (
          <MyCustomListItem
            title={'Détails'}
            text={description.details}
            icon={<SubjectIcon />}
            accentuatedColor
          />
        )}
        {description.numberPhotographers.length > 0 && (
          <MyCustomListItem
            title={'Nombre de photographes'}
            text={description.numberPhotographers}
            icon={<GroupIcon />}
            accentuatedColor
          />
        )}
        {description.deadline && description.deadline !== 'Invalid date' && (
          <MyCustomListItem
            title={'Deadline'}
            text={description.deadline}
            icon={<EventAvailableIcon />}
            accentuatedColor
          />
        )}
        {description.matos?.length > 0 &&
          description.matos !== description.organizer && (
            <MyCustomListItem
              title={'Matos'}
              text={description.matos}
              icon={<AddAPhotoIcon />}
              accentuatedColor
            />
          )}
        {description?.organizer?.length > 0 && (
          <MyCustomListItem
            title={'Organisation'}
            text={displayContact}
            icon={<PhoneIcon />}
            accentuatedColor
          />
        )}
      </DialogContent>
      <DialogActions>
        <MyButton
          title={"S'inscrire"}
          callback={handleGoToGGCalendar}
          secondary={true}
        />
        <MyButton title={'Emprunter'} callback={handleGoToCatalogue} />
      </DialogActions>
    </Dialog>
  )
}
