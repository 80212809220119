import { AppBar, Grid } from '@material-ui/core'
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TabScrollButton from '@material-ui/core/TabScrollButton'
import BatteryFullIcon from '@material-ui/icons/BatteryFull'
import CameraIcon from '@material-ui/icons/Camera'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import SdCardIcon from '@material-ui/icons/SdCard'
import React from 'react'
import SwiperCore from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import IndividualCardMatos from '../../components/Matos/IndividualCardMatos'
import { appBarHeight, upperCaseFirst } from '../../utils/various'
import { showTitleAccordingToEnv } from '../../utils/Version'
import {
  MatosDetails,
  MatosTypeEnum,
  MountTypeEnum,
  sortItemAccordingToMainCamerasBrandSelected,
  SwiperIndexCatalogue,
} from './utils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: `calc(2*${appBarHeight})`,
    backgroundColor: theme.palette.background.paper,
    paddingBottom: appBarHeight,
  },
  mainTabs: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
  },
  nestedAppBar: {
    marginTop: appBarHeight,
    height: appBarHeight,
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
  },
  indicator: {
    backgroundColor: theme.palette.indicators.isSelected,
    height: '10px',
    top: '45px',
  },
  typo: {
    textAlign: 'center',
    color: theme.palette.customText.secondary,
  },
}))

interface Props {
  catalog: MatosDetails[]
  handleAddMatos: Function
  selectedMatos: MatosDetails[]
  handleRemoveMatos: Function
  mainMountInSelectedMatos: MountTypeEnum
  openOnSlide?: SwiperIndexCatalogue
  isAdmin?: boolean
}
export default function CatalogTabs({
  catalog,
  handleAddMatos,
  selectedMatos,
  handleRemoveMatos,
  mainMountInSelectedMatos,
  openOnSlide,
  isAdmin,
}: Props) {
  const classes = useStyles()
  const [swiperInstance, setSwiperInstance] = React.useState<SwiperCore>()
  const [value, setValue] = React.useState<SwiperIndexCatalogue>(
    openOnSlide ? openOnSlide : SwiperIndexCatalogue.BOITIER
  )
  React.useEffect(() => {
    document.title = showTitleAccordingToEnv(
      'Catalogue - '.concat(
        upperCaseFirst(SwiperIndexCatalogue[value].toString()),
        's'
      )
    )
  }, [value])

  const displayMatos = (type: MatosTypeEnum) => {
    const c: MatosDetails[] = catalog ? Object.values(catalog) : []
    if (type.toUpperCase() === SwiperIndexCatalogue[value]) {
      ///To avoid rendering everything in the same time
      return (
        <Grid
          container
          alignContent="center"
          justify="center"
          alignItems="center"
          spacing={1}
        >
          {c
            ?.filter((item) => item.type === type)
            /* TO DO : serait mieux en back */
            .sort((item1, item2) =>
              sortItemAccordingToMainCamerasBrandSelected(
                item1,
                item2,
                mainMountInSelectedMatos
              )
            )
            .map((item: MatosDetails) => {
              return (
                <Grid item key={item.id}>
                  <IndividualCardMatos
                    matos={item}
                    handleAddMatos={handleAddMatos}
                    selectedMatos={selectedMatos}
                    handleRemoveMatos={handleRemoveMatos}
                    admin={isAdmin}
                    mainMountInSelectedMatos={mainMountInSelectedMatos}
                  />
                </Grid>
              )
            })}
        </Grid>
      )
    }
  }
  const MyTabScrollButton = withStyles((theme) => ({
    root: {
      opacity: 1,
      color: theme.palette.primary.main,
      width: 15,
      overflow: 'hidden',
      transition: 'width 0.5s',
      '&.Mui-disabled': {
        width: 0,
      },
    },
  }))(TabScrollButton)

  React.useEffect(() => {
    swiperInstance && swiperInstance.slideTo(value + 1)
  }, [swiperInstance, value])

  return (
    <div className={classes.root}>
      <AppBar
        elevation={1}
        position="fixed"
        color="default"
        className={classes.nestedAppBar}
      >
        <Tabs
          value={value}
          onChange={(event: React.ChangeEvent<{}>, newValue: number) => {
            setValue(newValue)
          }}
          variant="scrollable"
          scrollButtons="on"
          ScrollButtonComponent={MyTabScrollButton}
          selectionFollowsFocus
          className={classes.mainTabs}
          TabIndicatorProps={{ className: classes.indicator }}
        >
          <Tab icon={<CameraAltIcon />} />
          <Tab icon={<CameraIcon />} />
          <Tab icon={<SdCardIcon />} />
          <Tab icon={<BatteryFullIcon />} />
          <Tab icon={<FlashOnIcon />} />
          <Tab icon={<LocalMallIcon />} />
        </Tabs>
      </AppBar>
      <Swiper
        onSwiper={setSwiperInstance}
        spaceBetween={5}
        loop={true}
        slidesPerView={1}
        history={{
          key: isAdmin ? 'admin/catalogue' : 'catalogue',
        }}
        onSlideChange={(swiper) => setValue(swiper.realIndex)}
      >
        <SwiperSlide data-history="boitiers">
          {displayMatos(MatosTypeEnum.BOITIER)}
        </SwiperSlide>
        <SwiperSlide data-history="objectifs">
          {displayMatos(MatosTypeEnum.OBJECTIF)}
        </SwiperSlide>
        <SwiperSlide data-history="cartes">
          {displayMatos(MatosTypeEnum.CARTE)}
        </SwiperSlide>
        <SwiperSlide data-history="batteries">
          {displayMatos(MatosTypeEnum.BATTERIE)}
        </SwiperSlide>
        <SwiperSlide data-history="stand">
          {displayMatos(MatosTypeEnum.STAND)}
        </SwiperSlide>
        <SwiperSlide data-history="divers">
          {displayMatos(MatosTypeEnum.OTHER)}
        </SwiperSlide>
      </Swiper>
    </div>
  )
}
