import { Divider, InputAdornment, Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import PersonIcon from '@material-ui/icons/Person'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import SmsIcon from '@material-ui/icons/Sms'
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import classNames from 'classnames'
import 'firebase/analytics'
import firebase from 'firebase/app'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import { LoadingContext } from '../../contexts/loading/LoadingContext'
import {
  changeBookingIntoBorrowing,
  getMatosSorti,
  isConflictedWithBorrowingsLive,
  MatosDetailInBorrowingOrBooking,
  MatosLive,
  putBookingToDelete,
} from '../../pages/Catalogue/utils'
import {
  Booking,
  EndBorrowingForm,
  getBorrowingsOrBookingsDetails,
} from '../../pages/History/utils'
import { getGivenName, getToken } from '../../utils/Auth'
import { LIMIT_VARCHAR, LIMIT_VARCHAR_COMMENT } from '../../utils/various'
import MyButton from '../Buttons/MyButton'
import CustomDialogTitle from '../Dialog/CustomDialogTitle'
import MyCustomListItem from '../MyCustomListItem'
import MatosItemsInBookingAccordeon from './MatosItemsInBookingAccordeon'

interface Props {
  openDetails: boolean
  booking: Booking
  setOpenDetails: any
  rerenderParentCallback?: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: { marginTop: 10, marginBottom: 10 },
    commentInput: { margin: theme.spacing(2), width: '90%' },
    divider: { width: '100%' },
    warning: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
    icon: { marginRight: 5, alignItems: 'center' },
    typoEndDateIfLate: { color: theme.palette.error.main, fontWeight: 700 },
  })
)

export default function BookingDialog({
  openDetails,
  booking,
  setOpenDetails,
  rerenderParentCallback,
}: Props) {
  const analytics = firebase.analytics()

  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  const classes = useStyles()
  const token = getToken()
  const givenName = getGivenName()
  const { incrementLoading, decrementLoading } = useContext(LoadingContext)
  const { enqueueSnackbar } = useSnackbar()
  const [bookingDetails, setBookingDetails] = React.useState<
    MatosDetailInBorrowingOrBooking[]
  >()
  const [matosLive, setMatosLive] = React.useState<MatosLive[]>()
  const [
    bodyEndBorrowing,
    setBodyEndBorrowing,
  ] = React.useState<EndBorrowingForm>({
    title: booking.title,
    id: booking.id,
    enddate: moment.utc().format().substr(0, 19),
    borrower_log_end: givenName,
    comment_end: null,
  })
  const [isConflicted, setIsConflicted] = React.useState<boolean>(false)
  const isLate = moment(booking.startdate).isBefore(new Date())

  React.useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDetails])

  React.useEffect(() => {
    const idsBorrowed: number[] = []
    bookingDetails &&
      bookingDetails.forEach((element) => {
        idsBorrowed.push(element.id_matos)
      })
    matosLive &&
      bookingDetails &&
      isConflictedWithBorrowingsLive(idsBorrowed, matosLive, setIsConflicted)
  }, [bookingDetails, matosLive])

  async function getData() {
    incrementLoading()
    await getBorrowingsOrBookingsDetails(
      token,
      booking.id,
      enqueueSnackbar,
      decrementLoading,
      false
    ).then((res) => {
      setBookingDetails(res)
      decrementLoading()
    })

    await getMatosSorti(getToken(), enqueueSnackbar, decrementLoading).then(
      (res) => {
        setMatosLive(res)
      }
    )
  }

  const handleClose = () => {
    setOpenDetails(false)
  }

  const handleCancel = () => {
    incrementLoading()
    putBookingToDelete(
      booking.id,
      enqueueSnackbar,
      bodyEndBorrowing.comment_end,
      decrementLoading,
      rerenderParentCallback,
      handleClose
    )
  }

  const handleSubmit = () => {
    incrementLoading()
    bookingDetails &&
      changeBookingIntoBorrowing(
        isConflicted,
        booking,
        bookingDetails,
        getGivenName(),
        enqueueSnackbar,
        decrementLoading,
        rerenderParentCallback,
        handleClose
      )
    analytics.logEvent('bookingDialog-create_borrowing')
  }
  const DisplayInfoForBooking = () => {
    return (
      <span>
        <MyCustomListItem
          title={'Réservé par'}
          text={booking.borrower_log_start}
          icon={<PersonIcon />}
          accentuatedColor
        />
        <MyCustomListItem
          title={'Réservé le'}
          text={moment(booking.startdate_log).format('dddd Do MMM LT')}
          icon={<WatchLaterIcon />}
          accentuatedColor
        />

        {booking.comment_start?.length > 0 && (
          <MyCustomListItem
            title={'Commentaire'}
            text={booking.comment_start}
            icon={<SmsIcon />}
            accentuatedColor
          />
        )}
      </span>
    )
  }
  const DisplayBeginInfoForBooking = () => {
    return (
      <span>
        {booking.borrower_start !== booking.borrower_log_start && (
          <MyCustomListItem
            title={'Emprunteur'}
            text={booking.borrower_start}
            icon={<PersonIcon />}
            accentuatedColor
          />
        )}
        <MyCustomListItem
          title={"Début estimé de l'emprunt"}
          text={moment(booking.startdate).format('dddd Do MMM LT')}
          icon={<QueryBuilderIcon />}
          accentuatedColorError={classNames(
            isLate && classes.typoEndDateIfLate
          )}
          accentuatedColor
        />
      </span>
    )
  }
  const DisplayEndInfoForBooking = () => {
    return (
      <MyCustomListItem
        title={"Fin estimée de l'emprunt"}
        text={moment(booking.enddate).format('dddd Do MMM LT')}
        icon={<QueryBuilderIcon />}
        accentuatedColor
      />
    )
  }

  const errorInBodyExists = () => {
    const comment_end_error = bodyEndBorrowing.comment_end
      ? bodyEndBorrowing.comment_end.length > LIMIT_VARCHAR_COMMENT
      : false

    return (
      comment_end_error ||
      isConflicted ||
      bodyEndBorrowing.borrower_log_end.length > LIMIT_VARCHAR ||
      bodyEndBorrowing.title.length > LIMIT_VARCHAR
    )
  }

  return (
    <Dialog open={openDetails} onClose={handleClose}>
      <CustomDialogTitle
        title={'[Résa] '.concat(booking.title)}
        handleClose={handleClose}
      />
      <DialogContent className={classes.dialogContent}>
        <DisplayInfoForBooking />
        <Divider className={classes.divider} />
        <DisplayBeginInfoForBooking />
        <Divider className={classes.divider} />
        <DisplayEndInfoForBooking />
        <Divider className={classes.divider} />

        <TextField
          autoComplete="off"
          className={classes.commentInput}
          margin="dense"
          id="CommentEnd"
          label="Commentaire d'emprunt"
          type="comment"
          variant="outlined"
          value={bodyEndBorrowing?.comment_end}
          onChange={(e) =>
            setBodyEndBorrowing({
              ...bodyEndBorrowing,
              comment_end: e.target.value,
            })
          }
          error={
            bodyEndBorrowing.comment_end
              ? bodyEndBorrowing.comment_end.length > LIMIT_VARCHAR_COMMENT
              : false
          }
          helperText={
            bodyEndBorrowing.comment_end
              ? bodyEndBorrowing.comment_end.length > LIMIT_VARCHAR_COMMENT
                ? 'Trop long'
                : ''
              : ''
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SmsOutlinedIcon className={classes.icon} />
              </InputAdornment>
            ),
          }}
        />

        <Divider className={classes.divider} />
        <MatosItemsInBookingAccordeon
          matosInBooking={bookingDetails}
          isConflicted={isConflicted}
          matosLive={matosLive}
        />
        {isConflicted && (
          <Typography
            style={{ wordWrap: 'break-word', textAlign: 'center' }}
            className={classes.warning}
          >
            Impossible d'emprunter maintenant
            <br />
            Du matos de la réservation est déjà emprunté
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <MyButton
          title={'Emprunter'}
          callback={handleSubmit}
          isDisabled={errorInBodyExists()}
        />
        <MyButton
          callback={handleCancel}
          title={'Annuler ma réservation'}
          secondary
        />
      </DialogActions>
    </Dialog>
  )
}
