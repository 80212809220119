import React from 'react'
import { FrontNotificationsRES } from '../../components/AppBar/utils'
import {
  defaultNotificationsUserOptions,
  NotificationsUserOptions,
} from '../../pages/Notifications/utils'

export interface NotificationContextInterface {
  notifications: FrontNotificationsRES[]
  triggerGetDataNotifications: () => void
  isFirebaseTokenFound: boolean
  triggerFirebaseToken: () => void
  userPreferences: NotificationsUserOptions
  triggerGetUserPreferencesNotifications: () => void
}
export const NotificationsContext = React.createContext<NotificationContextInterface>(
  {
    notifications: [],
    triggerGetDataNotifications: () => null,
    isFirebaseTokenFound: true,
    triggerFirebaseToken: () => null,
    userPreferences: defaultNotificationsUserOptions,
    triggerGetUserPreferencesNotifications: () => null,
  }
)
