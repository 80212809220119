import {
  createMuiTheme,
  createStyles,
  CssBaseline,
  makeStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import fr from 'date-fns/locale/fr'
import firebase from 'firebase/app'
import 'firebase/messaging'
import { SnackbarProvider, useSnackbar } from 'notistack'
import React, { Fragment, useCallback, useMemo } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PACKAGE from '../package.json'
import {
  IS_DARK_DEFAULT_THEME,
  useThemeRefHandler,
} from './components/ThemeModeSwitch'
import ThemeSwitcherContext from './contexts/theme/ThemeSwitcherContext'
import {
  FirebaseNotification,
  FirebasePayload,
  onFirebaseMessageListener,
} from './firebase'
import { MainPages } from './pages/index'
import LoadingProvider from './providers/loading/loadingProvider'
import NotificationsProvider from './providers/notifications/notificationsProvider'
import { darkTheme, lightTheme } from './theme'
import { getDarkModeLocalStorage, getToken } from './utils/Auth'

const authContext = {}
export const AuthContext = React.createContext(authContext)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    genericVariantSnack: { minWidth: 200, padding: theme.spacing(2) },
  })
)

const DismissActionNotif = ({ id }) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <Fragment>
      <IconButton
        onClick={() => {
          closeSnackbar(id)
        }}
        color={'secondary'}
      >
        <CloseIcon />
      </IconButton>
    </Fragment>
  )
}
const App = (): JSX.Element => {
  let token: string = getToken()
  const classes = useStyles()

  const [darkMode, setDarkMode] = React.useState<boolean | undefined>(
    getDarkModeLocalStorage() ?? IS_DARK_DEFAULT_THEME
  )

  const loadTheme = useCallback((): void => {
    if (darkMode !== undefined) {
      setDarkMode(darkMode)
    } else {
      setDarkMode(IS_DARK_DEFAULT_THEME)
    }
  }, [darkMode])

  const theme = useMemo(
    () => createMuiTheme(darkMode ? darkTheme : lightTheme),
    [darkMode]
  )

  useThemeRefHandler(darkMode)

  const [
    firebaseNotification,
    setFirebaseNotification,
  ] = React.useState<FirebaseNotification | null>(null)

  if (firebase.messaging.isSupported()) {
    onFirebaseMessageListener()
      .then((payload) => {
        const p = payload as FirebasePayload
        p.notification &&
          setFirebaseNotification({
            title: p.notification.title,
            body: p.notification.body,
          })
      })
      .catch((err) =>
        console.error('Erreur lors de la réception de la notification: ', err)
      )
  } else {
    console.warn('Firebase not supported')
  }

  React.useEffect(() => {
    console.info(
      process.env.REACT_APP_NODE_STAGING_ENV || process.env.NODE_ENV,
      PACKAGE.version
    )
  }, [])

  return (
    <Router>
      <CssBaseline />
      <ThemeSwitcherContext.Provider value={loadTheme}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
            <SnackbarProvider
              preventDuplicate={true}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              classes={{
                variantSuccess: classes.genericVariantSnack,
                variantError: classes.genericVariantSnack,
                variantWarning: classes.genericVariantSnack,
                variantInfo: classes.genericVariantSnack,
              }}
              action={(key) => <DismissActionNotif id={key} />}
              autoHideDuration={2000}
              maxSnack={2}
            >
              <LoadingProvider>
                <NotificationsProvider>
                  <Switch>
                    <Route>
                      <MainPages
                        token={token}
                        firebaseNotification={firebaseNotification}
                        darkMode={darkMode}
                        setDarkMode={setDarkMode}
                      />
                    </Route>
                  </Switch>
                </NotificationsProvider>
              </LoadingProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </ThemeSwitcherContext.Provider>
    </Router>
  )
}

export default App
