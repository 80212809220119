import { Paper, Typography } from '@material-ui/core'
import React from 'react'

interface Props {
  customClass: string
}
export default function Information({ customClass }: Props) {
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')

  if (moment(new Date()).isBefore(moment('2022-02-01'))) {
    return (
      <Paper className={customClass}>
        <Typography variant={'h6'}>Le mode sombre débarque !</Typography> Mais
        rassure toi, tu peux toujours repasser en mode clair en ouvrant le menu
        !
      </Paper>
    )
  } else {
    return <React.Fragment />
  }
}
