import { Grid } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import VisibilityIcon from '@material-ui/icons/Visibility'
import 'firebase/analytics'
import firebase from 'firebase/app'
import React from 'react'
import { MatosDetailInBorrowingOrBooking } from '../../pages/Catalogue/utils'
import SummaryIndividualCardMatos from '../Matos/SummaryIndividualCardMatos'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
)

interface Props {
  matosInBorrowing: MatosDetailInBorrowingOrBooking[]
}
export default function MatosItemsInCreatedBorrowAccordeon({
  matosInBorrowing,
}: Props) {
  const analytics = firebase.analytics()
  const classes = useStyles()

  const showMatosSelected = () => {
    return (
      <Grid container direction="column">
        {matosInBorrowing?.map((item) => {
          return (
            <Grid item key={item.id_borrowing}>
              <SummaryIndividualCardMatos
                matos={null}
                matosDetailInBorrowingOrBooking={item}
                canDeleteMatos={false}
              />
            </Grid>
          )
        })}
      </Grid>
    )
  }
  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<VisibilityIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => {
            analytics.logEvent('borrowingCard-open_accordeon')
          }}
        >
          <Typography className={classes.heading}>Voir le matos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <span>{showMatosSelected()}</span>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
