import { transformMatosDetailInBorrowingOrBooking_To_MatosDetails } from '../../components/Borrowings/utils'
import { getGivenName, getToken } from '../../utils/Auth'
import { snackBarErrorOrFailToFetch } from '../../utils/SnackBarGenericError'
import { Booking, BookingStatus, BorrowingStatus } from '../History/utils'
export interface MatosDetails {
  id: number
  brand: string
  model: string
  name: string
  mount: string
  capacity: number
  type: MatosTypeEnum
  status: MatosStatusEnum
  pics: string
}
export enum MountTypeEnum {
  NIKON = 'Nikon',
  CANON = 'Canon',
  MAVIC = 'Mavic',
  NA = 'N/A',
}
export interface LabelsForBorrowingCreation {
  TITLE: boolean
  FOR_WHO: boolean
  START_DATE: boolean
  END_DATE: boolean
  COMMENT_START: boolean
}
export interface NewBorrowingOrBookingForm {
  title: string
  borrower_log_start: string
  borrower_start: string
  comment_start: string
  startdate_log: Date
  startdate: Date
  enddate: Date
  status: BorrowingStatus | BookingStatus
  matos: MatosDetails[]
}
export interface NewBorrowingOrBookingBody {
  title: string
  borrower_log_start: string
  borrower_start: string
  comment_start: string
  startdate_log: Date
  startdate: Date
  enddate: Date
  status: BorrowingStatus | BookingStatus
  matos: number[]
}
export enum MatosStatusEnum {
  LOCAL = 'Local',
  INDISPONIBLE = 'Indisponible',
  COUV = 'Couv',
  ARCHIVED = 'Archived',
}
export enum MatosTypeEnum {
  BOITIER = 'Boitier',
  OBJECTIF = 'Objectif',
  CARTE = 'Carte',
  BATTERIE = 'Batterie',
  STAND = 'Stand',
  OTHER = 'Other',
}

export const itemMatosStatusMenu = [
  {
    value: MatosStatusEnum.COUV,
  },

  {
    value: MatosStatusEnum.LOCAL,
  },
  {
    value: MatosStatusEnum.ARCHIVED,
  },
  {
    value: MatosStatusEnum.INDISPONIBLE,
  },
]
export const buildItemMatosTypeMenu = () => {
  let array: any = []
  for (let type in MatosTypeEnum) {
    array.push({ value: MatosTypeEnum[type] })
  }

  return array
}
export const buildMountMatosTypeMenu = () => {
  let array: any = []
  for (let type in MountTypeEnum) {
    array.push({ value: MountTypeEnum[type] })
  }
  return array
}

export interface MatosDetailInBorrowingOrBooking {
  id: number
  title: string
  startdate_log: Date
  startdate: Date
  enddate: Date
  borrower_log_start: string
  borrower_log_end: string
  borrower_start: string
  status: BookingStatus | BorrowingStatus
  comment_start: string
  comment_end: string
  id_borrowing: number
  id_matos: number
  brand: string
  model: string
  name: string
  mount: string
  capacity: number
  type: MatosTypeEnum
  pics: string
}
interface BodyToTransformBookingStatus {
  booking_id: number
  new_status: BookingStatus
  new_enddate: Date
  new_borrower_log_end: string
  new_comment_end?: string
}

export interface MatosLive {
  id: number
  name: string
  type: MatosTypeEnum
}
export const extractIdFromMatosSelected = (selectedMatos: MatosDetails[]) => {
  let id: number[] = []
  selectedMatos.forEach((matos) => {
    id.push(matos.id)
  })
  return id
}
export function getCatalogExceptArchived(
  token: string,
  enqueueSnackbar: Function,
  decrementLoading: Function
) {
  const url = process.env.REACT_APP_URL_BACK + 'catalogue/excludeArchived/get'
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getCatalogExceptArchived',
        decrementLoading
      )
    })
}

export function convertToUTC(dateToConvert: Date) {
  var moment = require('moment')
  require('moment/min/locales.min')
  return moment(dateToConvert).utc().format('yyyy-MM-DDTHH:mm:ss')
}
export function postNewBorrowing(
  token: string,
  bodyToSent: NewBorrowingOrBookingForm,
  decrementLoading: Function,
  setOpenDialogValidationCart: Function,
  handleRemoveMatos: Function,
  rerenderParentCallback: Function,
  enqueueSnackbar: Function
) {
  ///TODO un peu crade
  let newBody: NewBorrowingOrBookingBody = {
    title: bodyToSent.title.replace("'", "\\'"),
    borrower_log_start: bodyToSent.borrower_log_start.replace("'", "\\'"),
    borrower_start: bodyToSent.borrower_start.replace("'", "\\'"),
    comment_start: bodyToSent.comment_start.replace("'", "\\'"),
    startdate_log: convertToUTC(bodyToSent.startdate_log),
    startdate: convertToUTC(bodyToSent.startdate),
    enddate: convertToUTC(bodyToSent.enddate),
    status: bodyToSent.status,
    matos: extractIdFromMatosSelected(bodyToSent.matos),
  }

  return fetch(process.env.REACT_APP_URL_BACK + 'borrowings/new/post', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newBody),
  })
    .then((res) => {
      res.json()
      if (res.status !== 400) {
        setOpenDialogValidationCart(false)
        handleRemoveMatos([], true)
        enqueueSnackbar('Emprunt effectué', {
          variant: 'success',
        })
        rerenderParentCallback()
      } else {
        enqueueSnackbar(
          "Impossible d'emprunter, réessaye prochainement (ERR_400)",
          {
            variant: 'error',
          }
        )
      }
      decrementLoading()
    })
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'postNewBorrowing',
        decrementLoading
      )
    })
}
export function postNewBooking(
  token: string,
  bodyToSent: NewBorrowingOrBookingForm,
  decrementLoading: Function,
  setOpenDialogValidationCart: Function,
  handleRemoveMatos: Function,
  rerenderParentCallback: Function,
  enqueueSnackbar: Function
) {
  ///TODO un peu crado tjs
  let newBody: NewBorrowingOrBookingBody = {
    title: bodyToSent.title.replace("'", "\\'"),
    borrower_log_start: bodyToSent.borrower_log_start.replace("'", "\\'"),
    borrower_start: bodyToSent.borrower_start.replace("'", "\\'"),
    comment_start: bodyToSent.comment_start.replace("'", "\\'"),
    startdate_log: convertToUTC(bodyToSent.startdate_log),
    startdate: convertToUTC(bodyToSent.startdate),
    enddate: convertToUTC(bodyToSent.enddate),
    status: bodyToSent.status,
    matos: extractIdFromMatosSelected(bodyToSent.matos),
  }
  ///Transform en UTC
  return fetch(process.env.REACT_APP_URL_BACK + 'bookings/new/post', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newBody),
  })
    .then((res) => {
      res.json()
      if (res.status !== 400) {
        setOpenDialogValidationCart(false)
        handleRemoveMatos([], true)
        rerenderParentCallback()
        enqueueSnackbar(
          'Réservation effectuée, pense à la transformer en emprunt au moment venu !',
          {
            variant: 'success',
          }
        )
        decrementLoading()
      } else {
        enqueueSnackbar(
          'Impossible de réserver, réessaye prochainement (ERR_400)',
          {
            variant: 'error',
          }
        )
      }
    })
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'postNewBooking',
        decrementLoading,
        'Impossible de créer la réservation'
      )
    })
}

export function getMatosSorti(
  token: string,
  enqueueSnackbar: Function,
  decrementLoading: Function
) {
  return fetch(process.env.REACT_APP_URL_BACK + 'matos/couv/get', {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getMatosSorti',
        decrementLoading
      )
    })
}
function putNewStatusBooking(
  bodyToSent: BodyToTransformBookingStatus,
  token: string,
  enqueueSnackbar: Function,
  decrementLoading: Function
) {
  const body = JSON.stringify(bodyToSent)
  return fetch(process.env.REACT_APP_URL_BACK + 'bookings/new_status/put', {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: body,
  })
    .then((res) => res.json())
    .catch(
      (error) =>
        error.toString().indexOf('Unexpected token S in JSON at position 0') ===
          -1 &&
        snackBarErrorOrFailToFetch(
          error,
          enqueueSnackbar,
          'putNewStatusBooking',
          decrementLoading
        )
    )
}
function postNewBorrowingLight(
  bodyToSent: NewBorrowingOrBookingForm,
  token: string,
  enqueueSnackbar: Function,
  decrementLoading: Function
) {
  let newBody: NewBorrowingOrBookingBody = {
    title: bodyToSent.title.replace("'", "\\'"),
    borrower_log_start: bodyToSent.borrower_log_start.replace("'", "\\'"),
    borrower_start: bodyToSent.borrower_start.replace("'", "\\'"),
    comment_start: bodyToSent.comment_start.replace("'", "\\'"),
    startdate_log: bodyToSent.startdate_log,
    startdate: bodyToSent.startdate,
    enddate: convertToUTC(bodyToSent.enddate),
    status: bodyToSent.status,
    matos: extractIdFromMatosSelected(bodyToSent.matos),
  }
  return fetch(process.env.REACT_APP_URL_BACK + 'borrowings/new/post', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newBody),
  })
    .then((res) => res.json())
    .catch((error) =>
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'postNewBorrowingLight',
        decrementLoading
      )
    )
}
function bodyForNewBorrowingsFromBooking(
  booking: Booking,
  details: MatosDetailInBorrowingOrBooking[],
  givenName: string
) {
  var moment = require('moment')
  const bodyForNewBorrowing: NewBorrowingOrBookingForm = {
    title: booking.title.replace("'", "\\'"),
    borrower_log_start: givenName.replace("'", "\\'"),
    borrower_start: givenName.replace("'", "\\'"),
    comment_start: booking.comment_start.concat(
      ' [Issu de la réservation de ',
      booking.borrower_start,
      ' créée le ',
      moment(booking.startdate_log).format('llll').toString(),
      ' et transformée le ',
      moment().format('llll').toString(),
      ']'
    ),
    startdate_log: convertToUTC(moment().format('yyyy-MM-DDTHH:mm:ss')),
    startdate: convertToUTC(moment().format('yyyy-MM-DDTHH:mm:ss')),
    enddate: moment(booking.enddate).format('yyyy-MM-DDTHH:mm:ss'),
    status: BorrowingStatus.SORTI,
    matos: transformMatosDetailInBorrowingOrBooking_To_MatosDetails(details),
  }

  return bodyForNewBorrowing
}

export const isConflictedWithBorrowingsLive = (
  matosToBeBorrowed: number[],
  matosAlreadyBorrowed: MatosLive[],
  setIsConflicted: (value: boolean | ((prevVar: boolean) => boolean)) => void
) => {
  matosAlreadyBorrowed.forEach((matosBorrowed) => {
    matosToBeBorrowed.forEach((matosToBe) => {
      if (matosToBe === matosBorrowed.id) {
        setIsConflicted(true)
      }
    })
  })
}
export const putBookingToDelete = (
  booking_id: number,
  enqueueSnackbar: Function,
  comment_end: string | null,
  decrementLoading: Function,
  rerenderParentCallback: Function,
  handleClose: Function
) => {
  var moment = require('moment')
  if (!comment_end) {
    comment_end = ''
  }
  const bodyToSent: BodyToTransformBookingStatus = {
    booking_id: booking_id,
    new_enddate: convertToUTC(moment().format('yyyy-MM-DDTHH:mm:ss')),
    new_borrower_log_end: getGivenName().replace("'", "\\'"),
    new_comment_end: 'Canceled on '
      .concat(
        convertToUTC(moment().format('yyyy-MM-DDTHH:mm:ss')),
        ' ',
        comment_end
      )
      .replace("'", "\\'"),
    new_status: BookingStatus.CANCELED,
  }
  putNewStatusBooking(bodyToSent, getToken(), enqueueSnackbar, decrementLoading)
    .then(() => {
      enqueueSnackbar('Réservation annulée', {
        variant: 'success',
      })
      rerenderParentCallback()
      decrementLoading()
      handleClose()
    })
    .catch((error) => {
      error.toString().indexOf('Unexpected token S in JSON at position 0') ===
        -1 &&
        snackBarErrorOrFailToFetch(
          error,
          enqueueSnackbar,
          'putBookingToDelete',
          decrementLoading
        )
    })
}

export const changeBookingIntoBorrowing = (
  isConflicted: boolean,
  booking: Booking,
  details: MatosDetailInBorrowingOrBooking[],
  givenName: string,
  enqueueSnackbar: Function,
  decrementLoading: Function,
  rerenderParentCallback: Function,
  handleClose: Function
) => {
  var moment = require('moment')
  const bodyToSentForBorrowing = bodyForNewBorrowingsFromBooking(
    booking,
    details,
    givenName
  )
  const bodyToTransformFormerBooking: BodyToTransformBookingStatus = {
    booking_id: booking.id,
    new_enddate: convertToUTC(moment().format('yyyy-MM-DDTHH:mm:ss')),
    new_borrower_log_end: getGivenName().replace("'", "\\'"),
    new_comment_end: 'Transformed on '
      .concat(convertToUTC(moment().format('yyyy-MM-DDTHH:mm:ss')))
      .replace("'", "\\'"),
    new_status: BookingStatus.CANCELED,
  }
  if (isConflicted) {
    enqueueSnackbar("Un des matos est déjà emprunté, impossible d'emprunter", {
      variant: 'error',
    })
    decrementLoading()
  } else {
    postNewBorrowingLight(
      bodyToSentForBorrowing,
      getToken(),
      enqueueSnackbar,
      decrementLoading
    )
      .then((res) => {
        putNewStatusBooking(
          bodyToTransformFormerBooking,
          getToken(),
          enqueueSnackbar,
          decrementLoading
        )
          .then(() => {
            enqueueSnackbar('Réservation transformée en emprunt', {
              variant: 'success',
            })
            decrementLoading()
            handleClose()
            rerenderParentCallback()
          })
          .catch((error) => {
            snackBarErrorOrFailToFetch(
              error,
              enqueueSnackbar,
              'changeBookingIntoBorrowing',
              decrementLoading
            )
          })
      })
      .catch((error) => {
        snackBarErrorOrFailToFetch(
          error,
          enqueueSnackbar,
          'changeBookingIntoBorrowing',
          decrementLoading
        )
      })
  }
}
export const buildItemMatosMountMenu = () => {
  let array: any = []
  for (let type in MountTypeEnum) {
    array.push({ value: MountTypeEnum[type] })
  }
  return array
}

export const findMainMountInSelectedMatos = (selectedMatos: MatosDetails[]) => {
  const maxMountOccurence = selectedMatos
    .filter((item) => item.type === MatosTypeEnum.BOITIER)
    .sort(
      (prev, curr) =>
        selectedMatos.filter((item) => item.brand === prev.brand).length -
        selectedMatos.filter((item) => item.brand === curr.brand).length
    )
    .pop()
  return maxMountOccurence?.brand as MountTypeEnum
}

export const TYPES_TO_DISPLAY_MOUNT = [
  MatosTypeEnum.OBJECTIF,
  MatosTypeEnum.BATTERIE,
]

export const sortItemAccordingToMainCamerasBrandSelected = (
  item1: MatosDetails,
  item2: MatosDetails,
  mainMountInSelectedMatos: MountTypeEnum
): number => {
  if (!TYPES_TO_DISPLAY_MOUNT.includes(item1.type)) {
    return 0
  }
  if (mainMountInSelectedMatos === MountTypeEnum.NA) {
    return 0
  }
  if (
    item1.mount.indexOf(mainMountInSelectedMatos) > -1 &&
    item2.mount.indexOf(mainMountInSelectedMatos) === -1
  ) {
    return -1
  } else if (
    item2.mount.indexOf(mainMountInSelectedMatos) > -1 &&
    item1.mount.indexOf(mainMountInSelectedMatos) === -1
  ) {
    return 1
  } else {
    return 0
  }
}
export enum SwiperIndexCatalogue {
  BOITIER = 0,
  OBJECTIF = 1,
  CARTE = 2,
  BATTERIE = 3,
  STAND = 4,
  OTHER = 5,
}
