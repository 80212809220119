import { BorrowingStatus } from '../../pages/History/utils'
import { snackBarErrorOrFailToFetch } from '../../utils/SnackBarGenericError'
export interface PropsPopoverItem {
  borrower_start: string
  startdate?: string
  enddate?: string
  title: string
  id: number
  originalId: number
  forAdmin?: boolean
  closePopover: () => void
}

export interface FrontNotificationsRES {
  id: number
  originalId: number
  originalTable: 'borrowings' | 'bookings'
  createdAt: string
  removedAt: string | null
  description: string
  title: string
  startdate_log: string
  startdate: string
  enddate: string
  borrower_log_start: string
  borrower_log_end: string | null
  borrower_start: string
  status: BorrowingStatus
  comment_start: string
  comment_end: string | null
}

export function getLiveFrontNotifs(token: string, enqueueSnackbar: Function) {
  const url = process.env.REACT_APP_URL_BACK + 'notifications/front/live/get'
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(error, enqueueSnackbar, 'getLiveFrontNotifs')
    })
}
