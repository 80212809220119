import { CardActionArea, CardHeader, Grid } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import classNames from 'classnames'
import { useSnackbar } from 'notistack'
import React, { useContext, useMemo } from 'react'
import { LoadingContext } from '../../contexts/loading/LoadingContext'
import {
  MatosDetails,
  MatosStatusEnum,
  MatosTypeEnum,
  MountTypeEnum,
  TYPES_TO_DISPLAY_MOUNT,
} from '../../pages/Catalogue/utils'
import {
  getMatosInBorrowingsHistoryDetails,
  getNextBookingForMatosDetails,
  MatosInBorrowingsHistoryDetails,
  NextBookingForMatosDetails,
} from '../../pages/Metrics/utils'
import { getToken } from '../../utils/Auth'
import ChipBorrowerIfInCouv from './ChipBorrowerIfInCouv'
import ChipMatosIsBookedToday from './ChipMatosIsBookedToday'
import ChipMatosStatus from './ChipMatosStatus'
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 10,
    width: 350,
    height: 220,
    boxShadow: `0 8px 40px -12px ${theme.palette.boxShadow.primary}`,
    '&:hover': {
      boxShadow: `0 16px 70px -12.125px ${theme.palette.boxShadow.primary}`,
    },
    backgroundColor: theme.palette.background.default,
  },
  rootIndisponible: {
    margin: 10,
    width: 300,
    height: 220,
    backgroundColor: theme.palette.matos.card.disabled,
  },
  rootIsSelected: { backgroundColor: theme.palette.matos.card.selected },
  rootIsBookedToday: { border: `1px solid ${theme.palette.warning.main}` },
  mainMountInSelectedMatos: { color: theme.palette.indicators.isSelected },
  notMainMountInSelectedMatos: { color: theme.palette.indicators.default },
  media: {
    height: 125,
    width: 125,
  },
  content: { paddingLeft: 15 },
  checkbox: { margin: 10 },
}))

interface Props {
  matos: MatosDetails
  handleRemoveMatos: Function
  handleAddMatos: Function
  selectedMatos: MatosDetails[]
  mainMountInSelectedMatos?: MountTypeEnum
  admin?: boolean
}
export default function IndividualCardMatos({
  matos,
  handleAddMatos,
  handleRemoveMatos,
  selectedMatos,
  mainMountInSelectedMatos,
  admin,
}: Props) {
  const classes = useStyles()
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')

  const { enqueueSnackbar } = useSnackbar()
  const { incrementLoading, decrementLoading } = useContext(LoadingContext)
  const [
    nextBookingForThisMatos,
    setNextBookingForThisMatos,
  ] = React.useState<NextBookingForMatosDetails | null>(null)
  const [
    matosInBorrowingsHistoryDetails,
    setMatosInBorrowingsHistoryDetails,
  ] = React.useState<MatosInBorrowingsHistoryDetails[]>([])

  async function getNextBookingsForThisMatos() {
    incrementLoading()
    await getNextBookingForMatosDetails(
      getToken(),
      matos.id,
      enqueueSnackbar,
      decrementLoading
    ).then((res: NextBookingForMatosDetails[]) => {
      if (res.length === 0) {
        setNextBookingForThisMatos(null)
      } else {
        res.sort((a, b) => moment(a).isBefore(b))
        setNextBookingForThisMatos(res.slice(-1)[0])
      }
      decrementLoading()
    })
  }

  const nextBookingDateTime = useMemo(() => {
    if (nextBookingForThisMatos !== null) {
      return nextBookingForThisMatos.startdate
    } else {
      return null
    }
  }, [nextBookingForThisMatos])

  const now = useMemo(() => {
    return moment().format()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const shouldBeBorrowedWithin24Hours: boolean = useMemo(() => {
    if (nextBookingDateTime) {
      return (
        moment(nextBookingDateTime).isAfter(now) &&
        moment(nextBookingDateTime).isBefore(moment(now).add(1, 'days'))
      )
    } else {
      return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextBookingDateTime, now])

  const wasToBeBorrowedLessThan30MinAgo: boolean = useMemo(() => {
    if (nextBookingDateTime) {
      return (
        moment(now).isAfter(moment(nextBookingDateTime)) &&
        moment(now).isBefore(moment(nextBookingDateTime).add(30, 'minutes'))
      )
    } else {
      return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextBookingDateTime, now])

  async function getDataForMatosInCouv() {
    incrementLoading()
    await getMatosInBorrowingsHistoryDetails(
      getToken(),
      matos.id,
      enqueueSnackbar,
      decrementLoading
    ).then((res) => {
      //Par ordre chronologique
      setMatosInBorrowingsHistoryDetails(
        res.sort((a, b) => a.startdate.localeCompare(b.startdate))
      )
      decrementLoading()
    })
  }

  React.useEffect(() => {
    matos.status === MatosStatusEnum.COUV && getDataForMatosInCouv()
    getNextBookingsForThisMatos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  interface MountProps {
    mount: MountTypeEnum
  }

  const Mount = ({ mount }: MountProps): JSX.Element => {
    return (
      <Grid container spacing={1}>
        <Grid item>
          <CameraAltIcon
            className={
              mainMountInSelectedMatos && mount === mainMountInSelectedMatos
                ? classes.mainMountInSelectedMatos
                : classes.notMainMountInSelectedMatos
            }
          />
        </Grid>
        <Grid item>{mount}</Grid>
      </Grid>
    )
  }

  return (
    <Card
      className={classNames(
        matos.status === MatosStatusEnum.INDISPONIBLE ||
          matos.status === MatosStatusEnum.ARCHIVED
          ? classes.rootIndisponible
          : classes.root,
        selectedMatos.includes(matos) && classes.rootIsSelected,
        (wasToBeBorrowedLessThan30MinAgo || shouldBeBorrowedWithin24Hours) &&
          classes.rootIsBookedToday
      )}
      onClick={() => {
        if (selectedMatos.includes(matos)) {
          handleRemoveMatos(matos)
        } else {
          if (admin) {
            handleAddMatos(matos)
          } else {
            if (matos.status !== MatosStatusEnum.INDISPONIBLE) {
              handleAddMatos(matos)
            }
          }
        }
      }}
    >
      {' '}
      <CardActionArea>
        <CardHeader
          action={
            <Checkbox
              className={classes.checkbox}
              size="medium"
              color="default"
              checked={selectedMatos.includes(matos)}
              disabled={
                !admin &&
                (matos.status === MatosStatusEnum.INDISPONIBLE ||
                  matos.status === MatosStatusEnum.ARCHIVED)
              }
            />
          }
          avatar={<ChipMatosStatus status={matos.status} />}
          title={matos.name}
          titleTypographyProps={{ variant: 'h5' }}
        />
        <Grid
          container
          direction="row"
          className={classes.content}
          alignItems={'center'}
        >
          <Grid item xs={6}>
            <CardMedia
              component={'img'}
              className={classes.media}
              image={matos.pics}
              title={matos.pics}
            />
          </Grid>
          <Grid item container direction={'column'} xs={6}>
            <Typography variant={'h6'}>{matos.brand}</Typography>
            <Typography variant={'subtitle1'}>{matos.model}</Typography>
            <Typography variant={'subtitle1'}>
              {matos.type === MatosTypeEnum.CARTE &&
                matos.capacity.toString().concat(' Go')}
              {TYPES_TO_DISPLAY_MOUNT.includes(matos.type) && (
                <Mount mount={matos.mount as MountTypeEnum} />
              )}
            </Typography>

            {matos.status === MatosStatusEnum.COUV && (
              <ChipBorrowerIfInCouv
                borrower_start={
                  matosInBorrowingsHistoryDetails
                    ?.slice(-1)[0]
                    ?.borrower_start.split(' ')[0]
                  ///On prend le dernier emprunt de la liste précédemment triée par ordre chrono (Donc le plus récent emprunt)
                }
                status={matos.status}
              />
            )}
            <ChipMatosIsBookedToday
              shouldBeBorrowedWithin24Hours={shouldBeBorrowedWithin24Hours}
              wasToBeBorrowedLessThan30MinAgo={wasToBeBorrowedLessThan30MinAgo}
              expectedDateOfBorrowing={nextBookingDateTime}
            />
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  )
}
