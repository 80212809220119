import { Dialog, Fab, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import SendIcon from '@material-ui/icons/Send'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import WatchLaterIcon from '@material-ui/icons/WatchLater'
import clsx from 'clsx'
import 'firebase/analytics'
import firebase from 'firebase/app'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import CartDialog from '../../components/Catalogue/CartDialog'
import DialogValidationCart from '../../components/Catalogue/DialogValidationCart/DialogValidationCart'
import CatalogSearchDialog from '../../components/Catalogue/SearchDialog'
import { LoadingContext } from '../../contexts/loading/LoadingContext'
import { NotificationsContext } from '../../contexts/notifications/NotificationsContext'
import { getGivenName, getToken } from '../../utils/Auth'
import { useFabStyles } from '../../utils/various'
import { BorrowingStatus } from '../History/utils'
import CatalogTabs from './CatalogTabs'
import {
  findMainMountInSelectedMatos,
  getCatalogExceptArchived,
  MatosDetails,
  MatosStatusEnum,
  MountTypeEnum,
  NewBorrowingOrBookingForm,
  SwiperIndexCatalogue,
} from './utils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignContent: 'center',
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
  },
  bookIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  borrowIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
  },
  drawer: { height: 'calc(100% - 64px)', top: 64 },
}))
interface Props {
  openOnSlide?: SwiperIndexCatalogue
}

export default function Catalogue({ openOnSlide }: Props) {
  var moment = require('moment')
  const analytics = firebase.analytics()
  const fabClasses = useFabStyles()
  const classes = useStyles()
  const { incrementLoading, decrementLoading } = useContext(LoadingContext)
  const token = getToken()
  const { enqueueSnackbar } = useSnackbar()
  const [catalog, setCatalog] = React.useState<MatosDetails[]>([])
  const [selectedMatos, setSelectedMatos] = React.useState<MatosDetails[]>([])
  const [
    openDialogValidationCart,
    setOpenDialogValidationCart,
  ] = React.useState<boolean>(false)
  const [openDialogBooking, setOpenDialogBooking] = React.useState<boolean>(
    false
  )
  const { notifications } = useContext(NotificationsContext)
  const [
    hasOneMatosAlreadyBorrowed,
    setHasOneMatosAlreadyBorrowed,
  ] = React.useState<boolean>(false)
  const [
    mainMountInSelectedMatos,
    setMainMountInSelectedMatos,
  ] = React.useState<MountTypeEnum>(MountTypeEnum.CANON)
  const [formState, setForm] = React.useState<NewBorrowingOrBookingForm>({
    title: '',
    borrower_log_start: getGivenName(),
    borrower_start: getGivenName(),
    comment_start: '',
    startdate_log: moment().format('yyyy-MM-DDTHH:mm:ss'),
    startdate: moment().format('yyyy-MM-DDTHH:mm:ss'),
    enddate: moment().add(1, 'hours').format('yyyy-MM-DDTHH:mm:ss'),
    status: BorrowingStatus.SORTI,
    matos: selectedMatos,
  })

  React.useEffect(() => {
    setForm({
      ...formState,
      matos: selectedMatos,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatos])
  React.useEffect(() => {
    setHasOneMatosAlreadyBorrowed(false)
    selectedMatos.forEach((item) => {
      if (item.status === MatosStatusEnum.COUV) {
        setHasOneMatosAlreadyBorrowed(true)
      }
    })
    setMainMountInSelectedMatos(findMainMountInSelectedMatos(selectedMatos))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatos])

  function handleRemoveMatos(matosToRemove: MatosDetails, deleteAll?: boolean) {
    if (deleteAll) {
      setSelectedMatos([])
    } else {
      const newSelection = selectedMatos.filter(
        (matos) => matos.id !== matosToRemove.id
      )
      setSelectedMatos(newSelection)
    }
  }

  function handleAddMatos(matosToAdd: MatosDetails) {
    setSelectedMatos([...selectedMatos, matosToAdd])
  }
  React.useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications?.length])

  async function getData() {
    incrementLoading()
    await getCatalogExceptArchived(
      token,
      enqueueSnackbar,
      decrementLoading
    ).then((res) => {
      setCatalog(res)
      decrementLoading()
    })
  }

  function handleStartBorrowing() {
    analytics.logEvent('catalog-createBorrowing-FAB')
    setOpenDialogValidationCart(true)
  }
  function handleStartBoooking() {
    analytics.logEvent('catalog-createBooking-FAB')
    setOpenDialogBooking(true)
  }

  const handleClickCartFAB = () => {
    analytics.logEvent('catalog-open_cart-FAB')
    setIsCartDialogOpened(true)
  }
  const handleClickSearchFAB = () => {
    analytics.logEvent('catalog-open_search-FAB')
    setIsSearchCatalogDialogOpened(true)
  }

  const [isCartDialogOpened, setIsCartDialogOpened] = React.useState<boolean>(
    false
  )
  const [
    isSearchCatalogDialogOpened,
    setIsSearchCatalogDialogOpened,
  ] = React.useState<boolean>(false)

  return (
    <div id="catalog-container" className={classes.root}>
      <CatalogTabs
        catalog={catalog}
        handleAddMatos={handleAddMatos}
        handleRemoveMatos={handleRemoveMatos}
        selectedMatos={selectedMatos}
        openOnSlide={openOnSlide}
        mainMountInSelectedMatos={mainMountInSelectedMatos}
      />
      <Fab
        variant="extended"
        color="secondary"
        aria-label="Recherche"
        className={clsx(
          fabClasses.common,
          fabClasses.firstTop,
          fabClasses.rounded
        )}
        onClick={handleClickSearchFAB}
      >
        <SearchRoundedIcon color={'primary'} />
      </Fab>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="Panier"
        className={clsx(
          fabClasses.common,
          fabClasses.secondTop,
          fabClasses.rounded
        )}
        onClick={handleClickCartFAB}
        disabled={selectedMatos.length === 0}
      >
        <ShoppingBasketIcon color={'primary'} />
      </Fab>
      <Fab
        variant="extended"
        color="primary"
        aria-label="Emprunter"
        className={clsx(fabClasses.common, fabClasses.firstBottom)}
        disabled={hasOneMatosAlreadyBorrowed || selectedMatos.length === 0}
        onClick={handleStartBorrowing}
      >
        <SendIcon className={classes.borrowIcon} />
        <Typography color={'secondary'}> Emprunter </Typography>
      </Fab>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="Emprunter"
        className={clsx(fabClasses.common, fabClasses.secondBottom)}
        onClick={handleStartBoooking}
        disabled={selectedMatos.length === 0}
      >
        <WatchLaterIcon className={classes.bookIcon} />
        <Typography color={'primary'}> Réserver </Typography>
      </Fab>
      {openDialogValidationCart && (
        <DialogValidationCart
          openDialogValidationCart={openDialogValidationCart}
          setOpenDialogValidationCart={setOpenDialogValidationCart}
          selectedMatos={selectedMatos}
          handleRemoveMatos={handleRemoveMatos}
          rerenderParentCallback={getData}
          isBorrowing={openDialogValidationCart}
          formState={formState}
          setForm={setForm}
        />
      )}
      {openDialogBooking && (
        <DialogValidationCart
          openDialogValidationCart={openDialogBooking}
          setOpenDialogValidationCart={setOpenDialogBooking}
          selectedMatos={selectedMatos}
          handleRemoveMatos={handleRemoveMatos}
          rerenderParentCallback={getData}
          isBorrowing={openDialogValidationCart}
          formState={formState}
          setForm={setForm}
        />
      )}
      {isCartDialogOpened && (
        <Dialog
          open={isCartDialogOpened}
          onClose={() => setIsCartDialogOpened(false)}
          className={classes.drawer}
        >
          <CartDialog
            selectedMatos={selectedMatos}
            handleRemoveMatos={handleRemoveMatos}
            handleClose={() => setIsCartDialogOpened(false)}
            actionMainButton={() => setOpenDialogValidationCart(true)}
            setOpenDialogBooking={setOpenDialogBooking}
            hasOneMatosAlreadyBorrowed={hasOneMatosAlreadyBorrowed}
            titleMain={'Emprunter'}
          />
        </Dialog>
      )}
      {isSearchCatalogDialogOpened && (
        <CatalogSearchDialog
          openCatalogSearchDialog={isSearchCatalogDialogOpened}
          handleClose={() => setIsSearchCatalogDialogOpened(false)}
          catalog={catalog}
          handleAddMatos={handleAddMatos}
          handleRemoveMatos={handleRemoveMatos}
          selectedMatos={selectedMatos}
        />
      )}
    </div>
  )
}
