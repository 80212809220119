import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core'
import EventIcon from '@mui/icons-material/Event'
import TodayIcon from '@mui/icons-material/Today'
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker'
import { isSameDay, isValid } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'

interface Props {
  dateRange: [Date, Date]
  handleDateRangeChange: (d: [Date, Date]) => void
  disableFuture: boolean
  allowSameDayRange?: boolean
  disabled?: boolean
  startText?: string
  endText?: string
  onClose?: () => void
}

export const MyDateRangePicker = (props: Props): JSX.Element => {
  const today = useMemo(() => {
    return new Date()
  }, [])

  const [pickerOpen, setPickerOpen] = useState(false)
  const [rangeError, setRangeError] = useState<[boolean, boolean]>([
    false,
    false,
  ])

  const [internalDateRange, setInternalDate] = useState<[Date, Date]>(
    props.dateRange
  )

  const handleDateRangeChange = useCallback(
    (dateRange: DateRange<Date> | null): void => {
      if (
        dateRange &&
        dateRange[0] &&
        dateRange[1] &&
        isValid(dateRange[0]) &&
        isValid(dateRange[1]) &&
        dateRange[0] <= dateRange[1] &&
        // Avoid rendering graph when disablefuture is set
        !(props.disableFuture && dateRange[1] > today)
      ) {
        const validRange = dateRange as [Date, Date]
        setInternalDate(validRange)
        props.handleDateRangeChange(validRange)
        setRangeError([false, false])
      }
    },
    [props, today]
  )

  const onOpen = useCallback(() => setPickerOpen(true), [])
  const onClose = useCallback(() => {
    setPickerOpen(false)
    props.onClose && props.onClose()
  }, [props])

  const onError = useCallback(
    ([startReason]) => {
      if (
        startReason === 'invalidRange' &&
        isSameDay(internalDateRange[0], internalDateRange[1]) &&
        props.allowSameDayRange
      ) {
        setRangeError([false, false])
        return
      } else if (internalDateRange[0] < internalDateRange[1]) {
        setRangeError([false, false])
        return
      }
      setRangeError([true, true])
    },
    [internalDateRange, props.allowSameDayRange]
  )

  const renderInput = useCallback(
    (startProps, endProps): JSX.Element => (
      <Grid
        container
        alignItems="center"
        justify="center"
        color="default"
        spacing={1}
      >
        <Grid item xs={6} md={4} style={{ width: '100vw' }}>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onOpen}>
                    <TodayIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={rangeError[0]}
            {...startProps}
          />
        </Grid>
        <Grid item xs={6} md={4} style={{ width: '100vw' }}>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onOpen}>
                    <EventIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={rangeError[0]}
            {...endProps}
          />
        </Grid>
      </Grid>
    ),
    [onOpen, rangeError]
  )

  return (
    <DateRangePicker
      PopperProps={{ container: document.body }}
      open={pickerOpen}
      disabled={props.disabled}
      startText={props.startText ?? 'Du'}
      endText={props.startText ?? 'Au'}
      disableFuture={props.disableFuture}
      inputFormat={'dd/MM/yyyy'}
      value={internalDateRange}
      onChange={handleDateRangeChange}
      onOpen={onOpen}
      onClose={onClose}
      onError={onError}
      renderInput={renderInput}
      disableCloseOnSelect={false}
      showToolbar={false}
    />
  )
}
