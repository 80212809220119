import React from 'react'
import { useState } from 'react'
import { LoadingContext } from '../../contexts/loading/LoadingContext'

const LoadingProvider = ({ children }) => {
  const incrementLoading = () => {
    toggleLoading((prevState) => {
      return {
        ...prevState,
        loadingCount: prevState.loadingCount + 1,
      }
    })
  }

  const decrementLoading = () => {
    toggleLoading((prevState) => {
      return {
        ...prevState,
        loadingCount:
          prevState.loadingCount > 0 ? prevState.loadingCount - 1 : 0,
      }
    })
  }

  const loadingState = {
    loadingCount: 0,
    incrementLoading,
    decrementLoading,
  }

  const [loading, toggleLoading] = useState(loadingState)

  return (
    <LoadingContext.Provider value={loading}>
      {children}
    </LoadingContext.Provider>
  )
}

export default LoadingProvider
