import 'firebase/analytics'
import firebase from 'firebase/app'
import { Link } from 'react-router-dom'
import { clearAllAuthAsync, getToken } from './Auth'

export const snackBarErrorOrFailToFetch = (
  error: any,
  enqueueSnackbar: any,
  comesFromFunction: string,
  decrementLoading?: Function,
  title?: string
) => {
  const analytics = firebase.analytics()
  const customMessage = 'ERROR FROM FUNCTION '.concat(
    comesFromFunction,
    ' : ',
    error.toString()
  )
  if (
    error.toString().indexOf('Failed to fetch') > -1 ||
    comesFromFunction === 'getVersionBack'
  ) {
    console.error('Failed to fetch')
    analytics.logEvent('err_failed_to_fetch', { text: customMessage })
    enqueueSnackbar('Connexion impossible avec le serveur', {
      variant: 'error',
      preventDuplicate: true,
      key: 'err_failed_to_fetch',
      id: 'err_failed_to_fetch',
      persist: true,
    })
  } else if (
    error.toString().indexOf('Unexpected token F in JSON at position 0') > 0
  ) {
    if (getToken() !== 'null') {
      console.error('SESSION EXPIREE')
      analytics.logEvent('err_session_expired')
      enqueueSnackbar(
        <div>
          <span>Session expirée </span>
          <br />
          <span
            style={{
              cursor: 'pointer',
              fontWeight: 800,
            }}
          >
            <Link
              rel="canonical"
              key="clear"
              onClick={() => {
                clearAllAuthAsync()
                firebase.analytics().logEvent('err_click_session_expired')
                window.location.reload()
              }}
              to=""
            >
              Se reconnecter
            </Link>
          </span>
        </div>,
        {
          variant: 'error',
          preventDuplicate: true,
          key: 'err_session_expired',
          id: 'err_session_expired',
          persist: true,
        }
      )
    }
  } else {
    console.error(customMessage)
    analytics.logEvent('err_generic', { text: customMessage })
    enqueueSnackbar(
      title ? title : 'Une erreur est survenue ('.concat(customMessage, ')'),
      {
        variant: 'error',
      }
    )
  }
  decrementLoading && decrementLoading()
}
