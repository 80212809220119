import { makeStyles, Theme } from '@material-ui/core'

export function upperCaseFirst(str: string) {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
}
export function getAbbreviatedName(borrower: string) {
  return borrower?.split(' ')[0]
}

export function existValueInEnum(type: any, value: any): boolean {
  return (
    Object.keys(type)
      .filter((k) => isNaN(Number(k)))
      .filter((k) => type[k] === value).length > 0
  )
}
export const LIMIT_VARCHAR_COMMENT = 150
export const LIMIT_VARCHAR_PICS = 150
export const LIMIT_VARCHAR = 50
export const appBarHeight = '55px'

export const useFabStyles = makeStyles((theme: Theme) => ({
  common: { position: 'fixed', zIndex: 50, right: theme.spacing(3) },
  rounded: { height: 50, width: 50 },
  firstTop: {
    top: `calc(${appBarHeight} + ${appBarHeight} + 10px)`,
  },
  secondTop: {
    top: `calc(${appBarHeight} + ${appBarHeight} + 70px)`,
  },
  firstBottom: {
    bottom: '20px',
  },
  secondBottom: {
    bottom: '80px',
  },
  primary: { backgroundColor: theme.palette.customButtons.primary.default },
  secondary: { backgroundColor: theme.palette.customButtons.secondary.default },
}))
