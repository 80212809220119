import {
  AppBar,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import BorrowingDialog from '../../components/Borrowings/BorrowingDialog'
import { AreaGraphMatosBorrowed } from '../../components/Metrics/AreaGraphMatosBorrowed'
import { MatosSearchAutocomplete } from '../../components/Metrics/MatosSearchAutocomplete'
import TableForBorrowingRanking from '../../components/Metrics/TableForBorrowingRanking'
import { MyDateRangePicker } from '../../components/Pickers/MyDateRangePicker'
import { LoadingContext } from '../../contexts/loading/LoadingContext'
import { getToken } from '../../utils/Auth'
import { appBarHeight } from '../../utils/various'
import { showTitleAccordingToEnv } from '../../utils/Version'
import { Borrowing } from '../History/utils'
import {
  AllBorrowingsWithIdMatosBetweenDateRange,
  CountMatosHasBeenBorrowed,
  getAllBorrowingsWithIdMatosBetweenDateRange,
  getBorrowedMatosForGraph,
  getMatosBorrowedBetweenDateRange,
  SeriesGraph,
  transformDataBorrowingsPerDayToGraph,
} from './utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightRegular,
      textAlign: 'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    nestedAppBar: {
      marginTop: appBarHeight,
      backgroundColor: theme.palette.background.default,
      alignItems: 'center',
    },
    paper: {
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      minWidth: '96vw',
    },
    root: { backgroundColor: theme.palette.background.default },
    textField: {
      '& #custom-autocomplete-dateRangePicker-label': {
        '&.Mui-focused': {
          color: theme.palette.indicators.isSelected,
        },
      },
    },
  })
)

export default function Metrics() {
  React.useEffect(() => {
    document.title = showTitleAccordingToEnv('Historique')
  }, [])
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()
  const { incrementLoading, decrementLoading } = useContext(LoadingContext)
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')

  const [globaleSeries, setGlobaleSeries] = React.useState<SeriesGraph[]>([])
  const [
    countMatosHasBeenBorrowed,
    setCountMatosHasBeenBorrowed,
  ] = React.useState<CountMatosHasBeenBorrowed[]>([])

  const [dateRange, setDateRange] = React.useState<[Date, Date]>([
    moment().subtract(1, 'months').format('yyyy-MM-DD'),
    moment().format('yyyy-MM-DD'),
  ])
  const [matosSearchBar, setMatosSearchBar] = React.useState<
    CountMatosHasBeenBorrowed[] | null
  >(null)
  const [matosNameSearchBar, setMatosNameSearchBar] = React.useState<
    string[] | null
  >(null)
  const [
    allBorrowingsWithIdMatosBetweenDateRange,
    setAllBorrowingsWithIdMatosBetweenDateRange,
  ] = React.useState<AllBorrowingsWithIdMatosBetweenDateRange[]>([])

  const [
    borrowingToShowInDialog,
    setBorrowingToShowInDialog,
  ] = React.useState<Borrowing | null>(null)
  const [openedDialog, setOpenedDialog] = React.useState<boolean>(false)

  React.useEffect(() => {
    setMatosNameSearchBar(null)
    let array: string[] = []
    matosSearchBar?.forEach((fullMatos) => {
      array.push(fullMatos.name)
    }, setMatosNameSearchBar(array))
  }, [matosSearchBar])

  async function getGlobalData() {
    ///Pour le graphe du nombre total
    await getBorrowedMatosForGraph(
      dateRange,
      getToken(),
      enqueueSnackbar,
      incrementLoading,
      decrementLoading
    ).then((res) => {
      setGlobaleSeries(
        transformDataBorrowingsPerDayToGraph(dateRange, res, decrementLoading)
      )
    })

    ///For the main Tab ranking
    await getMatosBorrowedBetweenDateRange(
      dateRange,
      getToken(),
      enqueueSnackbar,
      incrementLoading,
      decrementLoading
    ).then((res) => {
      setCountMatosHasBeenBorrowed(res)
      decrementLoading()
    })

    ///For the subTab with history of each matos between the date range
    await getAllBorrowingsWithIdMatosBetweenDateRange(
      dateRange,
      getToken(),
      enqueueSnackbar,
      incrementLoading,
      decrementLoading
    ).then((res) => {
      setAllBorrowingsWithIdMatosBetweenDateRange(res)
      decrementLoading()
    })
  }

  React.useEffect(() => {
    getGlobalData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterGraph = (serie) => {
    if (serie.name === 'Matos sortis') {
      return true
    } else {
      if (matosNameSearchBar?.includes(serie.name)) {
        return true
      }
    }
  }

  const onDateRangeChange = (range: [Date, Date]): void => {
    if (range[0] && range[1]) {
      setDateRange(range)
    }
  }

  return (
    <div id="metrics-container" className={classes.root}>
      <AppBar position="fixed" className={classes.nestedAppBar}>
        <Grid
          container
          alignItems="center"
          justify="center"
          color="default"
          spacing={1}
        >
          <Grid item xs={12}>
            <MyDateRangePicker
              dateRange={dateRange}
              disableFuture={false}
              handleDateRangeChange={onDateRangeChange}
              onClose={getGlobalData}
            />
          </Grid>
          <Grid item xs={8}>
            <MatosSearchAutocomplete
              options={countMatosHasBeenBorrowed?.sort(function (a, b) {
                if (a.name < b.name) {
                  return -1
                } else {
                  return 1
                }
              })}
              getOptionLabel={(option) => option.name}
              onChangeFunction={setMatosSearchBar}
              value={matosSearchBar}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="custom-autocomplete-dateRangePicker"
                  autoComplete="off"
                  label="Matos"
                  variant="outlined"
                  className={classes.textField}
                />
              )}
              noOptionsText={
                'Aucun matos sorti entre ces dates ne correspond à ce nom'
              }
            />
          </Grid>
        </Grid>
      </AppBar>

      <Paper className={classes.paper} style={{ marginTop: 160 }} elevation={1}>
        <TableForBorrowingRanking
          countMatosHasBeenBorrowed={countMatosHasBeenBorrowed}
          allBorrowingsWithIdMatosBetweenDateRange={
            allBorrowingsWithIdMatosBetweenDateRange
          }
          matosSearchBar={matosSearchBar}
          setOpenedDialog={setOpenedDialog}
          setBorrowingToShowInDialog={setBorrowingToShowInDialog}
        />
      </Paper>

      <Paper className={classes.paper} elevation={1}>
        <Typography variant="h3" className={classes.header}>
          Matos empruntés
        </Typography>
        <AreaGraphMatosBorrowed
          series={globaleSeries.filter((serie) => {
            return filterGraph(serie)
          })}
        />
      </Paper>

      {openedDialog && borrowingToShowInDialog && (
        <BorrowingDialog
          openDetails={openedDialog}
          originalBorrowing={borrowingToShowInDialog}
          setOpenDetails={setOpenedDialog}
          rerenderParentCallback={() => {
            setOpenedDialog(false)
            getGlobalData()
          }}
        />
      )}
    </div>
  )
}
