import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import React from 'react'
import { AllBorrowingsWithIdMatosBetweenDateRange } from '../../pages/Metrics/utils'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'asc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface HeadCell {
  disablePadding: boolean
  id: keyof AllBorrowingsWithIdMatosBetweenDateRange
  label: string
  numeric: boolean
}

const headCells: HeadCell[] = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'Motif',
  },
  {
    id: 'borrower_start',
    numeric: false,
    disablePadding: false,
    label: 'Sorti par',
  },
  {
    id: 'startdate_log',
    numeric: false,
    disablePadding: false,
    label: 'Sorti le',
  },
  {
    id: 'borrower_log_end',
    numeric: false,
    disablePadding: false,
    label: 'Rendu par',
  },
  { id: 'enddate', numeric: false, disablePadding: false, label: 'Rendu le' },
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof AllBorrowingsWithIdMatosBetweenDateRange
  ) => void
  order: Order
  orderBy: string
  rowCount: number
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = (
    property: keyof AllBorrowingsWithIdMatosBetweenDateRange
  ) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
      width: '85vw',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
)

interface PropsTable {
  allBorrowingsWithIdMatosBetweenDateRange: AllBorrowingsWithIdMatosBetweenDateRange[]
  matosId: number
  setOpenedDialog
  setBorrowingToShowInDialog
}

export default function TableHistoryBorrowingOfOneMatos({
  allBorrowingsWithIdMatosBetweenDateRange,
  matosId,
  setOpenedDialog,
  setBorrowingToShowInDialog,
}: PropsTable) {
  const classes = useStyles()
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<
    keyof AllBorrowingsWithIdMatosBetweenDateRange
  >('startdate_log')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [filteredData, setFilteredData] = React.useState<
    AllBorrowingsWithIdMatosBetweenDateRange[]
  >(allBorrowingsWithIdMatosBetweenDateRange)

  React.useEffect(() => {
    if (matosId) {
      setFilteredData(
        allBorrowingsWithIdMatosBetweenDateRange.filter(
          (elt) => elt.id_matos === matosId
        )
      )
    }
  }, [allBorrowingsWithIdMatosBetweenDateRange, matosId])
  React.useEffect(() => {
    setRowsPerPage(filteredData.length > 5 ? 5 : filteredData.length)
  }, [filteredData])
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AllBorrowingsWithIdMatosBetweenDateRange
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  interface PropsBorrowingDialog {
    row: AllBorrowingsWithIdMatosBetweenDateRange
    index
  }
  function Row({ row, index }: PropsBorrowingDialog) {
    const borrowing = {
      id: row.id_borrowing,
      title: row.title,
      startdate_log: moment(row.startdate_log),
      startdate: moment(row.startdate),
      enddate: moment(row.enddate),
      borrower_log_start: row.borrower_log_start,
      borrower_log_end: row.borrower_log_end,
      borrower_start: row.borrower_start,
      status: row.status,
      comment_start: row.comment_start,
      comment_end: row.comment_end,
    }

    const labelId = `table-${index}`
    return (
      <TableRow
        hover
        onClick={() => {
          setBorrowingToShowInDialog(borrowing)
          setOpenedDialog(true)
        }}
        role="detailsOnLastBorrowings"
        tabIndex={-1}
        key={row.id_borrowing.toString().concat(row.id_matos.toString())}
        style={{ cursor: 'pointer' }}
      >
        <TableCell
          component="th"
          id={labelId}
          scope="row"
          padding="none"
          align="left"
        >
          {row.title}
        </TableCell>
        <TableCell align="left">{row.borrower_start}</TableCell>
        <TableCell align="left">
          {moment(row.startdate_log).format('lll')}
        </TableCell>
        <TableCell align="left">{row.borrower_log_end}</TableCell>
        <TableCell align="left">{moment(row.enddate).format('lll')}</TableCell>
      </TableRow>
    )
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredData.length - page * rowsPerPage)

  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table
          aria-labelledby="TableHistoryBorrowingOfOneMatos"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={filteredData.length}
          />
          <TableBody>
            {stableSort(filteredData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return <Row row={row} index={index} />
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {filteredData.length > 5 && (
          <TablePagination
            rowsPerPageOptions={
              filteredData.length > 5 ? [5, 10, 25] : [filteredData.length]
            }
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} sur ${count}`
            }
            labelRowsPerPage={''}
          />
        )}
      </TableContainer>
    </Paper>
  )
}
