import {
  createStyles,
  FormGroup,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined'
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined'
import React from 'react'
import {
  LabelsForBorrowingCreation,
  NewBorrowingOrBookingForm,
} from '../../../pages/Catalogue/utils'
import { LIMIT_VARCHAR, LIMIT_VARCHAR_COMMENT } from '../../../utils/various'

interface Props {
  formState: NewBorrowingOrBookingForm
  handleChange: (
    key: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  isBorrowing: boolean
  hideSomeLabels?: LabelsForBorrowingCreation
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: '20px',
    },
  })
)

export const TextfieldsForValidationCart = ({
  formState,
  handleChange,
  isBorrowing,
  hideSomeLabels,
}: Props) => {
  var moment = require('moment')
  const classes = useStyles()
  return (
    <FormGroup>
      {!hideSomeLabels?.TITLE && (
        <TextField
          autoComplete="off"
          error={
            formState.title.length === 0 ||
            formState.title.length > LIMIT_VARCHAR
          }
          required
          fullWidth
          id="title"
          variant="outlined"
          autoFocus={formState.title.length > 0 ? false : true}
          label="Titre"
          value={formState.title}
          onChange={(e) => handleChange('title', e)}
          className={classes.input}
          helperText={
            formState.title.length === 0
              ? "M'oublie pas !"
              : formState.title.length > LIMIT_VARCHAR
              ? 'Trop long !'
              : ''
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <InfoOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      {!hideSomeLabels?.FOR_WHO && (
        <TextField
          autoComplete="off"
          error={formState.borrower_start.length === 0}
          required
          fullWidth
          id="borrower_start"
          variant="outlined"
          label="Pour qui"
          value={formState.borrower_start}
          onChange={(e) => handleChange('borrower_start', e)}
          className={classes.input}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PermIdentityOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      {!hideSomeLabels?.START_DATE && (
        <TextField
          autoComplete="off"
          error={
            !moment.isDate(new Date(formState.startdate)) ||
            moment(new Date(formState.startdate))
              .add(5, 'minutes')
              .isBefore(new Date()) ||
            !formState.startdate
          }
          required
          fullWidth
          id="startdate"
          variant="outlined"
          label={isBorrowing ? 'Début' : 'Début prévisionnel'}
          value={moment(formState.startdate).format('yyyy-MM-DDTHH:mm')}
          onChange={(e) => handleChange('startdate', e)}
          className={classes.input}
          type="datetime-local"
          helperText={
            moment(new Date(formState.startdate))
              .add(5, 'minutes')
              .isBefore(new Date()) || !formState.startdate
              ? "C'est dans le passé"
              : ''
          }
        />
      )}
      {!hideSomeLabels?.END_DATE && (
        <TextField
          autoComplete="off"
          error={
            !moment.isDate(new Date(formState.enddate)) ||
            !formState.enddate ||
            moment(new Date(formState.enddate)).isBefore(
              new Date(formState.startdate)
            ) ||
            moment(new Date(formState.enddate))
              .add(5, 'minutes')
              .isBefore(new Date())
          }
          required
          fullWidth
          id="enddate"
          variant="outlined"
          label={isBorrowing ? 'Fin' : 'Fin prévisionnelle'}
          value={moment(formState.enddate).format('yyyy-MM-DDTHH:mm')}
          onChange={(e) => handleChange('enddate', e)}
          className={classes.input}
          type="datetime-local"
          helperText={
            moment(new Date(formState.enddate)).isBefore(
              new Date(formState.startdate)
            )
              ? 'La fin doit être après le début'
              : moment(new Date(formState.enddate))
                  .add(5, 'minutes')
                  .isBefore(new Date())
              ? "C'est dans le passé"
              : ''
          }
        />
      )}
      {!hideSomeLabels?.COMMENT_START && (
        <TextField
          autoComplete="off"
          fullWidth
          id="comment_start"
          variant="outlined"
          label="Commentaire"
          error={formState.comment_start.length > LIMIT_VARCHAR_COMMENT}
          value={formState.comment_start}
          onChange={(e) => handleChange('comment_start', e)}
          className={classes.input}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SmsOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    </FormGroup>
  )
}
