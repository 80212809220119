import { snackBarErrorOrFailToFetch } from '../../utils/SnackBarGenericError'
import { MatosStatusEnum, MatosTypeEnum } from '../Catalogue/utils'
import { BookingStatus, BorrowingStatus } from '../History/utils'

export interface SeriesGraph {
  name: string
  data: [string, number][]
}
export interface MatosInBorrowingsHistoryDetails {
  id: number
  title: string
  startdate_log: string
  startdate: string
  enddate: string
  borrower_log_start: string
  borrower_log_end: string
  borrower_start: string
  status: MatosStatusEnum
  comment_start: string
  comment_end: string
  id_borrowing: number
  id_matos: number
  brand: string
  model: string
  name: string
  mount: string
  capacity: number
  type: MatosTypeEnum
  pics: string
}
export interface NextBookingForMatosDetails {
  id: number
  title: string
  startdate_log: Date
  startdate: Date
  enddate: Date
  borrower_log_start: string
  borrower_log_end: string | null
  borrower_start: string
  status: BookingStatus
  comment_start: string
  comment_end: string | null
  id_booking: number
  id_matos: number
}

export interface CountMatosHasBeenBorrowed {
  counterMatosBorrowed: number
  id: number
  brand: string
  model: string
  name: string
  mount: string
  capacity: number
  type: MatosTypeEnum
  status: MatosStatusEnum
  pics: string
}
export interface AllBorrowingsWithIdMatosBetweenDateRange {
  id: number
  title: string
  startdate_log: string
  startdate: string
  enddate: string
  borrower_log_start: string
  borrower_log_end: string
  borrower_start: string
  status: BorrowingStatus
  comment_start: string
  comment_end: string
  id_borrowing: number
  id_matos: number
}

export interface MetricsGraphRaw {
  count: number
  startdate: string
  id_matos: number
  name: string
}
export function getMatosInBorrowingsHistoryDetails(
  token: string,
  id_matos: number,
  enqueueSnackbar: Function,
  decrementLoading: Function
) {
  return fetch(
    process.env.REACT_APP_URL_BACK +
      'matos/allBorrowings/get?id_matos=' +
      id_matos,
    {
      method: 'GET',
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getMatosInBorrowingsHistoryDetails',
        decrementLoading
      )
    })
}
export function getNextBookingForMatosDetails(
  token: string,
  id_matos: number,
  enqueueSnackbar: Function,
  decrementLoading: Function
) {
  return fetch(
    process.env.REACT_APP_URL_BACK +
      'matos/next_bookings/get?id_matos=' +
      id_matos,
    {
      method: 'GET',
      headers: {
        Authorization: token,
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getNextBookingForMatosDetails',
        decrementLoading
      )
    })
}
export function getBorrowedMatosForGraph(
  dateRange: [Date, Date],
  token: string,
  enqueueSnackbar: Function,
  incrementLoading: Function,
  decrementLoading: Function
) {
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  incrementLoading()
  const url =
    process.env.REACT_APP_URL_BACK +
    'analytics/matos/borrowed_per_day/get?start=' +
    moment(dateRange[0]).format('yyyy-MM-DD') +
    '&end=' +
    moment(dateRange[1]).add(1, 'days').format('yyyy-MM-DD')
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getBorrowedMatosForGraph',
        decrementLoading
      )
    })
}

export function getMatosBorrowedBetweenDateRange(
  dateRange: [Date, Date],
  token: string,
  enqueueSnackbar: Function,
  incrementLoading: Function,
  decrementLoading: Function
) {
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  incrementLoading()
  const url =
    process.env.REACT_APP_URL_BACK +
    'analytics/matos/borrowed_time/get?start=' +
    moment(dateRange[0]).format('yyyy-MM-DD') +
    '&end=' +
    moment(dateRange[1]).add(1, 'days').format('yyyy-MM-DD')
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getMatosBorrowedBetweenDateRange',
        decrementLoading
      )
    })
}

export function getAllBorrowingsWithIdMatosBetweenDateRange(
  dateRange: [Date, Date],
  token: string,
  enqueueSnackbar: Function,
  incrementLoading: Function,
  decrementLoading: Function
) {
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  incrementLoading()
  const url =
    process.env.REACT_APP_URL_BACK +
    'allBorrowingsWithIdMatosBetweenDateRange?start=' +
    moment(dateRange[0]).format('yyyy-MM-DD') +
    '&end=' +
    moment(dateRange[1]).add(1, 'days').format('yyyy-MM-DD')
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getAllBorrowingsWithIdMatosBetweenDateRange',
        decrementLoading
      )
    })
}

export const transformDataBorrowingsPerDayToGraph = (
  dateRange: [Date, Date],
  dataRaw: MetricsGraphRaw[],
  decrementLoading: Function
) => {
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  let data: SeriesGraph[] = []

  ///Tableau vide de dates
  const initDates: [string, number][] = []
  for (
    ///Création du tableau de valeurs avec que des 0
    var d_ = new Date(dateRange[0]);
    d_ <= new Date(moment(dateRange[1]).add(1, 'days'));
    d_.setDate(d_.getDate() + 1)
  ) {
    initDates.push([moment(d_).startOf('day').valueOf(), 0])
  }

  ///Recense tous les matos différents incluent dans la réponse de la requête
  let differentMatos: string[] = ['Matos sortis']
  dataRaw?.forEach((data) => {
    if (!differentMatos.includes(data.name)) {
      differentMatos.push(data.name)
    }
  })

  ///On créé donc le template vide
  differentMatos.forEach((matosName) => {
    data.push({ name: matosName, data: [...initDates] })
  })

  data?.forEach((matosRow) => {
    dataRaw.forEach((rowRaw) => {
      let daysInBetween: number = moment(rowRaw.startdate).diff(
        dateRange[0],
        'days'
      )
      if (rowRaw.name === matosRow.name) {
        matosRow.data[daysInBetween] = [
          moment(rowRaw.startdate).startOf('day').valueOf(),
          rowRaw.count,
        ]
      }
      if (matosRow.name === 'Matos sortis' && matosRow.data[daysInBetween]) {
        let oldCount = matosRow.data[daysInBetween][1]
        matosRow.data[daysInBetween] = [
          moment(rowRaw.startdate).startOf('day').valueOf(),
          oldCount + rowRaw.count,
        ]
      }
    })
  })

  dataRaw?.forEach((rowRaw) => {
    let daysInBetween = moment(rowRaw.startdate).diff(dateRange[0], 'days')
    data[0][daysInBetween] = [
      moment(rowRaw.startdate).startOf('day').valueOf(),
      rowRaw.count,
    ]
  })
  decrementLoading()
  return data
}
