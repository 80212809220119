import {
  Card,
  CardActionArea,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import PersonIcon from '@material-ui/icons/Person'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import classNames from 'classnames'
import 'firebase/analytics'
import firebase from 'firebase/app'
import React, { useMemo } from 'react'
import { Borrowing, BorrowingStatus } from '../../pages/History/utils'
import { getGivenName } from '../../utils/Auth'
import { getAbbreviatedName } from '../../utils/various'
import MyCustomListItem from '../MyCustomListItem'
import BorrowingDialog from './BorrowingDialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 5,
      width: 300,
      height: 65,
      marginBottom: 10,
      marginLeft: 5,
      marginRight: 5,
      boxShadow: `0 8px 40px -12px ${theme.palette.boxShadow.primary}`,
      '&:hover': {
        boxShadow: `0 16px 70px -12.125px ${theme.palette.boxShadow.primary}`,
      },
      backgroundColor: theme.palette.borrowings.default,
      justifyContent: 'center',
      textAlign: 'center',
    },
    cardHeader: {
      marginBottom: -10,
      marginTop: -20,
      color: theme.palette.customText.primary,
      display: 'block',
      overflow: 'hidden',
    },
    rootIfMine: { border: `1px solid ${theme.palette.customText.main}` },
    rootIfLate: { border: `2px solid  ${theme.palette.error.main}` },
    typoEndDateIfLate: { color: theme.palette.error.main },
  })
)

interface Props {
  borrowing: Borrowing
  rerenderParentCallback?: any
}
export default function BorrowingCard({
  borrowing,
  rerenderParentCallback,
}: Props) {
  const classes = useStyles()
  const analytics = firebase.analytics()
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr', {
    relativeTime: {
      past: 'depuis %s',
      s: '1 seconde',
      ss: '%s secondes',
      m: 'une minute',
      mm: '%d minutes',
      h: 'une heure',
      hh: '%d heures',
      d: 'un jour',
      dd: '%d jours',
      M: 'un mois',
      MM: '%d mois',
      y: 'un an',
      yy: '%d ans',
    },
  })

  const [openDetails, setOpenDetails] = React.useState(false)
  const isMine = useMemo(() => {
    return borrowing.borrower_start === getGivenName()
  }, [borrowing])

  const isLate = useMemo(() => {
    return (
      borrowing.status === BorrowingStatus.SORTI &&
      moment(borrowing.enddate).isBefore(new Date())
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [borrowing])
  
  const abreviatedBorrower = useMemo(() => {
    return getAbbreviatedName(borrowing.borrower_start)
  }, [borrowing])

  const handleClickOpenBorrowingDetails = () => {
    analytics.logEvent('borrowingCard-open_borrowingDialog')
    setOpenDetails(!openDetails)
  }
  return (
    <div id={`borrowing-${borrowing.id}`}>
      <Card
        className={classNames(
          classes.root,
          isLate && classes.rootIfLate,
          isMine && classes.rootIfMine
        )}
        onClick={handleClickOpenBorrowingDetails}
      >
        <CardActionArea>
          <CardHeader
            title={
              <Typography noWrap variant="h5" component="h4">
                {borrowing.title}
              </Typography>
            }
            className={classNames(
              classes.cardHeader,
              isLate && classes.typoEndDateIfLate
            )}
          />
          <Grid container direction="column">
            <Grid container>
              <Grid item container xs={5}>
                <MyCustomListItem
                  dense
                  text={abreviatedBorrower}
                  icon={<PersonIcon />}
                  accentuatedColor={isMine}
                  accentuatedColorError={isLate && isMine}
                />
              </Grid>
              <Grid item container xs={7}>
                <MyCustomListItem
                  dense
                  text={
                    borrowing.status === BorrowingStatus.SORTI
                      ? moment(borrowing.startdate).fromNow()
                      : moment(borrowing.enddate).format('L LT')
                  }
                  icon={<QueryBuilderIcon />}
                  accentuatedColorError={isLate}
                  customClassAccentuated={isLate && classes.typoEndDateIfLate}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
      {openDetails && (
        <BorrowingDialog
          openDetails={openDetails}
          originalBorrowing={borrowing}
          setOpenDetails={setOpenDetails}
          rerenderParentCallback={rerenderParentCallback}
        />
      )}
    </div>
  )
}
