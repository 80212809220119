import firebase from 'firebase/app'
import 'firebase/messaging'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import {
  FrontNotificationsRES,
  getLiveFrontNotifs,
} from '../../components/AppBar/utils'
import { LoadingContext } from '../../contexts/loading/LoadingContext'
import { NotificationsContext } from '../../contexts/notifications/NotificationsContext'
import { getFirebaseToken } from '../../firebase'
import {
  defaultNotificationsUserOptions,
  getNotificationOptions,
  NotificationsUserOptions,
} from '../../pages/Notifications/utils'
import { getGivenName, getIsAdmin, getToken } from '../../utils/Auth'

const NotificationsProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { incrementLoading, decrementLoading } = React.useContext(
    LoadingContext
  )
  const [notifications, setNotifications] = useState<FrontNotificationsRES[]>(
    []
  )
  const [
    userPreferences,
    setUserPreferences,
  ] = useState<NotificationsUserOptions>(defaultNotificationsUserOptions)

  const [isFirebaseTokenFound, setFirebaseTokenFound] = React.useState<boolean>(
    true
  )
  const { loadingCount } = React.useContext(LoadingContext)

  const filterNotifs = (notifsRaw) => {
    return notifsRaw?.filter((notif) => {
      if (getIsAdmin()) {
        return true
      } else {
        if (notif.borrower_start === getGivenName()) {
          return true
        } else {
          return false
        }
      }
    })
  }
  const triggerGetDataNotifications = () => {
    getLiveFrontNotifs(getToken(), enqueueSnackbar)
      .then((res: FrontNotificationsRES[]) => {
        setNotifications(
          filterNotifs(
            res.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
          )
        )
      })
      .catch((err) => {
        setNotifications([])
      })
  }
  const triggerFirebaseToken = () => {
    if (firebase.messaging.isSupported()) {
      getFirebaseToken(
        setFirebaseTokenFound,
        incrementLoading,
        decrementLoading,
        enqueueSnackbar
      )
    } else {
      console.log('Firebase not supported')
    }
  }
  const triggerGetUserPreferencesNotifications = () => {
    getNotificationOptions(enqueueSnackbar, decrementLoading)
      .then((res) => {
        let pref = res[0]
        if (res[0].allowPushNotifications === 'true') {
          pref.allowPushNotifications = true
        } else {
          pref.allowPushNotifications = false
        }
        setUserPreferences(pref)
      })
      .catch((err) => {
        setUserPreferences(defaultNotificationsUserOptions)
      })
  }
  React.useEffect(() => {
    ///OnMount
    triggerFirebaseToken()
    triggerGetDataNotifications()
    triggerGetUserPreferencesNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    ///If loading changes
    triggerGetDataNotifications()
    triggerGetUserPreferencesNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCount === 0])
  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        triggerGetDataNotifications,
        isFirebaseTokenFound,
        triggerFirebaseToken,
        userPreferences,
        triggerGetUserPreferencesNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export default NotificationsProvider
