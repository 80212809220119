import { snackBarErrorOrFailToFetch } from '../../utils/SnackBarGenericError'

export interface TransformedDescriptionForEvents {
  details: string
  numberPhotographers: string
  deadline: string | null
  matos: string
  organizer: string
}
export const defaultTransformedDescriptionForEvents = {
  details: '',
  numberPhotographers: '',
  deadline: null,
  matos: '',
  organizer: '',
}
export interface EventFromGoogleCalendar {
  kind: 'calendar#event'
  etag: string
  id: string
  status: string
  htmlLink: string
  created: Date
  updated: Date
  summary: string
  description: string
  location: string
  creator: {
    email: string
  }
  organizer: {
    email: string
    displayName?: DisplayNameCalendars
    self: boolean
  }
  start: {
    dateTime: Date
    timeZone?: string
  }
  end: {
    dateTime: Date
    timeZone?: string
  }
  visibility?: string
  iCalUID: string
  sequence: number
  hangoutLink?: string
  conferenceData?: {
    createRequest: {
      requestId: string
      conferenceSolutionKey: {
        type: string
      }
      status: {
        statusCode: string
      }
    }
    entryPoints: [
      {
        entryPointType: string
        uri: string
        label: string
      }
    ]
    conferenceSolution: {
      key: {
        type: string
      }
      name: string
      iconUri: string
    }
    conferenceId: string
    signature: string
  }
  guestsCanSeeOtherGuests?: boolean
  eventType?: string
  extendedProperties?: { private: { eventAttendeeList: string } }
}
export interface ResponseGoogleAPICalendar {
  kind: 'calendar#events'
  etag: any
  summary: DisplayNameCalendars
  updated: Date
  timeZone: string
  accessRole: string
  defaultReminders
  nextSyncToken: string
  items: EventFromGoogleCalendar[]
}
export enum DisplayNameCalendars {
  PICS_COUV = 'Pics_Couv',
  PICS_PRESTA = 'Pics_Presta',
  PICS_STUDIO = 'Pics_Studio',
  PICS_INTERNE = 'Pics_Interne',
}
export const defaultMainCalendar = {
  Pics_Couv: [],
  Pics_Presta: [],
  Pics_Studio: [],
  Pics_Interne: [],
}
export interface MainCalendar {
  Pics_Couv: EventFromGoogleCalendar[]
  Pics_Presta: EventFromGoogleCalendar[]
  Pics_Studio: EventFromGoogleCalendar[]
  Pics_Interne: EventFromGoogleCalendar[]
}
export interface CalendarChoices {
  Pics_Couv: boolean
  Pics_Presta: boolean
  Pics_Studio: boolean
  Pics_Interne: boolean
}
export const returnStrictTextBetweenStringsForDescription = (
  desc: string,
  index: number
) => {
  ///Obligé pour traduire correctement le champ description des events
  /// Si on change la macro du GoogleSheetDeCouv, ça cassera ici aussi
  return desc?.split(' <b> ')[index]?.replace(/\//g, '')?.replace(/<b>/g, '')
}
export const isPhoneNumber = (phone: string) => {
  const reg = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-s./0-9]*$/g)
  return reg.test(phone)
}
export const isEventOK = (title: string) => {
  const t = title.toLowerCase()
  return {
    boolean: t.indexOf('(ok)') === -1,
    newValue: t.replace(/(\(ok\))/g, ''),
  }
}

export const filterEventsCalendar = (
  calendarChoices: CalendarChoices,
  elt: EventFromGoogleCalendar
) => {
  if (
    calendarChoices.Pics_Couv &&
    elt.organizer.displayName === DisplayNameCalendars.PICS_COUV
  ) {
    return true
  }
  if (
    calendarChoices.Pics_Presta &&
    elt.organizer.displayName === DisplayNameCalendars.PICS_PRESTA
  ) {
    return true
  }
  if (
    calendarChoices.Pics_Studio &&
    elt.organizer.displayName === DisplayNameCalendars.PICS_STUDIO
  ) {
    return true
  }
  if (calendarChoices.Pics_Interne && !elt.organizer.displayName) {
    return true
  }
}

export const getEventsFromGoogleCalendar = (
  linkedEmail: string,
  APIkey: string,
  enqueueSnackbar: Function,
  decrementLoading: Function
) => {
  const url = `https://www.googleapis.com/calendar/v3/calendars/${linkedEmail}/events?key=${APIkey}&maxResults=2500`
  return fetch(url, {
    method: 'GET',
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getEventsFromGoogleCalendar',
        decrementLoading
      )
    })
}

export const getGoogleAPISecretKey = (
  token: string,
  enqueueSnackbar: Function,
  decrementLoading: Function
) => {
  return fetch(process.env.REACT_APP_URL_BACK + 'googleAPISecretKey', {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getGoogleAPISecretKey',
        decrementLoading
      )
    })
}

export async function getAllCalendars(
  incrementLoading,
  decrementLoading,
  enqueueSnackbar,
  googleAPISecretKey,
  setCouv,
  setPresta,
  setStudio,
  setInterne
) {
  const p = process.env
  incrementLoading()
  p.REACT_APP_CALENDAR_ID_COUV &&
    (await getEventsFromGoogleCalendar(
      p.REACT_APP_CALENDAR_ID_COUV,
      googleAPISecretKey,
      enqueueSnackbar,
      decrementLoading
    ).then((res: ResponseGoogleAPICalendar) => {
      setCouv(res?.items)
    }))
  p.REACT_APP_CALENDAR_ID_PRESTA &&
    (await getEventsFromGoogleCalendar(
      p.REACT_APP_CALENDAR_ID_PRESTA,
      googleAPISecretKey,
      enqueueSnackbar,
      decrementLoading
    ).then((res: ResponseGoogleAPICalendar) => {
      setPresta(res?.items)
    }))
  p.REACT_APP_CALENDAR_ID_STUDIO &&
    (await getEventsFromGoogleCalendar(
      p.REACT_APP_CALENDAR_ID_STUDIO,
      googleAPISecretKey,
      enqueueSnackbar,
      decrementLoading
    ).then((res: ResponseGoogleAPICalendar) => {
      setStudio(res?.items)
    }))
  p.REACT_APP_CALENDAR_ID_GENERAL &&
    (await getEventsFromGoogleCalendar(
      p.REACT_APP_CALENDAR_ID_GENERAL,
      googleAPISecretKey,
      enqueueSnackbar,
      decrementLoading
    ).then((res: ResponseGoogleAPICalendar) => {
      setInterne(res?.items)
    }))
  decrementLoading()
}

export async function getAPIKey(
  enqueueSnackbar,
  incrementLoading,
  decrementLoading,
  token,
  setGoogleAPISecretKey
) {
  incrementLoading()
  await getGoogleAPISecretKey(token, enqueueSnackbar, decrementLoading).then(
    (res: { googleAPISecretKey: string }) => {
      setGoogleAPISecretKey(res?.googleAPISecretKey)
      decrementLoading()
    }
  )
}
