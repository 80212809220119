import { createStyles, makeStyles, Theme } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'

interface Props {
  wasToBeBorrowedLessThan30MinAgo: boolean
  shouldBeBorrowedWithin24Hours: boolean
  expectedDateOfBorrowing: Date | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warning: { backgroundColor: theme.palette.warning.main, marginRight: 10 },
  })
)
export default function ChipMatosIsBookedToday({
  wasToBeBorrowedLessThan30MinAgo,
  shouldBeBorrowedWithin24Hours,
  expectedDateOfBorrowing,
}: Props) {
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  const classes = useStyles()

  return expectedDateOfBorrowing &&
    (shouldBeBorrowedWithin24Hours || wasToBeBorrowedLessThan30MinAgo) ? (
    <Chip
      label={
        wasToBeBorrowedLessThan30MinAgo
          ? 'Emprunt en attente'
          : 'Réservé '.concat(moment(expectedDateOfBorrowing).fromNow())
      }
      className={classes.warning}
    />
  ) : (
    <></>
  )
}
