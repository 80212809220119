import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { MatosDetails } from '../../../pages/Catalogue/utils'
import SummaryIndividualCardMatos from '../../Matos/SummaryIndividualCardMatos'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
)

interface Props {
  selectedMatos: MatosDetails[]
  title: string
  handleRemoveMatos: Function
  keepExpanded?: boolean
}
export default function MatosItemsInBorrowAccordeon({
  selectedMatos,
  title,
  handleRemoveMatos,
  keepExpanded,
}: Props) {
  const [expanded, setExpanded] = React.useState<boolean>(
    keepExpanded ? keepExpanded : false
  )
  const handleChange = () => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(!expanded)
  }
  const classes = useStyles()
  const showMatosSelected = () => {
    return (
      <>
        {selectedMatos.map((item: MatosDetails) => {
          return (
            <SummaryIndividualCardMatos
              matos={item}
              canDeleteMatos={true}
              handleRemoveMatos={handleRemoveMatos}
            />
          )
        })}
      </>
    )
  }
  return (
    <div className={classes.root}>
      <Accordion expanded={expanded} onChange={handleChange()}>
        <AccordionSummary
          expandIcon={<VisibilityIcon />}
          aria-controls="panel1a-content"
        >
          <Typography className={classes.heading} variant="h5">
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <span>{showMatosSelected()}</span>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
