import { Dialog, Fab, makeStyles, Theme, Typography } from '@material-ui/core'
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded'
import EditRoundedIcon from '@material-ui/icons/EditRounded'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import clsx from 'clsx'
import 'firebase/analytics'
import firebase from 'firebase/app'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import ChangeMultipleStatus from '../../components/Admin/ChangeMultipleStatusDialog'
import EditOrCreateOneMatosDialog from '../../components/Admin/EditOrCreateOneMatosDialog'
import CartDialog from '../../components/Catalogue/CartDialog'
import CatalogSearchDialog from '../../components/Catalogue/SearchDialog'
import { LoadingContext } from '../../contexts/loading/LoadingContext'
import { getToken } from '../../utils/Auth'
import { useFabStyles } from '../../utils/various'
import { showTitleAccordingToEnv } from '../../utils/Version'
import CatalogTabs from '../Catalogue/CatalogTabs'
import {
  MatosDetails,
  MatosStatusEnum,
  MountTypeEnum,
} from '../Catalogue/utils'
import {
  AdminDialog,
  defaultAdminDialog,
  getCatalogAdmin,
  getMaxIdMatosAdmin,
  templateNewMatos,
} from './utils'
const useStyles = makeStyles((theme: Theme) => ({
  icon: { paddingRight: '5px' },
  drawer: { height: 'calc(100% - 64px)', top: 64 },
}))
export default function Admin() {
  React.useEffect(() => {
    document.title = showTitleAccordingToEnv('Admin')
  }, [])
  const analytics = firebase.analytics()
  const { loadingCount, incrementLoading, decrementLoading } = useContext(
    LoadingContext
  )
  const token = getToken()
  const classes = useStyles()
  const fabClasses = useFabStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [catalog, setCatalog] = React.useState<MatosDetails[]>([])
  const [selectedMatos, setSelectedMatos] = React.useState<MatosDetails[]>([])
  const [
    localTemplateNewMatos,
    setLocalTemplateNewMatos,
  ] = React.useState<MatosDetails>(templateNewMatos)
  const [openedDialog, setOpenedDialog] = React.useState<AdminDialog>(
    defaultAdminDialog
  )

  const handleChange = (name, value: boolean, fromFAB?: boolean) => {
    fromFAB
      ? analytics.logEvent('admin-'.concat(name, '-FAB'))
      : analytics.logEvent('admin-change_'.concat(name))
    setOpenedDialog({ ...openedDialog, [name]: value })
  }

  const rerender = () => {
    getData()
    setSelectedMatos([])
    setOpenedDialog(defaultAdminDialog)
  }
  function handleRemoveMatos(matosToRemove: MatosDetails, deleteAll?: boolean) {
    if (deleteAll) {
      setSelectedMatos([])
    } else {
      const newSelection = selectedMatos.filter(
        (matos) => matos.id !== matosToRemove.id
      )
      setSelectedMatos(newSelection)
    }
  }

  function handleAddMatos(matosToAdd: MatosDetails) {
    setSelectedMatos([...selectedMatos, matosToAdd])
  }
  React.useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [
    hasOneMatosAlreadyBorrowed,
    setHasOneMatosAlreadyBorrowed,
  ] = React.useState<boolean>(false)

  React.useEffect(() => {
    setHasOneMatosAlreadyBorrowed(false)
    selectedMatos.forEach((item) => {
      if (item.status === MatosStatusEnum.COUV) {
        setHasOneMatosAlreadyBorrowed(true)
      }
    })
  }, [selectedMatos])
  async function getData() {
    incrementLoading()
    await getCatalogAdmin(token, enqueueSnackbar, decrementLoading)
      .then((res) => {
        setCatalog(res)
      })
      .then(async () => {
        await getMaxIdMatosAdmin(token, enqueueSnackbar, decrementLoading).then(
          (res) => {
            res &&
              res[0]['MAX(id)'] > 0 &&
              setLocalTemplateNewMatos({
                ...localTemplateNewMatos,
                id: res[0]['MAX(id)'] + 1,
              })
            decrementLoading()
          }
        )
      })
  }

  function selectAllMatosFromCatalog(value: boolean) {
    incrementLoading()
    if (value === true) {
      setSelectedMatos(
        catalog.filter((matos) => matos.status !== MatosStatusEnum.COUV)
      )
      decrementLoading()
    } else {
      setSelectedMatos([])
      decrementLoading()
    }
  }

  return (
    <div id="admin-page">
      <CatalogTabs
        catalog={catalog}
        handleAddMatos={handleAddMatos}
        handleRemoveMatos={handleRemoveMatos}
        selectedMatos={selectedMatos}
        isAdmin={true}
        mainMountInSelectedMatos={MountTypeEnum.NA}
      />

      <Fab
        variant="extended"
        color="secondary"
        aria-label="Recherche"
        className={clsx(
          fabClasses.common,
          fabClasses.firstTop,
          fabClasses.rounded
        )}
        onClick={() => {
          handleChange('searchAdmin', true, true)
        }}
        disabled={loadingCount > 0}
      >
        <SearchRoundedIcon color={'primary'} />
      </Fab>
      <Fab
        variant="extended"
        color="secondary"
        aria-label="Panier"
        className={clsx(
          fabClasses.common,
          fabClasses.secondTop,
          fabClasses.rounded
        )}
        onClick={() => {
          handleChange('cart', true, true)
        }}
        disabled={selectedMatos.length === 0}
      >
        <ShoppingBasketIcon color={'primary'} />
      </Fab>

      <Fab
        variant="extended"
        color="secondary"
        aria-label="Change"
        className={clsx(fabClasses.common, fabClasses.secondBottom)}
        onClick={() => {
          selectedMatos.length === 1
            ? handleChange('changeOneMatos', true, true)
            : handleChange('changeMultipleStatus', true, true)
        }}
        disabled={
          loadingCount > 0 ||
          selectedMatos.length === 0 ||
          hasOneMatosAlreadyBorrowed
        }
      >
        <EditRoundedIcon className={classes.icon} color={'primary'} />
        <Typography color={'primary'}>
          {selectedMatos.length === 1
            ? ' Modifier'
            : ' Modifier tous les statuts'}
        </Typography>
      </Fab>

      <Fab
        variant="extended"
        color="primary"
        aria-label="Panier"
        className={clsx(fabClasses.common, fabClasses.firstBottom)}
        onClick={() => {
          handleChange('createNewMatos', true, true)
        }}
        disabled={loadingCount > 0}
      >
        <AddCircleRoundedIcon className={classes.icon} />
        <Typography color={'secondary'}> Ajouter du matos</Typography>
      </Fab>

      <EditOrCreateOneMatosDialog
        matos={selectedMatos[0]}
        handleClose={() => handleChange('changeOneMatos', false)}
        openDialogChangeOneMatos={openedDialog.changeOneMatos}
        rerenderParentCallback={rerender}
      />
      <EditOrCreateOneMatosDialog
        matos={localTemplateNewMatos}
        handleClose={() => handleChange('createNewMatos', false)}
        openDialogChangeOneMatos={openedDialog.createNewMatos}
        rerenderParentCallback={rerender}
        createNewMatos={true}
      />
      <ChangeMultipleStatus
        matos={selectedMatos}
        handleClose={() => {
          handleChange('changeMultipleStatus', false)
        }}
        openDialogChangeMultipleStatus={openedDialog.changeMultipleStatus}
        rerenderParentCallback={rerender}
      />
      <Dialog
        open={openedDialog.cart}
        onClose={() => handleChange('cart', false)}
        className={classes.drawer}
      >
        <CartDialog
          selectedMatos={selectedMatos}
          handleRemoveMatos={handleRemoveMatos}
          handleClose={() => handleChange('cart', false)}
          actionMainButton={() => {
            selectedMatos.length === 1
              ? handleChange('changeOneMatos', true)
              : handleChange('changeMultipleStatus', true)
          }}
          setOpenDialogBooking={() => alert('OUPS')}
          hasOneMatosAlreadyBorrowed={hasOneMatosAlreadyBorrowed}
          isAdminAccess={true}
          titleMain={'Modifier'}
          selectAllMatosFromCatalog={selectAllMatosFromCatalog}
        />
      </Dialog>
      <CatalogSearchDialog
        openCatalogSearchDialog={openedDialog.searchAdmin}
        handleClose={() => handleChange('searchAdmin', false)}
        catalog={catalog}
        handleAddMatos={handleAddMatos}
        handleRemoveMatos={handleRemoveMatos}
        selectedMatos={selectedMatos}
      />
    </div>
  )
}
