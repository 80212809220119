import Autocomplete from '@material-ui/lab/Autocomplete'

//TODO Typer ça ow!
interface Props {
  options
  getOptionLabel: (option: any) => string
  onChangeFunction
  value
  renderInput
  noOptionsText
}

export const MatosSearchAutocomplete = ({
  options,
  getOptionLabel,
  onChangeFunction,
  value,
  renderInput,
  noOptionsText,
}: Props) => {
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  return (
    <Autocomplete
      id="custom-autocomplete-dateRangePicker"
      multiple
      limitTags={2}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={(e, value) => {
        onChangeFunction(value)
      }}
      value={value ? value : []}
      renderInput={renderInput}
      noOptionsText={noOptionsText}
      fullWidth
      size={'small'}
      style={{ marginBottom: 10 }}
    />
  )
}
