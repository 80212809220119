import { Grid } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import React from 'react'
import {
  MatosDetailInBorrowingOrBooking,
  MatosDetails,
  MatosLive,
  MatosStatusEnum,
} from '../../pages/Catalogue/utils'
import ChipMatosStatus from './ChipMatosStatus'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 5,
    minWidth: 250,
    width: '100%',
    height: 75,
    boxShadow: `0 1px 10px -2px ${theme.palette.boxShadow.primary}`,
    '&:hover': {
      boxShadow: `0 16px 70px -12.125px ${theme.palette.boxShadow.primary}`,
    },
  },
  media: {
    height: '100%',
  },
}))

interface Props {
  matos: MatosDetails | null
  matosDetailInBorrowingOrBooking?: MatosDetailInBorrowingOrBooking
  handleRemoveMatos?: Function
  canDeleteMatos?: boolean
  matosLive?: MatosLive[]
}
///Component qui montre le matos dans les résumé de matos emprunté et le panier
export default function SummaryIndividualCardMatos({
  matos,
  matosDetailInBorrowingOrBooking,
  handleRemoveMatos,
  canDeleteMatos,
  matosLive,
}: Props) {
  const classes = useStyles()
  const [thisMatosIsBorrowed, setThisMatosIsBorrowed] = React.useState<boolean>(
    false
  )

  React.useEffect(() => {
    matosLive &&
      matosDetailInBorrowingOrBooking &&
      matosLive.forEach((live) => {
        if (live.id === matosDetailInBorrowingOrBooking.id_matos) {
          setThisMatosIsBorrowed(true)
        }
      })
  }, [matosDetailInBorrowingOrBooking, matosLive])

  const m = matosDetailInBorrowingOrBooking
    ? matosDetailInBorrowingOrBooking
    : matos
    ? matos
    : { id: 0, pics: '', name: '' }

  return (
    <Card className={classes.root}>
      <Grid container direction="row" key={m.id}>
        <Grid item xs={canDeleteMatos ? 4 : 5}>
          <CardMedia className={classes.media} image={m.pics} title={m.pics} />
        </Grid>
        <Grid item xs={canDeleteMatos ? 6 : 7}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {m.name}
              {thisMatosIsBorrowed && (
                <ChipMatosStatus
                  status={MatosStatusEnum.COUV}
                  dontShowLabel={true}
                />
              )}
            </Typography>
          </CardContent>
        </Grid>
        {canDeleteMatos && (
          <Grid item xs={2}>
            <CardActionArea
              onClick={() => {
                handleRemoveMatos && handleRemoveMatos(m)
              }}
            >
              <DeleteIcon />
            </CardActionArea>
          </Grid>
        )}
      </Grid>
    </Card>
  )
}
