import { snackBarErrorOrFailToFetch } from './SnackBarGenericError'

export const defaultValueToken = 'null'
//TODO NOT SAFE DE STOCKER LE TOKEN EN CLAIR
//NE PAS UTILISER les ASYNCSTORAGES MAIS VIA LES COOKIES
export function getToken() {
  /// TODO caca
  let userToken: string = defaultValueToken
  const tokenString = localStorage.getItem('token')
  if (tokenString) {
    userToken = JSON.parse(tokenString)
  }
  return userToken
}
export function getGivenName() {
  let givenName = localStorage.getItem('givenName')
  if (givenName && givenName.length > 0) {
    return givenName
  } else {
    clearAllAuthAsync()
    return ''
  }
}

export function clearAllAuthAsync() {
  const isDarkMode = getDarkModeLocalStorage()
  localStorage.clear()
  setDarkModeLocalStorage(isDarkMode)
}
const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value)
    })
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key)
    })
  },
}
export function setToken(userToken: string) {
  asyncLocalStorage
    .setItem('token', JSON.stringify(userToken))
    .then(function () {
      return asyncLocalStorage.getItem('token')
    })
    .then(function (value) {
      window.location.replace('/')
    })
}
export function setGivenName(givenName: string) {
  return localStorage.setItem(
    'givenName',
    givenName.replace('["', '').replace('"]', '')
  )
}
export function setAdmin(groups) {
  if (groups.indexOf('Matos') > -1) {
    return localStorage.setItem('admin', 'true')
  }
}
export function getIsAdmin() {
  let admin = localStorage.getItem('admin')

  if (admin) {
    if (admin.indexOf('true') > -1) {
      return true
    }
  }
  return false
}
export function setDarkModeLocalStorage(value: boolean | undefined) {
  let newValue: boolean = false
  if (value && value === true) {
    newValue = true
  }
  return localStorage.setItem('darkMode', newValue.toString())
}

export function getDarkModeLocalStorage() {
  let isDarkMode = localStorage.getItem('darkMode')
  if (isDarkMode) {
    if (isDarkMode.includes('true')) {
      return true
    } else return false
  }
  return undefined
}

export function validateToken(userToken: string, enqueueSnackbar: Function) {
  if (!process.env.REACT_APP_URL_BACK) {
    throw new Error('REACT_APP_URL_BACK manquant dans .env')
  }
  return fetch(process.env.REACT_APP_URL_BACK, {
    method: 'GET',
    headers: {
      Authorization: userToken,
    },
  })
    .then(function (response) {
      if (response.status === 200) {
        return true
      }
      if (response.status === 403) {
        return false
      } else {
        enqueueSnackbar(
          'Une erreur '.concat(response.status.toString(), " s'est produite"),
          {
            variant: 'error',
          }
        )
        return false
      }
    })
    .catch((err) => {
      snackBarErrorOrFailToFetch(err, enqueueSnackbar, 'validateToken')
      return false
    })
}
