import React, { useContext } from 'react'
import { DataGrid, GridColDef, GridSortDirection } from '@material-ui/data-grid'
import {
  getHistoryPushNotificationsSent,
  HistoryPushNotificationsSentInterface,
} from './utils'
import { LoadingContext } from '../../contexts/loading/LoadingContext'
import { useSnackbar } from 'notistack'
import { makeStyles, Theme } from '@material-ui/core'

const columns: GridColDef[] = [
  { field: 'title', headerName: 'Titre', flex: 2 },
  { field: 'message', headerName: 'Message', flex: 4 },
  { field: 'sentTo', headerName: 'Envoyée à', flex: 2 },
  { field: 'sentAt', headerName: 'Envoyée le (UTC)', flex: 2 },
]

const useStyles = makeStyles((theme: Theme) => ({
  dataGrid: {
    padding: theme.spacing(2),
  },
}))
export default function HistoryPushNotificationsSent() {
  const classes = useStyles()

  const [rows, setRows] = React.useState<
    Readonly<HistoryPushNotificationsSentInterface[]>
  >([])
  const { enqueueSnackbar } = useSnackbar()
  const { incrementLoading, decrementLoading } = useContext(LoadingContext)
  const getData = () => {
    incrementLoading()
    getHistoryPushNotificationsSent(enqueueSnackbar, decrementLoading).then(
      (res) => {
        setRows(res)
        decrementLoading()
      }
    )
  }
  React.useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div style={{ height: 680, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        className={classes.dataGrid}
        sortModel={[
          {
            field: 'sentAt',
            sort: 'desc' as GridSortDirection,
          },
        ]}
        pageSize={10}
        rowsPerPageOptions={[10, 20, 50]}
      />
    </div>
  )
}
