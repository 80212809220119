import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import VisibilityIcon from '@material-ui/icons/Visibility'
import WarningIcon from '@material-ui/icons/Warning'
import 'firebase/analytics'
import firebase from 'firebase/app'
import React from 'react'
import {
  MatosDetailInBorrowingOrBooking,
  MatosLive,
} from '../../pages/Catalogue/utils'
import SummaryIndividualCardMatos from '../Matos/SummaryIndividualCardMatos'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    warning: { color: theme.palette.warning.main },
  })
)

interface Props {
  matosInBooking: MatosDetailInBorrowingOrBooking[] | undefined
  isConflicted: boolean
  matosLive: MatosLive[] | undefined
}
export default function MatosItemsInBookingAccordeon({
  matosInBooking,
  isConflicted,
  matosLive,
}: Props) {
  const analytics = firebase.analytics()
  const classes = useStyles()
  const ShowMatosSelected = () => {
    return (
      <>
        {matosInBooking?.map((item) => {
          return (
            <SummaryIndividualCardMatos
              matos={null}
              matosDetailInBorrowingOrBooking={item}
              canDeleteMatos={false}
              matosLive={matosLive}
            />
          )
        })}
      </>
    )
  }
  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<VisibilityIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={() => {
            analytics.logEvent('bookingCard-open_accordeon')
          }}
        >
          {isConflicted && <WarningIcon className={classes.warning} />}
          {isConflicted ? (
            <Typography
              style={{ wordWrap: 'break-word', textAlign: 'center' }}
              className={classes.warning}
            >
              Conflit avec les emprunts actuels
            </Typography>
          ) : (
            <Typography className={classes.heading}>Voir le matos</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ShowMatosSelected />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
