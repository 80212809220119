import { Theme } from '@material-ui/core'
import { cloneDeep, merge } from 'lodash'
import React from 'react'

interface EventsPalette {
  default: React.CSSProperties['color']
  PICS_PRESTA: React.CSSProperties['color']
  PICS_COUV: React.CSSProperties['color']
  PICS_STUDIO: React.CSSProperties['color']
}
interface BorrowingsPalette {
  default: React.CSSProperties['color']
}
interface SwitchThemePalette {
  backgroundColorLight: React.CSSProperties['color']
  backgroundColorDark: React.CSSProperties['color']
}
interface BookingsPalette {
  default: React.CSSProperties['color']
}
interface MatosPalette {
  card: MatosCardPalette
  labelStatus: MatosStatusPalette
  backgroundColorStatus: MatosStatusPalette
}
interface IndicatorsPalette {
  default: React.CSSProperties['color']
  isSelected: React.CSSProperties['color']
}
interface CustomButtonPalette {
  default: React.CSSProperties['color']
  hover: React.CSSProperties['color']
}

interface MatosStatusPalette {
  Archived: React.CSSProperties['color']
  Indisponible: React.CSSProperties['color']
  Local: React.CSSProperties['color']
  Couv: React.CSSProperties['color']
}

interface MatosCardPalette {
  default: React.CSSProperties['color']
  selected: React.CSSProperties['color']
  disabled: React.CSSProperties['color']
}
interface CustomTextPalette {
  main: React.CSSProperties['color']
  primary: React.CSSProperties['color']
  secondary: React.CSSProperties['color']
}
interface CustomButtons {
  main: CustomButtonPalette
  primary: CustomButtonPalette
  secondary: CustomButtonPalette
  cancel: CustomButtonPalette
}
interface BoxShadowPalette {
  primary: React.CSSProperties['color']
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    events: EventsPalette
    customButtons: CustomButtons
    borrowings: BorrowingsPalette
    bookings: BookingsPalette
    indicators: IndicatorsPalette
    matos: MatosPalette
    customText: CustomTextPalette
    switchTheme: SwitchThemePalette
    boxShadow: BoxShadowPalette
  }
}

const common = {
  palette: {
    common: { black: '#000', white: '#FFF' },
    primary: {
      main: '#0A0A2A',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#F6F6F6',
      contrastText: '#FFF',
    },
    error: {
      main: '#d90429',
      light: '#FCE7E7',
      dark: '#C62828',
      contrastText: '#FFF',
    },
    warning: {
      main: '#FF8F1F',
      light: '#FF8F1F',
      dark: '#FF8F1F',
      contrastText: '#FFF',
    },
    info: {
      main: '#0A0A2A',
      light: '#0A0A2A',
      dark: '#0A0A2A',
      contrastText: '#FFF',
    },
    success: {
      main: '#02c39a',
      light: '#ECF9F3',
      dark: '#49D583',
      contrastText: '#FFF',
    },
    background: {
      default: '#aab4be',
    },
    /*  https://coolors.co/6fc9cf-eb886f-d6b885-b4c794-9d9cbf-cc8ebe */
    events: {
      default: '#B4C794',
      PICS_PRESTA: '#D6B885',
      PICS_COUV: '#EB886F',
      PICS_STUDIO: '#6FC9CF',
    },
    bookings: { default: '#BF8D8A' },
    borrowings: { default: '#9D9CBF' },
    matos: {
      labelStatus: {
        Archived: 'white',
        Indisponible: '#DE3F3F',
        Local: '#1F5E6F',
        Couv: '#246B7F',
      },
      backgroundColorStatus: {
        Archived: '#474747',
        Indisponible: '#F9DCDC',
        Local: '#d6fadf',
        Couv: '#DFF1F6',
      },
    },
    switchTheme: {
      backgroundColorLight: '#aab4be',
      backgroundColorDark: '#596673',
    },
    boxShadow: { primary: 'rgba(0,0,0,.3)' },
  },
}

const light = {
  palette: {
    type: 'light',
    customButtons: {
      main: { default: '#F5F5F5', hover: '#E0E0E0' },
      primary: { default: '#0a0a2a', hover: '#2727A5' },
      secondary: { default: '#ebebeb', hover: '#d6d6d6' },
    },
    background: { paper: '#FFF', default: '#ECECEC' },
    indicators: { default: '#bbbbbb', isSelected: '#0a0a2a' },
    matos: {
      card: { default: '#F5F5F5', selected: '#ECF5FE', disabled: '#E0E0E0' },
    },
    customText: {
      main: '#000',
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&$error': {
          '& $notchedOutline': {
            borderColor: '#E42525',
          },
        },
        '&$focused $notchedOutline': {
          borderColor: '#0a0a2a',
        },
      },
      adornedEnd: {
        '&$error': {
          color: '#E42525',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$error': {
          color: '#E42525',
        },
        '&$focused': {
          color: '#0a0a2a',
        },
      },
    },
  },
}

const dark = {
  palette: {
    type: 'dark',
    customButtons: {
      main: { default: '#1F1F1F', hover: '#474747' },
      primary: { default: '#2727A5', hover: '#1690F3' },
      secondary: { default: '#E0E0E0', hover: '#CCCCCC' },
    },
    background: { paper: '#424242', default: '#303030' },
    indicators: { default: '#bbbbbb', isSelected: '#1690F3' },
    matos: {
      card: { default: '#1F1F1F', selected: '#333344', disabled: '#474747' },
    },
    customText: {
      main: '#fff',
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
  },
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: '#151515',
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: '#ccc',
        '$checked$checked + &': {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: '#1690F3',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$error': {
          '& $notchedOutline': {
            borderColor: '#E42525',
          },
        },
        '&$focused $notchedOutline': {
          borderColor: '#1690F3',
        },
      },
      adornedEnd: {
        '&$error': {
          color: '#E42525',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$error': {
          color: '#E42525',
        },
        '&$focused': {
          color: '#1690F3',
        },
      },
    },
    MuiFab: { primary: { backgroundColor: '#1690F3' } },
  },
}
export const darkTheme = merge(cloneDeep(common), dark) as Theme

export const lightTheme = merge(cloneDeep(common), light) as Theme
