import Chip from '@material-ui/core/Chip'
import { MatosStatusEnum } from '../../pages/Catalogue/utils'
import { useStylesForMatosChip } from './ChipMatosStatus'

interface Props {
  borrower_start: string
  status: MatosStatusEnum
}

export default function ChipBorrowerIfInCouv({
  borrower_start,
  status,
}: Props) {
  const classes = useStylesForMatosChip()
  return (
    <Chip
      label={borrower_start}
      className={classes[status]}
      style={{
        marginRight: 10,
      }}
    ></Chip>
  )
}
