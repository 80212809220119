import {
  AppBar as AppBarMaterialUI,
  Badge,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import EventIcon from '@material-ui/icons/Event'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import HistoryTwoToneIcon from '@material-ui/icons/HistoryTwoTone'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsIcon from '@material-ui/icons/Notifications'
import NotificationsPausedIcon from '@material-ui/icons/NotificationsPaused'
import SettingsIcon from '@material-ui/icons/Settings'
import TimelineIcon from '@material-ui/icons/Timeline'
import 'firebase/analytics'
import firebase from 'firebase/app'
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { LoadingContext } from '../../contexts/loading/LoadingContext'
import { NotificationsContext } from '../../contexts/notifications/NotificationsContext'
import { darkTheme, lightTheme } from '../../theme'
import { clearAllAuthAsync, getIsAdmin } from '../../utils/Auth'
import { IS_MOBILE_MODE_SIZE } from '../../utils/Front'
import { appBarHeight } from '../../utils/various'
import { ThemeModeSwitch } from '../ThemeModeSwitch'
import PopoverNotification from './NotificationsPopover'
import { conditionsAndColorForNotifsIcon } from './NotificationsPopover/DisclaimerNotifications'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: `${appBarHeight}`,
      backgroundColor: theme.palette.background.paper,
    },
    mobileMenuIcon: {
      position: 'absolute',
      left: '10%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    mobileLogoIcon: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    mobileNotifIcon: {
      position: 'absolute',
      left: '80%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    mobileLogOutIcon: {
      position: 'absolute',
      left: '90%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    desktopLogoIcon: {
      position: 'absolute',
      left: '75px',
      top: '25px',
      transform: 'translate(-50%, -50%)',
    },
    desktopNotifIcon: {
      position: 'absolute',
      right: '100px',
      top: '25px',
      transform: 'translate(-50%, -50%)',
    },
    desktopThemeSwitch: {
      position: 'absolute',
      right: '125px',
      top: '25px',
      transform: 'translate(-50%, -50%)',
    },
    desktopLogOutIcon: {
      position: 'absolute',
      right: '25px',
      top: '25px',
      transform: 'translate(-50%, -50%)',
    },
    desktopTabs: {
      position: 'absolute',
      left: '50%',
      top: '25px',
      transform: 'translate(-50%, -50%)',
    },
    burger: {
      width: 250,
    },
    themeSwitch: { marginLeft: theme.spacing(1) },
    indicator: {
      backgroundColor: theme.palette.indicators.isSelected,
      height: '10px',
      top: '45px',
    },
  })
)

const useActiveTab = (): string | boolean => {
  const location = useLocation()
  const root = location.pathname.split('/')[1]
  const tab = '/' + root
  return tab === '/' ? false : tab
}
enum Devices {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}
interface Props {
  darkMode: boolean | undefined
  setDarkMode: Dispatch<SetStateAction<boolean | undefined>>
}
const AppBar = ({ darkMode, setDarkMode }: Props): JSX.Element => {
  const analytics = firebase.analytics()
  var moment = require('moment')
  require('moment/min/locales.min')
  moment.locale('fr')
  const history = useHistory()
  const classes = useStyles()
  const activeTab = useActiveTab()
  const theme = useTheme()
  const isAdmin = getIsAdmin()
  const { loadingCount } = useContext(LoadingContext)
  const isMobileMode = useMediaQuery(
    theme.breakpoints.down(IS_MOBILE_MODE_SIZE)
  )
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false)
  const handleCloseMenuDrawer = (): void => setMenuDrawerOpen(false)
  const [
    anchorElNotif,
    setAnchorElNotif,
  ] = React.useState<HTMLButtonElement | null>(null)
  const {
    notifications,
    triggerGetDataNotifications,
    isFirebaseTokenFound,
    userPreferences,
  } = useContext(NotificationsContext)

  const handleLogOut = (device: Devices) => {
    analytics.logEvent(`appbar-logout-${device}`)
    clearAllAuthAsync()
    window.location.reload()
  }

  const handleSwitchTheme = (value: boolean) => {
    setDarkMode(value)
  }

  const handleNotificationIconClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    analytics.logEvent('appbar-notifications')
    triggerGetDataNotifications()
    setAnchorElNotif(event.currentTarget)
  }

  const handleClickTabs = (device: Devices, path: string) => {
    analytics.logEvent(`appbar-${device}-go_to-${path}`)
    history.push(path)
  }

  const getTabs = (): JSX.Element[] => {
    const adminTab = (
      <Tab
        disableRipple
        key="admin"
        label="Admin"
        component={Link}
        value="/admin"
        to="/admin"
      />
    )
    const tabs = [
      <Tab
        disableRipple
        key="catalog"
        label="Catalogue"
        component={Link}
        value="/catalogue"
        to="/catalogue"
      />,
      <Tab
        disableRipple
        key="emprunts"
        label="Emprunts"
        component={Link}
        value="/emprunts"
        to="/emprunts/sortis"
      />,

      <Tab
        disableRipple
        key="calendar"
        label="Planning"
        component={Link}
        value="/planning"
        to="/planning"
      />,
      <Tab
        disableRipple
        key="metrics"
        label="Historique"
        component={Link}
        value="/historique"
        to="/historique"
      />,
    ]

    return isAdmin ? tabs.concat(adminTab) : tabs
  }
  const logoURL = useMemo(() => {
    return `https://storage.galerie.pics/appli/logo_banniere${
      darkMode ? '_white.png' : '.png'
    }`
  }, [darkMode])
  const LogoBanniere = () => {
    return (
      <Link to="/" className={'box'}>
        {loadingCount > 0 && (
          <>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </>
        )}
        <img alt="ApPics" src={logoURL} height={40} />
      </Link>
    )
  }
  const NotifButton = () => {
    const textAndColor = conditionsAndColorForNotifsIcon(
      isFirebaseTokenFound,
      userPreferences,
      darkMode ? lightTheme : darkTheme
    )
    return (
      <Badge
        badgeContent={notifications?.length}
        invisible={notifications?.length === 0}
        color="primary"
      >
        {textAndColor.text.length === 0 ? (
          <NotificationsIcon />
        ) : (
          <NotificationsPausedIcon
            style={{
              color: textAndColor.color,
            }}
          />
        )}
      </Badge>
    )
  }

  const MobileDrawer = () => {
    return (
      <Drawer
        anchor="left"
        open={menuDrawerOpen}
        onClose={handleCloseMenuDrawer}
      >
        <Grid container alignContent="center" justify="center">
          <Link
            rel="canonical"
            to="/"
            onClick={() => analytics.logEvent('appbar-mobile_logo')}
          >
            <img alt="ApPics" src={logoURL} height={40} />
          </Link>
        </Grid>
        <Divider />
        <div
          className={classes.burger}
          role="presentation"
          onClick={() => setMenuDrawerOpen(false)}
          onKeyDown={() => setMenuDrawerOpen(false)}
        >
          <List>
            <ListItem
              button
              key={'Catalogue'}
              onClick={() => handleClickTabs(Devices.MOBILE, '/catalogue')}
            >
              <ListItemIcon>
                <CameraAltIcon />
              </ListItemIcon>
              <ListItemText primary={'Catalogue'} />
            </ListItem>
            <ListItem
              button
              key={'emprunts'}
              onClick={() =>
                handleClickTabs(Devices.MOBILE, '/emprunts/sortis')
              }
            >
              <ListItemIcon>
                <HistoryTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary={'Emprunts'} />
            </ListItem>
            <ListItem
              button
              key={'Planning'}
              onClick={() => handleClickTabs(Devices.MOBILE, '/planning')}
            >
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary={'Planning'} />
            </ListItem>
            <ListItem
              button
              key={'Historique'}
              onClick={() => handleClickTabs(Devices.MOBILE, '/historique')}
            >
              <ListItemIcon>
                <TimelineIcon />
              </ListItemIcon>
              <ListItemText primary={'Historique'} />
            </ListItem>
            <Divider />
            {isAdmin && (
              <>
                <Divider />
                <ListItem
                  button
                  key={'Admin'}
                  onClick={() => handleClickTabs(Devices.MOBILE, '/admin')}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Admin'} />
                </ListItem>
              </>
            )}
            <FormControlLabel
              control={<ThemeModeSwitch defaultChecked />}
              label="Thème"
              onChange={(evt, value) => handleSwitchTheme(value)}
              checked={darkMode}
              className={classes.themeSwitch}
            />
            <Divider />
          </List>
        </div>
      </Drawer>
    )
  }
  const DesktopAppBar = () => {
    return (
      <>
        <IconButton className={classes.desktopLogoIcon}>
          <LogoBanniere />
        </IconButton>

        <Tabs
          value={activeTab}
          className={classes.desktopTabs}
          indicatorColor="primary"
          TabIndicatorProps={{ className: classes.indicator }}
        >
          {getTabs()}
        </Tabs>
        <div className={classes.desktopThemeSwitch}>
          <FormControlLabel
            control={<ThemeModeSwitch defaultChecked />}
            label=""
            onChange={(evt, value) => handleSwitchTheme(value)}
            checked={darkMode}
          />
        </div>
        <IconButton
          onClick={handleNotificationIconClick}
          className={classes.desktopNotifIcon}
        >
          <NotifButton />
        </IconButton>
        <IconButton
          onClick={() => {
            handleLogOut(Devices.DESKTOP)
          }}
          className={classes.desktopLogOutIcon}
        >
          <ExitToAppIcon color={'inherit'} />
        </IconButton>
      </>
    )
  }
  const MobileAppBar = () => {
    return (
      <>
        <IconButton
          edge="start"
          onClick={(): void => setMenuDrawerOpen(true)}
          className={classes.mobileMenuIcon}
        >
          <MenuIcon color="inherit" />
        </IconButton>
        <IconButton className={classes.mobileLogoIcon}>
          <LogoBanniere />
        </IconButton>
        <IconButton
          onClick={handleNotificationIconClick}
          className={classes.mobileNotifIcon}
        >
          <NotifButton />
        </IconButton>
        <IconButton
          onClick={() => {
            handleLogOut(Devices.MOBILE)
          }}
          className={classes.mobileLogOutIcon}
        >
          <ExitToAppIcon color={'inherit'} />
        </IconButton>
        <MobileDrawer />
      </>
    )
  }

  return (
    <>
      <AppBarMaterialUI
        elevation={1}
        position="fixed"
        className={classes.container}
        color="default"
      >
        <Toolbar>
          <Hidden xsUp={!isMobileMode}>
            <MobileAppBar />
          </Hidden>
          <Hidden xsUp={isMobileMode}>
            <DesktopAppBar />
          </Hidden>
        </Toolbar>
      </AppBarMaterialUI>
      <Divider />
      <PopoverNotification
        anchorElNotif={anchorElNotif}
        setAnchorElNotif={setAnchorElNotif}
      />
    </>
  )
}

export default AppBar
