import { Checkbox, Collapse, Grid } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Tooltip from '@material-ui/core/Tooltip'
import BatteryFullIcon from '@material-ui/icons/BatteryFull'
import CameraIcon from '@material-ui/icons/Camera'
import CameraAltIcon from '@material-ui/icons/CameraAlt'
import FilterListIcon from '@material-ui/icons/FilterList'
import FlashOnIcon from '@material-ui/icons/FlashOn'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import SdCardIcon from '@material-ui/icons/SdCard'
import React from 'react'
import { MatosDetails, MatosTypeEnum } from '../../pages/Catalogue/utils'
import {
  AllBorrowingsWithIdMatosBetweenDateRange,
  CountMatosHasBeenBorrowed,
} from '../../pages/Metrics/utils'
import TableHistoryBorrowingOfOneMatos from './TableHistoryBorrowingOfOneMatos'
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface HeadCell {
  disablePadding: boolean
  id: keyof CountMatosHasBeenBorrowed
  label: string
  numeric: boolean
}

const headCells: HeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nom',
  },
  {
    id: 'counterMatosBorrowed',
    numeric: true,
    disablePadding: false,
    label: 'Nb emprunts',
  },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof CountMatosHasBeenBorrowed
  ) => void
  order: Order
  orderBy: string
  rowCount: number
  openTypeChoices: boolean
  setOpenTypeChoices
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    openTypeChoices,
    setOpenTypeChoices,
  } = props
  const createSortHandler = (property: keyof CountMatosHasBeenBorrowed) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property)
  }

  return (
    <React.Fragment>
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="left"
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ width: headCell.label === 'Historique' ? 5 : undefined }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
              {headCell.label === 'Type' && (
                <Tooltip title="Sélectionner un type">
                  <IconButton
                    aria-label="filter list"
                    onClick={() => {
                      setOpenTypeChoices(!openTypeChoices)
                    }}
                  >
                    <FilterListIcon
                      style={{
                        transform: openTypeChoices ? 'scaleY(-1)' : 'scaleY(1)',
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    row: {
      cursor: 'pointer',
    },
  })
)

interface PropsTable {
  countMatosHasBeenBorrowed: CountMatosHasBeenBorrowed[]
  allBorrowingsWithIdMatosBetweenDateRange: AllBorrowingsWithIdMatosBetweenDateRange[]
  setOpenedDialog
  setBorrowingToShowInDialog
  matosSearchBar: MatosDetails[] | null
}
type FilterTypeMatosChoices = {
  [key in MatosTypeEnum]: boolean
}
const defaultFilterTypeMatosChoices: FilterTypeMatosChoices = {
  [MatosTypeEnum.BOITIER]: true,
  [MatosTypeEnum.OBJECTIF]: true,
  [MatosTypeEnum.CARTE]: true,
  [MatosTypeEnum.BATTERIE]: true,
  [MatosTypeEnum.STAND]: true,
  [MatosTypeEnum.OTHER]: true,
}

export default function TableForBorrowingRanking({
  countMatosHasBeenBorrowed,
  allBorrowingsWithIdMatosBetweenDateRange,
  setOpenedDialog,
  setBorrowingToShowInDialog,
  matosSearchBar,
}: PropsTable) {
  const classes = useStyles()
  const [order, setOrder] = React.useState<Order>('desc')
  const [orderBy, setOrderBy] = React.useState<keyof CountMatosHasBeenBorrowed>(
    'counterMatosBorrowed'
  )
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [filteredData, setFilteredData] = React.useState<
    CountMatosHasBeenBorrowed[]
  >(countMatosHasBeenBorrowed)
  const [
    filterTypeMatosChoices,
    setFilterTypeMatosChoices,
  ] = React.useState<FilterTypeMatosChoices>(defaultFilterTypeMatosChoices)

  React.useEffect(() => {
    const arrayIDSelected = matosSearchBar
      ? matosSearchBar.map((itemMatos) => {
          return itemMatos.id
        })
      : []
    let filteredByID =
      arrayIDSelected?.length > 0
        ? countMatosHasBeenBorrowed.filter((elt) =>
            arrayIDSelected?.includes(elt.id)
          )
        : countMatosHasBeenBorrowed
    setFilteredData(
      filteredByID.filter((elt) => filterTypeMatosChoices[elt.type])
    )
  }, [filterTypeMatosChoices, countMatosHasBeenBorrowed, matosSearchBar])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof CountMatosHasBeenBorrowed
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredData.length - page * rowsPerPage)

  function Row(props: { row: CountMatosHasBeenBorrowed }) {
    const { row } = props
    const labelId = `table-${row.id}`
    const [open, setOpen] = React.useState(false)

    return (
      <React.Fragment key={row.id}>
        <TableRow
          hover
          onClick={(event) => setOpen(!open)}
          role="detailsOnLastBorrowings"
          tabIndex={-1}
          key={row.id}
          className={classes.row}
        >
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell
            component="th"
            id={labelId}
            scope="row"
            padding="none"
            align="left"
          >
            {row.name}
          </TableCell>
          <TableCell align="left">{row.counterMatosBorrowed}</TableCell>
          <TableCell align="left">{row.type}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <TableHistoryBorrowingOfOneMatos
                allBorrowingsWithIdMatosBetweenDateRange={
                  allBorrowingsWithIdMatosBetweenDateRange
                }
                matosId={row.id}
                setOpenedDialog={setOpenedDialog}
                setBorrowingToShowInDialog={setBorrowingToShowInDialog}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }
  const [openTypeChoices, setOpenTypeChoices] = React.useState<boolean>(false)
  const ShowCheckBox = () => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilterTypeMatosChoices({
        ...filterTypeMatosChoices,
        [event.target.name]: event.target.checked,
      })
    }
    return (
      <Grid container justify="flex-end">
        <Grid item>
          <Checkbox
            checked={filterTypeMatosChoices['Boitier']}
            onChange={handleChange}
            name="Boitier"
            color="primary"
            icon={<CameraAltIcon />}
            checkedIcon={<CameraAltIcon />}
          />
        </Grid>
        <Grid item>
          <Checkbox
            checked={filterTypeMatosChoices['Objectif']}
            onChange={handleChange}
            name="Objectif"
            color="primary"
            icon={<CameraIcon />}
            checkedIcon={<CameraIcon />}
          />
        </Grid>
        <Grid item>
          <Checkbox
            checked={filterTypeMatosChoices['Carte']}
            onChange={handleChange}
            name="Carte"
            color="primary"
            icon={<SdCardIcon />}
            checkedIcon={<SdCardIcon />}
          />
        </Grid>
        <Grid item>
          <Checkbox
            checked={filterTypeMatosChoices['Batterie']}
            onChange={handleChange}
            name="Batterie"
            color="primary"
            icon={<BatteryFullIcon />}
            checkedIcon={<BatteryFullIcon />}
          />
        </Grid>
        <Grid item>
          <Checkbox
            checked={filterTypeMatosChoices['Stand']}
            onChange={handleChange}
            name="Stand"
            color="primary"
            icon={<FlashOnIcon />}
            checkedIcon={<FlashOnIcon />}
          />
        </Grid>
        <Grid item>
          <Checkbox
            checked={filterTypeMatosChoices['Other']}
            onChange={handleChange}
            name="Other"
            color="primary"
            icon={<LocalMallIcon />}
            checkedIcon={<LocalMallIcon />}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      <Paper className={classes.paper}>
        <TableContainer>
          {openTypeChoices && <ShowCheckBox />}
          <Table
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredData.length}
              openTypeChoices={openTypeChoices}
              setOpenTypeChoices={setOpenTypeChoices}
            />
            <TableBody>
              {stableSort(filteredData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return <Row row={row} />
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {filteredData.length > 5 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} sur ${count}`
            }
            labelRowsPerPage={''}
          />
        )}
      </Paper>
    </div>
  )
}
