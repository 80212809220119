import {
  Divider,
  Grid,
  makeStyles,
  Switch,
  Theme,
  Typography,
} from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import classNames from 'classnames'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import DisclaimerNotifications from '../../components/AppBar/NotificationsPopover/DisclaimerNotifications'
import MyButton from '../../components/Buttons/MyButton'
import { LoadingContext } from '../../contexts/loading/LoadingContext'
import { NotificationsContext } from '../../contexts/notifications/NotificationsContext'
import {
  customChoicesNotificationsScope,
  defaultDisablePushNotifs,
  NotificationsUserOptions,
  postUpdateNotificationOptions,
} from './utils'

const useStyles = makeStyles((theme: Theme) => ({
  MyNotificationsOptions: { margin: theme.spacing(2) },
  grid: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  buttonColor: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.customButtons.primary.hover,
      color: theme.palette.common.white,
    },
    color: theme.palette.customText.secondary,
  },
  text: { color: theme.palette.customText.main },
  error: { backgroundColor: theme.palette.error.main },
}))

export default function MyNotificationsOptions() {
  const classes = useStyles()
  const { loadingCount, decrementLoading } = useContext(LoadingContext)
  const {
    isFirebaseTokenFound,
    userPreferences,
    triggerGetUserPreferencesNotifications,
  } = useContext(NotificationsContext)
  const { enqueueSnackbar } = useSnackbar()

  const [
    choicesForm,
    setChoicesForm,
  ] = React.useState<NotificationsUserOptions>(userPreferences)
  interface PropsForChoices {
    title: string
    valueName: keyof NotificationsUserOptions
  }

  React.useEffect(() => {
    if (!choicesForm.allowPushNotifications) {
      setChoicesForm(defaultDisablePushNotifs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choicesForm.allowPushNotifications])
  const handleSave = () => {
    postUpdateNotificationOptions(
      choicesForm,
      enqueueSnackbar,
      decrementLoading
    ).then((res) => {
      if (res.status === 200) {
        enqueueSnackbar('Changements sauvegardés', {
          variant: 'success',
        })
        triggerGetUserPreferencesNotifications()
      }
    })
  }

  const disableChoices = () => {
    return (
      !choicesForm.allowPushNotifications ||
      loadingCount > 0 ||
      !isFirebaseTokenFound
    )
  }

  const disablePushNotifSwitch = () => {
    return loadingCount > 0 || !isFirebaseTokenFound
  }
  function TypeOfChoices({ title, valueName }: PropsForChoices) {
    return (
      <div>
        <Divider light />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.grid}
          spacing={1}
        >
          <Grid item xs={4} md={8}>
            <Typography>{title}</Typography>
          </Grid>
          <Grid item xs={8} md={4}>
            <ToggleButtonGroup
              size="medium"
              value={choicesForm[valueName]}
              exclusive
              onChange={(e, newValue) => {
                setChoicesForm({
                  ...choicesForm,
                  [valueName]: newValue,
                })
              }}
            >
              <ToggleButton
                value={customChoicesNotificationsScope.NONE}
                className={classes.buttonColor}
                disabled={loadingCount > 0 || !isFirebaseTokenFound}
              >
                Aucune
              </ToggleButton>
              <ToggleButton
                value={customChoicesNotificationsScope.FORMEONLY}
                className={classes.buttonColor}
                disabled={disableChoices()}
              >
                Pour moi
              </ToggleButton>
              <ToggleButton
                value={customChoicesNotificationsScope.FOREVERYONE}
                className={classes.buttonColor}
                disabled={disableChoices()}
              >
                Tout
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </div>
    )
  }
  return (
    <div id="MyNotificationsOptions" className={classes.MyNotificationsOptions}>
      <DisclaimerNotifications />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.grid}
      >
        <Grid item xs={8}>
          <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
            Type de notifications
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
            Choix
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classNames(
          classes.grid,
          !isFirebaseTokenFound && classes.error
        )}
        spacing={1}
      >
        <Grid item xs={4} md={8}>
          <Typography>Push notifications</Typography>
        </Grid>
        <Grid item xs={8} md={4}>
          <Switch
            checked={choicesForm.allowPushNotifications}
            onChange={(e) => {
              setChoicesForm({
                ...choicesForm,
                allowPushNotifications: e.target.checked,
              })
            }}
            name="allowPushNotifications"
            disabled={disablePushNotifSwitch()}
            color="primary"
          />
        </Grid>
        {!isFirebaseTokenFound && (
          <Grid item>
            <Typography>
              Impossible de s'abonner aux pushs notifications. Autorise les
              notifications dans les paramètres de ton navigateur.
            </Typography>
          </Grid>
        )}
      </Grid>
      <TypeOfChoices
        title={'Un emprunt a été créé'}
        valueName={'borrowingsCreated' as keyof NotificationsUserOptions}
      />
      <TypeOfChoices
        title={"Un emprunt n'a pas été rendu à temps"}
        valueName={'borrowingsLate' as keyof NotificationsUserOptions}
      />
      <TypeOfChoices
        title={'Un emprunt a été rendu'}
        valueName={'borrowingsReturned' as keyof NotificationsUserOptions}
      />
      <TypeOfChoices
        title={'Une réservation a été créée'}
        valueName={'bookingsCreated' as keyof NotificationsUserOptions}
      />
      <TypeOfChoices
        title={"Une réservation n'a pas été transformée"}
        valueName={'bookingsLate' as keyof NotificationsUserOptions}
      />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.grid}
      >
        <Grid item xs={8}>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 600 }}
          ></Typography>
        </Grid>
        <Grid item xs={4}>
          <MyButton
            title={'Sauvegarder'}
            callback={handleSave}
            isDisabled={disablePushNotifSwitch()}
          />
        </Grid>
      </Grid>
    </div>
  )
}
