import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { LoadingContext } from '../../contexts/loading/LoadingContext'

interface Props {
  title: string
  isDisabled?: boolean
  callback: any
  callback2?: any
  iconLeft?: any
  secondary?: boolean
  shouldNeverBeDisabled?: boolean
}

const useStyles = makeStyles((theme) => ({
  createButtonPrimary: {
    backgroundColor: theme.palette.customButtons.primary.default,
    color: 'white',
    '&:hover': {
      color: 'black',
      backgroundColor: theme.palette.customButtons.primary.hover,
    },
  },
  createButtonSecondary: {
    backgroundColor: theme.palette.customButtons.secondary.default,
    color: 'black',
    '&:hover': {
      backgroundColor: theme.palette.customButtons.secondary.hover,
    },
  },
  disabledButtonCreate: {
    backgroundColor: theme.palette.background.paper,
  },
  buttonLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -12,
    color: theme.palette.primary.main,
  },
}))
export default function MyButton({
  title,
  callback,
  callback2,
  isDisabled,
  iconLeft,
  secondary,
  shouldNeverBeDisabled,
}: Props) {
  const classes = useStyles()
  const { loadingCount } = useContext(LoadingContext)

  const callbacks = () => {
    callback()
    callback2 && callback2()
  }
  return (
    <Button
      onClick={callbacks}
      className={
        secondary ? classes.createButtonSecondary : classes.createButtonPrimary
      }
      classes={{ disabled: classes.disabledButtonCreate }}
      disabled={
        shouldNeverBeDisabled === true ? false : isDisabled || loadingCount > 0
      }
      aria-label={title}
    >
      {shouldNeverBeDisabled !== true && loadingCount > 0 && (
        <CircularProgress
          className={classes.buttonLoading}
          size={15}
          thickness={5}
        />
      )}
      {iconLeft}
      {title}
    </Button>
  )
}
