import { snackBarErrorOrFailToFetch } from '../../utils/SnackBarGenericError'

export function getPromoFromVRLogin(VRLogin: string) {
  return 'P20'.concat(
    Number(parseInt(VRLogin[2].concat(VRLogin[3]), 10) + 3).toString()
  )
}

export interface CredentialsLogin {
  username: string
  password: string
}
export const isAVRUsername = (VRUsername: string) => {
  /* Expected AAAAloremipsus*/
  if (VRUsername.length < 4) {
    return false
  }
  const reg = new RegExp('^[0-9]+$')
  return reg.test(VRUsername.substring(0, 4))
}

export interface AuthCredentials {
  isAdmin: boolean
  token: string
  fullName: string
}

export interface ResLogin {
  success: boolean
  groups: string
  givenName: string
  token: string
}

interface CompleteFormForLogin {
  username: string
  password: string
  promo: string
}
export function postLogin(
  bodyToSent: CompleteFormForLogin,
  enqueueSnackbar: Function,
  decrementLoading: Function
) {
  const p = process.env
  if (
    !p.REACT_APP_URL_BACK ||
    !p.REACT_APP_CALENDAR_ID_COUV ||
    !p.REACT_APP_CALENDAR_ID_PRESTA ||
    !p.REACT_APP_CALENDAR_ID_STUDIO ||
    !p.REACT_APP_CALENDAR_ID_GENERAL
  ) {
    throw new Error('Clé .env manquante')
  }
  return fetch(p.REACT_APP_URL_BACK.concat('login'), {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyToSent),
  })
    .then((res) => res.json())
    .catch((error) => {
      if (
        ///TODO CACA
        error.toString().includes('Unexpected token F in JSON at position 0')
      ) {
        enqueueSnackbar('Compte inconnu', {
          variant: 'error',
        })
      } else {
        snackBarErrorOrFailToFetch(
          error,
          enqueueSnackbar,
          'login',
          decrementLoading
        )
      }
    })
}
