import { makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import React from 'react'
import { appBarHeight } from '../../utils/various'
import { showTitleAccordingToEnv } from '../../utils/Version'
import HistoryNotificationsFrontCreated from './HistoryNotificationsFrontCreated'
import HistoryPushNotificationsSent from './HistoryPushNotificationsSent'
import MyNotificationsOptions from './MyNotificationsOptions'

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    padding: theme.spacing(1),
    textAlign: 'center',
    textTransform: 'none',
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.customText.secondary,
    margin: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  root: {
    marginTop: `${appBarHeight}`,
    backgroundColor: theme.palette.background.paper,
  },
}))
export default function NotificationsPage() {
  const classes = useStyles()
  React.useEffect(() => {
    document.title = showTitleAccordingToEnv('Notifications')
  }, [])
  return (
    <div id="notification-page" className={classes.root}>
      <Paper className={classes.paper}>
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h2"
          className={classes.title}
        >
          Mes options
        </Typography>
        <MyNotificationsOptions />
      </Paper>
      <Paper className={classes.paper}>
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h2"
          className={classes.title}
        >
          Historique des notifications envoyées
        </Typography>
        <Typography color="textPrimary" gutterBottom variant="subtitle1">
          A voir sur ordinateur !
        </Typography>
        <HistoryPushNotificationsSent />
      </Paper>
      <Paper className={classes.paper}>
        <Typography
          color="textPrimary"
          gutterBottom
          variant="h2"
          className={classes.title}
        >
          Historique des notifications créées sur l'appli
        </Typography>
        <Typography color="textPrimary" gutterBottom variant="subtitle1">
          A voir sur ordinateur !
        </Typography>
        <HistoryNotificationsFrontCreated />
      </Paper>
    </div>
  )
}
