import 'firebase/analytics'
import firebase from 'firebase/app'
import React from 'react'
import { Link } from 'react-router-dom'

export function getVersionBACK() {
  return fetch(process.env.REACT_APP_URL_BACK + 'version', {
    method: 'GET',
  })
    .then((response) => response.json())
    .catch((error) => {
      console.error('ERROR', error)
    })
}
export function showNotifReload(
  classes,
  enqueueSnackbar,
  backVersion,
  frontVersion
) {
  enqueueSnackbar(
    <div>
      <span>Nouvelle version disponible </span>
      <div>
        {'FRONT : '.concat(frontVersion)}
        <br />
        {'BACK : '.concat(backVersion)}
      </div>
      <br />
      <span>
        <Link
          rel="canonical"
          key="clear"
          className={classes.reloadLink}
          onClick={() => {
            emptyCache()
            firebase.analytics().logEvent('warn_click_new_version_refresh')
            window.location.reload()
            console.log('Clearing cache')
          }}
          to=""
        >
          Recharger l'application
        </Link>
      </span>
    </div>,
    {
      variant: 'warning',
      persist: true,
    }
  )
}
export function showNotifWrongEnv(enqueueSnackbar, backVersion, frontVersion) {
  enqueueSnackbar(
    <div>
      <span>Mauvaise relation entre .env </span>

      <div>
        <br />
        {'FRONT : '.concat(frontVersion)}
        <br />
        {'BACK : '.concat(backVersion)}
      </div>
    </div>,
    {
      variant: 'warning',
      persist: true,
    }
  )
}
function emptyCache() {
  if ('caches' in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name)
      })
    })

    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload()
  }
}
export enum EnvEnum {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export const getNameOfEnv = () => {
  if (process.env.REACT_APP_NODE_STAGING_ENV) {
    return EnvEnum.STAGING
  } else if (process.env.NODE_ENV === EnvEnum.PRODUCTION) {
    return EnvEnum.PRODUCTION
  } else {
    return EnvEnum.DEVELOPMENT
  }
}

export function showTitleAccordingToEnv(title: string) {
  const envName = getNameOfEnv()
  if (envName === EnvEnum.PRODUCTION) {
    return title
  } else {
    return `[${envName.toUpperCase()}] ${title} - Ap'Pics`
  }
}
