import firebase from 'firebase/app'
import 'firebase/messaging'
import { getGivenName, getToken } from './utils/Auth'
import { snackBarErrorOrFailToFetch } from './utils/SnackBarGenericError'

export const firebaseConfigJSON = {
  apiKey: 'AIzaSyAEvWuEoqn2Q7OUp1AYvfkhqJNb6m1-tPg',
  authDomain: 'appics-27312.firebaseapp.com',
  projectId: 'appics-27312',
  storageBucket: 'appics-27312.appspot.com',
  messagingSenderId: '164607783758',
  appId: '1:164607783758:web:3b9de8e50d40021b60641f',
  measurementId: 'G-W13B2E46M3',
}
interface FcmOptions {
  analyticsLabel?: string
  link?: string
}
export interface FirebaseNotification {
  body?: string
  image?: string
  title?: string
  tag?: string
}
export interface FirebasePayload {
  collapseKey: string
  data?: [key: string, value: any][]
  fcmOptions?: FcmOptions
  notification?: FirebaseNotification | undefined
}

export interface FirebaseSubscriptionPicsBackEnd {
  given_name: string
  firebase_token: string
  last_firebase_update: string
}
firebase.initializeApp(firebaseConfigJSON)

export const getFirebaseToken = (
  setTokenFound,
  incrementLoading,
  decrementLoading,
  enqueueSnackbar
) => {
  var moment = require('moment')
  if (getGivenName().length === 0) {
    console.warn('Connexion requise pour accéder aux notifs Firebase')
  } else if (!firebase.messaging.isSupported()) {
    console.error('Firebase not supported')
  } else if (process.env.REACT_APP_VAPID_PUBLIC_KEY) {
    return firebase
      .messaging()
      .getToken({
        vapidKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
      })
      .then((currentToken) => {
        if (currentToken) {
          incrementLoading()
          setTokenFound(true)
          console.log('Obtained new token for the FirebaseNotif')
          let body: FirebaseSubscriptionPicsBackEnd = {
            given_name: getGivenName(),
            firebase_token: currentToken,
            last_firebase_update: moment().format('yyyy-MM-DDTHH:mm:ss'),
          }
          postFirebaseSubscription(body, decrementLoading, enqueueSnackbar)
        } else {
          console.warn(
            'No registration Firebase token available. Request permission to generate one.'
          )
          setTokenFound(false)
        }
      })
      .catch((err) => {
        console.error(
          'An error occurred while retrieving Firebase token. ',
          err
        )
        setTokenFound(false)
      })
  } else {
    throw Error('Public VAPID key missing')
  }
}

export const onFirebaseMessageListener = () =>
  new Promise((resolve) => {
    firebase.messaging().onMessage((payload) => {
      resolve(payload)
    })
  })

function postFirebaseSubscription(
  bodyToSent: FirebaseSubscriptionPicsBackEnd,
  decrementLoading: Function,
  enqueueSnackbar: Function
) {
  let token = getToken()

  return fetch(process.env.REACT_APP_URL_BACK + 'notifications/subscribe', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(bodyToSent),
  })
    .then((res) => {
      res.json()
      decrementLoading()
    })
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'postFirebaseSubscription',
        decrementLoading,
        "Impossible de t'enregistrer pour les notifs"
      )
    })
}
