import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import { LoadingContext } from '../../contexts/loading/LoadingContext'
import { postNewMatosAdmin, putUpdateMatosAdmin } from '../../pages/Admin/utils'
import {
  buildItemMatosMountMenu,
  buildItemMatosTypeMenu,
  itemMatosStatusMenu,
  MatosDetails,
  MatosStatusEnum,
  MatosTypeEnum,
  MountTypeEnum,
  TYPES_TO_DISPLAY_MOUNT,
} from '../../pages/Catalogue/utils'
import { getToken } from '../../utils/Auth'
import {
  existValueInEnum,
  LIMIT_VARCHAR,
  LIMIT_VARCHAR_PICS,
} from '../../utils/various'
import MyButton from '../Buttons/MyButton'
import CustomDialogTitle from '../Dialog/CustomDialogTitle'
import ChipMatosStatus from '../Matos/ChipMatosStatus'
import IndividualCardMatos from '../Matos/IndividualCardMatos'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  textField: { marginTop: '20px' },
}))

interface Props {
  matos: MatosDetails
  handleClose: () => void
  openDialogChangeOneMatos: boolean
  rerenderParentCallback: Function
  createNewMatos?: boolean
}

export default function EditOrCreateOneMatosDialog({
  matos,
  handleClose,
  openDialogChangeOneMatos,
  rerenderParentCallback,
  createNewMatos,
}: Props) {
  const classes = useStyles()
  const [matosForm, setMatosForm] = React.useState<MatosDetails>(matos)
  const { loadingCount, incrementLoading, decrementLoading } = useContext(
    LoadingContext
  )
  const { enqueueSnackbar } = useSnackbar()
  const matosTypeMenuItems = buildItemMatosTypeMenu()
  const matosMountMenuItems = buildItemMatosMountMenu()
  const handleChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setMatosForm({ ...matosForm, [key]: event.target.value })
  }

  const isError = (field, value) => {
    if (field === 'pics') {
      return value.length > LIMIT_VARCHAR_PICS
    } else if (field === 'model' || field === 'mount') {
      return value.length > LIMIT_VARCHAR
    } else {
      return value.length > LIMIT_VARCHAR || value.length === 0
    }
  }
  const isMountError = (input) => {
    return (
      TYPES_TO_DISPLAY_MOUNT.includes(matos.type) &&
      !existValueInEnum(MountTypeEnum, input)
    )
  }

  async function handleSubmit(event: React.FormEvent) {
    incrementLoading()
    createNewMatos
      ? await postNewMatosAdmin(
          matosForm,
          getToken(),
          decrementLoading,
          enqueueSnackbar,
          rerenderParentCallback,
          handleClose
        )
      : await putUpdateMatosAdmin(
          matosForm,
          getToken(),
          decrementLoading,
          enqueueSnackbar,
          rerenderParentCallback,
          handleClose
        )
  }

  React.useEffect(() => {
    setMatosForm(matos)
  }, [matos])

  return matos && matosForm ? (
    <Dialog
      open={openDialogChangeOneMatos}
      onClose={() => {
        handleClose()
      }}
    >
      <CustomDialogTitle
        title={createNewMatos ? 'Créer' : 'Modifier '.concat(matos.name)}
        handleClose={handleClose}
      />
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            autoComplete="off"
            select
            className={classes.textField}
            margin="dense"
            id="pics"
            label="Statut"
            type="comment"
            fullWidth
            variant="outlined"
            value={matosForm.status}
            onChange={(e) => handleChange('status', e)}
            disabled={matos.status === MatosStatusEnum.COUV}
            error={isError('status', matosForm.status)}
          >
            {itemMatosStatusMenu.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={option.value === MatosStatusEnum.COUV}
              >
                <ChipMatosStatus status={option.value} />
              </MenuItem>
            ))}
          </TextField>
          <TextField
            autoComplete="off"
            select
            className={classes.textField}
            margin="dense"
            id="type"
            label="Type"
            type="comment"
            fullWidth
            variant="outlined"
            value={matosForm.type}
            onChange={(e) => handleChange('type', e)}
            error={isError('type', matosForm.type)}
          >
            {matosTypeMenuItems.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            autoComplete="off"
            className={classes.textField}
            margin="dense"
            id="id"
            label="id"
            type="id"
            fullWidth
            variant="outlined"
            value={matosForm.id}
            disabled={true}
            error={isError('id', matosForm.id)}
          />
          <TextField
            autoComplete="off"
            className={classes.textField}
            margin="dense"
            id="name"
            label="Nom"
            type="comment"
            fullWidth
            variant="outlined"
            value={matosForm.name}
            onChange={(e) => handleChange('name', e)}
            error={isError('name', matosForm.name)}
          />
          <TextField
            autoComplete="off"
            className={classes.textField}
            margin="dense"
            id="brand"
            label="Marque"
            type="comment"
            fullWidth
            variant="outlined"
            value={matosForm.brand}
            onChange={(e) => handleChange('brand', e)}
            error={isError('brand', matosForm.brand)}
          />
          <TextField
            autoComplete="off"
            className={classes.textField}
            margin="dense"
            id="model"
            label="Modèle"
            type="comment"
            fullWidth
            variant="outlined"
            value={matosForm.model}
            onChange={(e) => handleChange('model', e)}
            error={isError('model', matosForm.model)}
          />
          <TextField
            autoComplete="off"
            className={classes.textField}
            margin="dense"
            id="capacity"
            label="Capacité"
            type="comment"
            fullWidth
            variant="outlined"
            value={matosForm.capacity}
            onChange={(e) => handleChange('capacity', e)}
            disabled={matosForm.type !== MatosTypeEnum.CARTE}
            error={isError('type', matosForm.type)}
          />
          <TextField
            autoComplete="off"
            select
            className={classes.textField}
            margin="dense"
            id="mont"
            label="Monture (Canon / Nikon)"
            type="comment"
            fullWidth
            variant="outlined"
            value={matosForm.mount}
            onChange={(e) => handleChange('mount', e)}
            error={
              isMountError(matosForm.mount) || isError('mount', matosForm.mount)
            }
          >
            {matosMountMenuItems.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            autoComplete="off"
            className={classes.textField}
            margin="dense"
            id="pics"
            label="URL photo"
            type="comment"
            fullWidth
            variant="outlined"
            value={matosForm.pics}
            onChange={(e) => handleChange('pics', e)}
            error={isError('pics', matosForm.pics)}
          />
        </form>
      </DialogContent>
      <Accordion>
        <AccordionSummary
          expandIcon={<VisibilityIcon />}
          aria-controls="panel1a-content"
        >
          <Typography className={classes.heading} variant="h5">
            Prévisualiser
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <IndividualCardMatos
            matos={matosForm}
            handleRemoveMatos={() => {
              alert('Ceci est un exemple')
            }}
            handleAddMatos={() => {
              alert('Ceci est un exemple')
            }}
            selectedMatos={[]}
          />
        </AccordionDetails>
      </Accordion>
      <DialogActions>
        <MyButton
          title={createNewMatos ? 'Créer' : 'Modifier'}
          callback={handleSubmit}
          isDisabled={
            isError('id', matosForm.id) ||
            isError('name', matosForm.name) ||
            isError('status', matosForm.status) ||
            isError('type', matosForm.type) ||
            isError('brand', matosForm.brand) ||
            isMountError(matosForm.mount) ||
            loadingCount > 0
          }
        />
      </DialogActions>
    </Dialog>
  ) : (
    <div></div>
  )
}
