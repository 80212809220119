import { Grid, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useContext } from 'react'
import { LoadingContext } from '../../../contexts/loading/LoadingContext'
import { NotificationsContext } from '../../../contexts/notifications/NotificationsContext'
import {
  Booking,
  Borrowing,
  getBorrowingsLive,
  getNextBookings,
} from '../../../pages/History/utils'
import { getToken } from '../../../utils/Auth'
import BookingCard from '../../Bookings/BookingCard'
import BorrowingCard from '../../Borrowings/BorrowingCard'
import SummaryMatosCouv from './SummaryMatosCouv'

export default function BorrowingsLive() {
  var moment = require('moment')
  const { enqueueSnackbar } = useSnackbar()
  const { incrementLoading, decrementLoading } = useContext(LoadingContext)
  const { notifications } = useContext(NotificationsContext)
  const [borrowingsLive, setBorrowingsLive] = React.useState<Borrowing[]>([])
  const [nextBookings, setNextBookings] = React.useState<Booking[]>([])

  const token = getToken()
  React.useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications?.length])

  async function getData() {
    incrementLoading()
    await getBorrowingsLive(token, enqueueSnackbar, decrementLoading).then(
      async (res) => {
        setBorrowingsLive(
          res?.sort((a, b) => b.startdate_log.localeCompare(a.startdate_log))
        )
        decrementLoading()
        incrementLoading()
        await getNextBookings(token, enqueueSnackbar, decrementLoading).then(
          async (res) => {
            setNextBookings(
              res
                ?.filter((booking) => {
                  return moment(booking.startdate).isBefore(
                    moment(new Date()).add(1, 'days')
                  )
                })
                .sort((a, b) => a.startdate_log.localeCompare(b.startdate_log))
            )
            decrementLoading()
          }
        )
      }
    )
  }

  return (
    <span id="BorrowingsLive">
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justify="center"
      >
        <SummaryMatosCouv borrowingsLive={borrowingsLive} />
        {borrowingsLive?.map((item: Borrowing) => {
          return (
            <Grid item key={item.id}>
              <BorrowingCard
                borrowing={item}
                rerenderParentCallback={getData}
              />
            </Grid>
          )
        })}
        {nextBookings?.map((item: Booking) => {
          return (
            <Grid item key={item.id}>
              <BookingCard booking={item} rerenderParentCallback={getData} />
            </Grid>
          )
        })}
        {nextBookings?.length === 0 && borrowingsLive?.length === 0 && (
          <Typography
            variant={'caption'}
            style={{
              fontSize: 15,
            }}
            color="textPrimary"
          >
            Aucun emprunt en cours !
          </Typography>
        )}
      </Grid>
    </span>
  )
}
