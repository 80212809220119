import React, { useContext } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { Grid, IconButton, List } from '@material-ui/core'
import { NotificationsPopoverListItem } from './NotificationsPopoverListItem'
import DisclaimerNotifications from './DisclaimerNotifications'
import { NotificationsContext } from '../../../contexts/notifications/NotificationsContext'
import SettingsIcon from '@material-ui/icons/Settings'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      textAlign: 'left',
    },
    icon: {
      padding: theme.spacing(1),
    },
    popoverMain: {
      boxpopoverMain: `0 40px 40px -12px ${theme.palette.customText.secondary}`,
      padding: theme.spacing(1),
    },
    header: {
      padding: theme.spacing(1),
    },
  })
)

interface Props {
  anchorElNotif: HTMLButtonElement | null
  setAnchorElNotif: (
    value:
      | HTMLButtonElement
      | null
      | ((prevVar: HTMLButtonElement | null) => HTMLButtonElement | null)
  ) => void
}

export default function PopoverNotifs({
  anchorElNotif,
  setAnchorElNotif,
}: Props) {
  const classes = useStyles()
  const open = Boolean(anchorElNotif)
  const id = open ? 'simple-popover' : undefined
  const { triggerGetDataNotifications } = useContext(NotificationsContext)
  const history = useHistory()
  React.useEffect(() => {
    triggerGetDataNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorElNotif}
        onClose={() => {
          setAnchorElNotif(null)
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.popoverMain}
      >
        <Grid container className={classes.header} spacing={2}>
          <Grid item xs={8}>
            <Typography className={classes.typography} variant="h6">
              Notifications
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.icon}>
            <IconButton
              onClick={() => {
                setAnchorElNotif(null)
                history.push('/notifications')
              }}
            >
              <SettingsIcon className={classes.icon} />
            </IconButton>
          </Grid>
        </Grid>
        <List
          disablePadding
          style={{
            maxWidth: 500,
          }}
        >
          <NotificationsPopoverListItem
            closePopover={() => {
              setAnchorElNotif(null)
            }}
          />
          <DisclaimerNotifications />
        </List>
      </Popover>
    </div>
  )
}
