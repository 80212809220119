import { Grid, TablePagination, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'
import BookingCard from '../../components/Bookings/BookingCard'
import BorrowingCard from '../../components/Borrowings/BorrowingCard'
import { Booking, Borrowing } from './utils'

interface PropsDisplayHistory {
  array?: Borrowing[]
  rerenderParentCallback: any
}
interface PropsDisplayNextBookings {
  array?: Booking[]
  rerenderParentCallback: any
}
const useStyles = makeStyles((theme: Theme) => ({
  root: { marginTop: '10px' },
  typo: {
    position: 'fixed',
    top: '25vh',
    textAlign: 'center',
    color: theme.palette.customText.secondary,
  },
  tablePagination: { marginTop: theme.spacing(2) },
}))

export function DisplayHistory({
  array,
  rerenderParentCallback,
}: PropsDisplayHistory) {
  const classes = useStyles()
  const [page, setPage] = React.useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10)
  const sliceForPagination = () => {
    return { start: rowsPerPage * page, end: rowsPerPage * (page + 1) + 1 }
  }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  function displayInfo() {
    if (!array || array.length === 0) {
      return (
        <Typography variant={'h5'} className={classes.typo}>
          Rien à afficher ici pour l'année scolaire sélectionnée
        </Typography>
      )
    } else {
      return (
        <>
          {array
            .slice(sliceForPagination().start, sliceForPagination().end)
            .map((item: Borrowing) => {
              return (
                <Grid item key={item.id}>
                  <BorrowingCard
                    borrowing={item}
                    rerenderParentCallback={rerenderParentCallback}
                  />
                </Grid>
              )
            })}

          {array.length > 10 && (
            <TablePagination
              component="div"
              count={array.length}
              page={page}
              onChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} sur ${count}`
              }
              labelRowsPerPage={''}
              className={classes.tablePagination}
            />
          )}
        </>
      )
    }
  }

  return (
    <>
      <Grid
        container
        alignContent="center"
        justify="center"
        alignItems="center"
        spacing={1}
        className={classes.root}
      >
        {displayInfo()}
      </Grid>
    </>
  )
}

export function DisplayNextBookings({
  array,
  rerenderParentCallback,
}: PropsDisplayNextBookings) {
  const classes = useStyles()

  /* TODO à proprifier : caca avec le précédent */
  function display() {
    if (!array || array.length === 0) {
      return (
        <Typography variant={'h5'} className={classes.typo}>
          Rien à afficher ici pour l'année scolaire sélectionnée
        </Typography>
      )
    } else {
      return (
        <>
          {array.map((item: Booking) => {
            return (
              <Grid item key={item.id}>
                <BookingCard
                  booking={item}
                  rerenderParentCallback={rerenderParentCallback}
                />
              </Grid>
            )
          })}
        </>
      )
    }
  }
  return (
    <Grid
      container
      alignContent="center"
      justify="center"
      alignItems="center"
      spacing={1}
      className={classes.root}
    >
      {display()}
    </Grid>
  )
}
