import { snackBarErrorOrFailToFetch } from '../../utils/SnackBarGenericError'
import {
  MatosDetails,
  MatosStatusEnum,
  MatosTypeEnum,
} from '../Catalogue/utils'

export interface AdminDialog {
  changeOneMatos: boolean
  createNewMatos: boolean
  changeMultipleStatus: boolean
  searchAdmin: boolean
  cart: boolean
}
export const defaultAdminDialog = {
  changeOneMatos: false,
  createNewMatos: false,
  changeMultipleStatus: false,
  searchAdmin: false,
  cart: false,
}
export const templateNewMatos: MatosDetails = {
  id: 0,
  brand: '',
  name: '',
  model: '',
  mount: '',
  capacity: 0,
  type: MatosTypeEnum.BOITIER,
  status: MatosStatusEnum.LOCAL,
  pics: '',
}
export function getCatalogAdmin(
  token: string,
  enqueueSnackbar: Function,
  decrementLoading: Function
) {
  const url = process.env.REACT_APP_URL_BACK + 'admin/matos/all/get'
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getCatalogAdmin',
        decrementLoading
      )
    })
}

export function getMaxIdMatosAdmin(
  token: string,
  enqueueSnackbar: Function,
  decrementLoading: Function
) {
  const url = process.env.REACT_APP_URL_BACK + 'admin/matos/maxId/get'
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: token,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'getMaxIdMatosAdmin',
        decrementLoading
      )
    })
}

export function putUpdateMatosAdmin(
  bodyToSent: MatosDetails,
  token: string,
  decrementLoading: Function,
  enqueueSnackbar: Function,
  rerenderParentCallback: any,
  handleClose: any
) {
  return fetch(process.env.REACT_APP_URL_BACK + 'admin/matos/update/put', {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(bodyToSent),
  })
    .then((res) => {
      res.json()
      decrementLoading()
      enqueueSnackbar('Matos modifié!', {
        variant: 'success',
      })
      rerenderParentCallback()
      handleClose()
    })
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'putUpdateMatosAdmin',
        decrementLoading,
        'Impossible de modifier'
      )
    })
}

export function putUpdateMultipleMatosStatusAdmin(
  bodyToSent: MatosDetails[],
  newStatus: MatosStatusEnum,
  token: string,
  decrementLoading: Function,
  enqueueSnackbar: Function,
  rerenderParentCallback: any,
  handleClose: any
) {
  return fetch(
    process.env.REACT_APP_URL_BACK +
      'admin/matos/update_multiple_status/put?new_status=' +
      newStatus,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(bodyToSent),
    }
  )
    .then((res) => {
      res.json()
      decrementLoading()
      enqueueSnackbar('Statuts modifiés!', {
        variant: 'success',
      })
      rerenderParentCallback()
      handleClose()
    })
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'putUpdateMultipleMatosStatusAdmin',
        decrementLoading,
        'Impossible de modifier les statuts'
      )
    })
}

export function postNewMatosAdmin(
  bodyToSent: MatosDetails,
  token: string,
  decrementLoading: Function,
  enqueueSnackbar: Function,
  rerenderParentCallback: any,
  handleClose: any
) {
  return fetch(process.env.REACT_APP_URL_BACK + 'admin/matos/new/post', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(bodyToSent),
  })
    .then((res) => {
      res.json()
      decrementLoading()
      enqueueSnackbar('Matos créé !', {
        variant: 'success',
      })
      rerenderParentCallback()
      handleClose()
    })
    .catch((error) => {
      snackBarErrorOrFailToFetch(
        error,
        enqueueSnackbar,
        'postNewMatosAdmin',
        decrementLoading,
        'Impossible de créer ce matos'
      )
    })
}
